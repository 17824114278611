import router from '../router'

let timeout = null // 创建一个标记用来存放定时器的返回值
let canRun = true
export default {
  toCommodityDetails: (data) => {
    let query = {
      goods_id: data.goods_id,
      shop_type: data.shop_type,
    }
    if (data.type) {
      query.type = data.type
    }
    return router.push({
      path: '/productDetails',
      query: query,
    })
    // let routeUrl = router.resolve({
    // 	path: "/productDetails",
    // 	query: {
    // 		goods_id: data
    // 	}
    // });
    // window.open(routeUrl.href);
  },
  toPage: (url, isBlank) => {      
  if (isBlank) {
      window.open(url, '_blank');
  } else {
    location.href = url
  }
  },
 

  routerToPage: (url, isBlank) => {
    if (isBlank) {
        window.open(url, '_blank');
    } else {
      router.push(url);
    }
    // // if (!isBlank) return window.location.href = url;
    // let routeUrl = router.resolve({
    // 	path: url,
    // });
    // window.open(routeUrl.href, '_blank');
  },
  unique: (arr) => {
    //数组去重
    return Array.from(new Set(arr))
  },
  //去除一个数组中与另一个数组中的值相同的元素
  quChu: (a, b) => {
    //数组去重
    let c = []
    for (let i of a) {
      if (b.indexOf(i) === -1) {
        c.push(i)
      }
    }
    return c
  },
  //   将数组转换为onum个一组
  changeArr: (oarr, onum) => {
    const arr = oarr
    const len = arr.length
    let result = []
    const sliceNum = onum // 这个值代表几个一组

    for (let i = 0; i < len / sliceNum; i++) {
      result.push(arr.slice(i * sliceNum, (i + 1) * sliceNum))
    }
    // 补足不足onum个的最后一个数组
    // for(let j = 0; j < (sliceNum - len % sliceNum); j++) {
    //     result[result.length - 1].push({})
    // }

    return result
  },

  /**
   * 函数防抖
   * 触发事件后在n秒后执行，如果n秒内又触发事件，则重新计算时间
   */
  debounce(func, wait = 500, immediate = false) {
    // 清除定时器
    if (timeout !== null) clearTimeout(timeout)
    // 立即执行，此类情况一般用不到
    if (immediate) {
      var callNow = !timeout
      timeout = setTimeout(function() {
        timeout = null
      }, wait)
      if (callNow) typeof func === 'function' && func()
    } else {
      // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
      timeout = setTimeout(function() {
        typeof func === 'function' && func()
      }, wait)
    }
  },

  /**
   * 函数节流
   * 触发事件立即执行，但在n秒内连续触发则不执行
   */
  throttle(func, wait = 500, immediate = true) {
    let timer, flag
    if (immediate) {
      if (!flag) {
        flag = true
        // 如果是立即执行，则在wait毫秒内开始时执行
        typeof func === 'function' && func()
        timer = setTimeout(() => {
          flag = false
        }, wait)
      }
    } else {
      if (!flag) {
        flag = true
        // 如果是非立即执行，则在wait毫秒内的结束处执行
        timer = setTimeout(() => {
          flag = false
          typeof func === 'function' && func()
        }, wait)
      }
    }
  },
  //   加密字符串
  toCode(str) {
    //加密字符串
    //定义密钥，36个字母和数字
    var key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var l = key.length //获取密钥的长度
    var a = key.split('') //把密钥字符串转换为字符数组
    var s = '',
      b,
      b1,
      b2,
      b3 //定义临时变量
    for (var i = 0; i < str.length; i++) {
      //遍历字符串
      b = str.charCodeAt(i) //逐个提取每个字符，并获取Unicode编码值
      b1 = b % l //求Unicode编码值得余数
      b = (b - b1) / l //求最大倍数
      b2 = b % l //求最大倍数的于是
      b = (b - b2) / l //求最大倍数
      b3 = b % l //求最大倍数的余数
      s += a[b3] + a[b2] + a[b1] //根据余数值映射到密钥中对应下标位置的字符
    }
    return s //返回这些映射的字符
  },
  // 解密字符串
  fromCode(str) {
    //定义密钥，36个字母和数字
    var key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var l = key.length //获取密钥的长度
    var b,
      b1,
      b2,
      b3,
      d = 0,
      s //定义临时变量
    s = new Array(Math.floor(str.length / 3)) //计算加密字符串包含的字符数，并定义数组
    b = s.length //获取数组的长度
    for (var i = 0; i < b; i++) {
      //以数组的长度循环次数，遍历加密字符串
      b1 = key.indexOf(str.charAt(d)) //截取周期内第一个字符串，计算在密钥中的下标值
      d++
      b2 = key.indexOf(str.charAt(d)) //截取周期内第二个字符串，计算在密钥中的下标值
      d++
      b3 = key.indexOf(str.charAt(d)) //截取周期内第三个字符串，计算在密钥中的下标值
      d++
      s[i] = b1 * l * l + b2 * l + b3 //利用下标值，反推被加密字符的Unicode编码值
    }
    b = eval('String.fromCharCode(' + s.join(',') + ')') //用fromCharCode()算出字符串
    return b //返回被解密的字符串
  },
  // 将数字四舍五入，传入参数:数字，小数位数
  roundNumber(Num, wei) {
    return Number(Num.toFixed(wei))
  },
  // 将数字向上取整n位
  ceil(num, byte) {
    if (!byte) {
      var byte = 2
    }
    // let n = 10 ** byte
    if (num) {
      return Number(num).toFixed(2)
    } else {
      return 0
    }
 
  },
  // 跳转到指定元素，传入元素的ref或者id，如this.$refs.hfdh
  maoDian(element, orderHeight) {
    let toTop = element.offsetTop
    if (orderHeight) {
      toTop = element.offsetTop + orderHeight
    }
    window.scrollTo(0, toTop)
  },
  to_link(txtContent) {
    let check_www = 'w{3}' + '[^\\s]*'
    let check_http = '(https|http|ftp|rtsp|mms)://' + '[^\\s]*'
    let strRegex = check_www + '|' + check_http
    let httpReg = new RegExp(strRegex, 'gi')
    return txtContent.replace(httpReg, function(httpText) {
      return httpText.search('http') < 0 && httpText.search('HTTP') < 0
    })
  },
  downloadIamge(imgsrc, name) {
    // 下载图片地址和图片名
    var image = new Image()
    // 解决跨域 Canvas 污染问题
    image.setAttribute('crossOrigin', 'anonymous')
    image.onload = function() {
      var canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      var context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      var url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
      var a = document.createElement('a') // 生成一个a元素
      var event = new MouseEvent('click') // 创建一个单击事件
      a.download = name || 'photo' // 设置图片名称
      a.href = url // 将生成的URL设置为a.href属性
      a.dispatchEvent(event) // 触发a的单击事件
    }
    image.src = imgsrc
  },
  // 把价格两位小数后的文字缩小
  twoSmall(value, fontSize) {
    if (value % 1 > 0) {
      let arr = value.toString().split(',')
      return '<font>' + arr[0] + ".</font><font style='font-size:'" + fontSize
        ? fontSize
        : '20' + "'px'>" + arr[1] + '</font>'
    }
    return value
  },
  // 美元价格千分位
  EURNumSegmentation(Num) {
    let re = /\d(?=(?:\d{3})+\b)/g
    // let num = String(Num).replace(/./, ',')
    let num = String(Num).replace(/\./g, '.')
    num = String(num).replace(re, '$&,')
    if (num) {
      return num
    } else {
      return 0
    }
    
  },
  // 价格千分位逗号隔开
  EURNum(Num) {
    let re = /\d(?=(?:\d{3})+\b)/g
    // let num = String(Num).replace(/./, ',')
    let num = String(Num).replace(/\./g, ',')
    num = String(num).replace(re, '$&,')
    return num
  },
  huilvqianf(Num) {
    // let num = (String(Num).replace(/\./g, ','));
    return Num
  },
  // 人民币价格千分位
  RMBNumSegmentation(Num) {
    // let re = /\d(?=(?:\d{3})+\b)/g;
    // let num = (String(Num).replace(/\./g, ','));
    // num = String(num).replace(re, "$&.")
    return Num
  },

  // 将每个单词首字母转换成大写
  trunUpperCase(v) {
    return v
      .trim()
      .toLowerCase()
      .replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
  },
  isOfficialWeb() {
    let isOfficialWeb = false
    if (window.location.origin == 'https://www.rakumart.us') {
      isOfficialWeb = true
    }
    return isOfficialWeb
  },
  // 判断是否是微信浏览器
  isWeixin() {
    let ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true
    } else {
      return false
    }
  },
}
