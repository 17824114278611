<template>
  <div id="CommoditySearch">
    <Dialog ref="dialog" :config="config">
      <!-- <span>插槽内容</span> -->
    </Dialog>
    <HomePageTop></HomePageTop>

    <!-- 关键词搜索 -->
    <nav class="strSearch" v-if="!isImgSearch">
      <div class="topCon">
        <span class="keyword">{{ $fanyi('关键词') }}：{{ $route.query.keyword }}
        </span>
        <!-- <span class="quantity">{{ $fanyi('共') }}<font> {{ lists.total ? lists.total : 0 }}</font>{{ $fanyi('个产品')
          }}</span> -->
        <el-popover popper-class="qieHuanPaiBan" placement="bottom-end" trigger="click" ref="qiehuanSel">
          <div v-if="!Crosswise" @click="
            Crosswise = true
          $refs.qiehuanSel.doClose()
            " class="PaiBanOpt">
            <span>{{ $fanyi('横版') }}</span>
            <img src="../../assets/icon/array1.svg" />
          </div>
          <div v-else @click="
            Crosswise = false
          $refs.qiehuanSel.doClose()
            " class="PaiBanOpt">
            <span>{{ $fanyi('竖版') }}</span>
            <img src="../../assets/icon/array2.svg" />
          </div>

          <div class="crosswise" slot="reference">
            <div v-if="!Crosswise">
              <img src="../../assets/icon/array2.svg" />
            </div>
            <div v-else>
              <img src="../../assets/icon/array1.svg" />
            </div>
            <img class="downIcon" src="../../assets/icon/drop_down  .svg" />
          </div>
        </el-popover>
      </div>
      <!-- 搜索接口 -->

      <div class="searchitem" v-for="(item, index) in keywordSNQuerylist" :key="index">
        <div class="searchitem-title">{{ item.translateName }} :</div>
        <div class="searchitem-list">
          <span @click="setSnId(item, childrenIndex)" :class="{ spanactive: item.active == childrenIndex }"
            v-for="(ite, childrenIndex) in item.children" :key="ite.id">{{ ite.translateName
            }}</span>
        </div>
      </div>
      <!-- 严选 -->
      <div class="Strictselection">
        <div class="fornecedor">{{ $fanyi('严选') }}:</div>
        <!-- 1688精选商品  -->

        <div class="Strictselection-ite" style="margin-right: 80px;">
          <el-checkbox v-model="shopchecked" @change="Selectchange" size="large" /><span>{{
            $fanyi('1688精选商品') }}</span>
        </div>
        <!-- 严选工厂  -->
        <div class="Strictselection-ite" style="margin-right: 122px;">
          <el-checkbox v-model="selectionoffactorieschecked" @change="Selectchange" size="large" />
          <span>{{ $fanyi('1688精选工厂') }}</span>
        </div>

        <!-- 店铺点评 -->

        <div class="Strictselection-ite">
          <span class="selett-title">{{ $fanyi('店铺点评') }}</span>
          <el-select v-model="shopComment" @change="Selectchange" placeholder="Select" size="large">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
      </div>
      <!-- 优势 -->
      <div class="advantage">

        <div class="fornecedor">{{ $fanyi('优势') }}:</div>
        <!-- 当日出货 -->
        <div class="advantage-ite">
          <el-checkbox v-model="Samedayshipment" @change="Selectchange" size="large" /><span>{{
            $fanyi('当日出货') }}</span>
        </div>
        <!-- 24小时以内出货 -->
        <div class="advantage-ite">
          <el-checkbox v-model="Shipmentwithin24hours" @change="Selectchange" size="large" /><span>{{
            $fanyi('24小时以内出货') }}</span>
        </div>
        <!-- 48小时以内出货 -->
        <div class="advantage-ite">
          <el-checkbox v-model="Shipmentwithin48hours" @change="Selectchange" size="large" /><span>{{
            $fanyi('48小时以内出货') }}</span>
        </div>
        <!-- 7天无理由退换商品 -->
        <div class="advantage-ite">
          <el-checkbox v-model="noreasontoreturnandexchangegoodsfor7days" @change="Selectchange" size="large" /><span>{{
            $fanyi('7天无理由退换商品') }}</span>
        </div>
        <!-- 1件起订 -->
        <div class="advantage-ite">
          <el-checkbox v-model="Orderonefromthebeginning" @change="Selectchange" size="large" /><span>{{
            $fanyi('1件起订') }}</span>
        </div>
      </div>
      <!-- 综合排序 -->
      <div class="sort">
        <div class="sort-item" @click="switchSort(6)" :class="{ activecolor: sortindex }">
          {{ $fanyi('综合排序') }}
        </div>
        <!-- 月贩卖数 -->
        <div class="sort-item" @click="switchSort(0)">
          <span :class="{ activecolor: StrictselectionArr[0].bottom }">{{
            $fanyi('月贩卖数')
          }}</span>
          <img v-if="!StrictselectionArr[0].bottom" src="../../assets/commoditysearch/bottom.png" alt="" />
          <img v-else src="../../assets/commoditysearch/botomactive.png" alt="" />
        </div>
        <!-- 复购率 -->
        <div class="sort-item" @click="switchSort(1)">
          <span :class="{ activecolor: StrictselectionArr[1].bottom }">{{
            $fanyi('复购率')
          }}</span>
          <img v-if="!StrictselectionArr[1].bottom" src="../../assets/commoditysearch/bottom.png" alt="" />
          <img v-else src="../../assets/commoditysearch/botomactive.png" alt="" />
        </div>
        <!-- 价格排序 -->
        <div class="sort-item" @click="switchSort(2)">
          <span :class="{
            activecolor:
              StrictselectionArr[2].bottom || StrictselectionArr[2].top,
          }">{{ $fanyi('价格') }}</span>
          <div class="pricesort">
            <img v-if="!StrictselectionArr[2].top" src="../../assets/commoditysearch/top.png" alt="" />
            <img v-else src="../../assets/commoditysearch/topactive.png" alt="" />
            <img v-if="!StrictselectionArr[2].bottom" src="../../assets/commoditysearch/bottom.png" alt="" />
            <img v-else src="../../assets/commoditysearch/botomactive.png" alt="" />
          </div>
        </div>
        <!-- 最高最低价格 -->
        <div class="sort-item">
          <div class="inputbox">
            <span>￥</span><input v-model="priceStart" :placeholder="$fanyi('最低价格')" type="number" />
          </div>
          <p>—</p>
          <div class="inputbox">
            <span>￥</span><input v-model="priceEnd" :placeholder="$fanyi('最高价格')" type="number" />
          </div>

          <div class="qrbutton" @click="(form.page = 1), getData(true, 'Sort')">
            {{ $fanyi('确认') }}
          </div>
        </div>
      </div>
    </nav>

    <!-- 图片搜索的搜索信息显示栏 -->
    <nav class="strSearch" v-if="isImgSearch">
      <div class="topCon">
        <span class="keyword">{{ $fanyi('搜索图片:') }}
          <el-image :src="$route.query.imgUrl">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
        </span>
        <!-- <span class="quantity">{{ $fanyi('共') }}<font> {{ total }}</font>{{ $fanyi('个产品') }}</span> -->
        <el-popover popper-class="qieHuanPaiBan" placement="bottom-end" trigger="click" ref="qiehuanSel">
          <div v-if="!Crosswise" @click="
            Crosswise = true
          $refs.qiehuanSel.doClose()
            " class="PaiBanOpt">
            <span>{{ $fanyi('横版') }}</span>
            <img src="../../assets/icon/array1.svg" />
          </div>
          <div v-else @click="
            Crosswise = false
          $refs.qiehuanSel.doClose()
            " class="PaiBanOpt">
            <span>{{ $fanyi('竖版') }}</span>
            <img src="../../assets/icon/array2.svg" />
          </div>

          <div class="crosswise" slot="reference">
            <div v-if="!Crosswise">
              <img src="../../assets/icon/array2.svg" />
            </div>
            <div v-else>
              <img src="../../assets/icon/array1.svg" />
            </div>
            <img class="downIcon" src="../../assets/icon/drop_down  .svg" />
          </div>
        </el-popover>
      </div>
    </nav>


    <!-- 没有找到商品 -->
    <div v-if="goodsListIsNull" class="goodsListIsNull">
      {{
        $route.query.type == 'imgSearch'
          ? $fanyi('没有找到商品，请换一张图片试试')
          : $fanyi('没有找到商品，请换一个关键词试试')
      }}
    </div>
    <!-- 搜索结果块状 -->
    <div class="SearchConterCrosswise" v-loading="loading" v-else-if="Crosswise">
      <div class="SearchConterCrosswiseCon" v-for="(item, index) in lists.result" :key="index">
        <!-- <button
          class="similarProducts"
          @click="
            $store.commit('getsearchImg', item.imgUrl);
            $fun.toPage('/commoditySearch?type=imgSearch');
            $router.go(0);
          "
        >
          {{ $fanyi("查看相似") }}
        </button> -->
        <!-- 放图片的div -->
        <div class="SearchConterCrosswiseImg" @click="toCommodityDetails(item)">
          <!-- 遮罩 -->
          <!-- <div class="shade" @click="$fun.toCommodityDetails(item.goods_id)">
            <div><img src="../../assets/newImg/搜索小.png" alt="" /></div>
          </div> -->
          <!-- <img class="goodsImg" :src="item.imgUrl" /> -->
          <el-image :src="item.imgUrl" style="width: 262px; height: 262px" class="biggoodsImg">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
          <!-- <loadingImg :src="item.imgUrl" /> -->
        </div>
        <!-- 显示其他信息的div -->
        <div class="rests">
          <p class="goodsTitle" :title="item.title">{{ item.title }}</p>
          <p>
            <span>${{
              $fun.EURNumSegmentation(
                $fun.ceil(item.goodsPrice * $store.state.exchangeRate)
              )
            }}（{{ $fun.RMBNumSegmentation(item.goodsPrice) }}￥）</span>
          </p>
        </div>
        <!-- 月销量 -->
        <div class="Monthlysalesvolume">{{ $fanyi('月销量') + ': ' }} {{ item.monthSold }}</div>
        <!-- 复购率 -->
        <div class="Repurchaserate">{{ $fanyi('复购率') + ': ' }} {{ item.repurchaseRate }} %</div>
      </div>
      <div v-if="slideLoading && !Loadingcompleted" class="keywordLoadingContainer">
        <div class="flexAndCenter">
          <img alt="" src="../../assets/newImg/order/loading.gif" />
          <div>{{ $fanyi('加载中') }}</div>
        </div>
      </div>
    </div>
    <!-- 搜索结果横向 -->

    <div class="SearchConterVertical" v-loading="loading" v-else>
      <div @click="
        toCommodityDetails(item)
        " v-for="(item, index) in lists.result" :key="index" class="item">
        <el-popover placement="right" trigger="hover">
          <img :src="item.imgUrl" alt="" style="width: 300px; height: 300px" />
          <el-image class="hengxiang" :src="item.imgUrl" slot="reference">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
        </el-popover>

        <!-- <img class="goodsImg" src="../../assets/newImg/加载中.gif" /> -->
        <div class="VerticalInfo">
          <span class="vGoodsTitle">{{ item.title }}</span>
          <span class="price">${{
            $fun.EURNumSegmentation(
              $fun.ceil(item.goodsPrice * $store.state.exchangeRate)
            )
          }}（{{ $fun.RMBNumSegmentation(item.goodsPrice) }}￥）</span>
          <div class="xiaoliag">
            <p>{{ $fanyi('月销量') }}：{{ item.monthSold }} {{ $fanyi('件') }}</p>
            <p>{{ $fanyi('复购率') }}：{{ item.repurchaseRate }} {{ '%' }}</p>
          </div>

        </div>
        <span class="collect" @click.stop="favoriteGoodsAdd(item)">
          <div>
            <img v-if="item.goodsFavoriteStatus" src="../../assets/icon/collection2.png" />
            <img v-else src="../../assets/icon/collection1.png" />
          </div>
        </span>
      </div>
      <div v-if="slideLoading && !Loadingcompleted && !loading" class="keywordLoadingContainer">
        <div class="flexAndCenter">
          <img alt="" src="../../assets/newImg/order/loading.gif" />
          <div>{{ $fanyi('加载中') }}</div>
        </div>
      </div>
    </div>

    <!--      <el-pagination-->
    <!--        @current-change="handleCurrentChange"-->
    <!--        :current-page="form.page"-->
    <!--        :page-size="20"-->
    <!--        layout="total, prev, pager, next, jumper"-->
    <!--        :total="total"-->
    <!--      >-->
    <!--      </el-pagination>-->
    <!-- <div class="allpag" v-if="total > 0">
      <span></span>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="form.page" :page-size="20"
        layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div> -->

    <Foot></Foot>
  </div>
</template>

<script>
import Foot from '../../components/foot/Foot.vue'
import Dialog from '../../components/public/Dialog'
import HomePageTop from '../../components/head/HomePageTop.vue'
export default {
  name: 'search',
  // // 组件进入调用的函数钩子
  // beforeRouteEnter(to, from, next) {

  //   console.log(to.path)
  //   if (to.path == '/commoditySearch') {
  //     this.form.page = this.$store.state.shoplistpage
  //     next(true)
  //   }
  //   if (from.path == '/productDetails') {
  //     next(true)
  //   }
  // },
  // 组件离开调用的和函数够子
  beforeRouteLeave(to, from, next) {
    if (to.path !== '/productDetails') this.$store.commit('getshoplistpage', 1)
    next()
  },
  data() {
    return {
      form: {
        page: 1,
      }, //搜索条件
      imgForm: {}, //图片搜索条件
      total: 0,
      keyWord: '',
      imageId: '', // 存储图搜id
      type: '',
      shopComment: '',// 店铺点评
      shopchecked: '',// 1688 严选
      priceStart: '',// 最低价格
      priceEnd: "",// 最高价格
      sortindex: '',// 综合排序
      snId: "",// 搜索栏选中的id
      sort: '',// 排序
      filter: '',// 筛选
      selectionoffactorieschecked: '', // 严选工厂
      Samedayshipment: '',// 当日出货
      Shipmentwithin24hours: '',// 24小时以内发货
      Shipmentwithin48hours: '',// 48小时以内发货
      noreasontoreturnandexchangegoodsfor7days: '',// 七天无理由退款
      Orderonefromthebeginning: '',// 1件起订
      keywordSNQuerylist: [],// 关键字搜索列表
      slideLoading: false,
      Loadingcompleted: false,
      StrictselectionArr: [
        {
          bottom: false,
        },
        {
          bottom: false,
        },
        {
          top: false,
          bottom: false,
        },
      ],
      options: [
        {
          value: '', // 全部
          label: this.$fanyi('全部'),
        },
        {
          value: 'totalEpScoreLv1', // 5 星
          label: this.$fanyi('5星'),
        },
        {
          value: 'totalEpScoreLv2', // 4.5-5
          label: this.$fanyi('4.5星~5星'),
        },
        {
          value: 'totalEpScoreLv3', // 4-4.5
          label: this.$fanyi('4星~4.5星'),
        },
      ],


      selectIconIndex: 0,
      shop_type: 1688,
      lists: {
        result: []
      },
      loading: false,
      tiShi: this.$fanyi('加载中'), //提示语句
      // 是否是横版
      Crosswise: true,
      isImgSearch: false,
      notCreate: true,
      goodsListIsNull: false, //搜索结果是否有商品
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('提示'),
        btnTxt: [this.$fanyi('确定')],
      },
    }
  },
  components: {
    HomePageTop,
    Foot,
    Dialog,
  },
  computed: {},
  created() {

    if (this.$route.query.type == 'imgSearch') {
      this.isImgSearch = true
      this.imageId = this.$route.query.imageId
    }
    this.initialized()
    // 调用多语言搜索导航
    this.keywordSNQuerysearch()
  },
  watch: {
    $route: {
      handler(route) {
        if (route.name == 'CommoditySearch') {
          if (this.$route.query.type == 'imgSearch') {
            this.isImgSearch = true
            this.imageId = this.$route.query.imageId
          } else {
            this.isImgSearch = false
          }
          this.initialized()
        }
      },
    },
  },
  methods: {
    // 添加收藏商品
    favoriteGoodsAdd(item) {
      if (!this.$store.state.userInfo) {
        return this.$refs.dialog.open(
          this.$fanyi('是否前往登录?'),
          () => {
            this.$fun.toPage('/login')
          },
          () => { }
        )
      }

      let datas = {
        goods_id: item.goods_id,
        title: item.title,
        image_url: item.imgUrl,
        price: item.goodsPrice,
        shop_type: item.shop_type,
      }
      if (!item?.goodsFavoriteStatus) {
        this.$api.favoriteGoodsAdd(datas).then((res) => {
          if (res.code != 0) return
          this.$message.success(this.$fanyi('添加收藏成功'))
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus
          this.$forceUpdate()
        })
      } else {
        this.$api
          .favoriteGoodsDelete({
            goods_id: item.goods_id,
          })
          .then((res) => {
            if (res.code != 0) return
            this.$message.success(this.$fanyi('取消收藏成功'))
            item.goodsFavoriteStatus = !item.goodsFavoriteStatus
            this.$forceUpdate()
          })
      }
    },

    // 路由改变时的操作
    initialized() {
      // 如果路由中带有CN就代表是点击首页的分类跳转过来的，需要读取路由中的中文关键词以进行精确搜索，否则获取路由中的普通关键词
      if (!!this.$route.query.CN) {
        this.keyWord = this.$fun.fromCode(this.$route.query.CN)
      } else if (this.$route.query.keyword) {
        if (this.$fun.to_link(this.$store.state.searchInfo) != false) {
          this.keyWord = this.$store.state.searchInfo
        } else {
          // let keywordIndex = location.href.indexOf('?keyword=')
          // let keywordText = location.href.substr(
          //   keywordIndex + 9,
          //   location.href.length
          // )
          // keywordText = keywordText.substr(0, keywordText.length - 10)
          this.keyWord = this.$route.query.keyword
        }
      }
      // 讲关键词传到vuex以传值到头部搜索框
      this.$store.commit('getsearchInfo', this.$store.state.searchInfo)
        ; (this.form.keyWords = this.keyWord),
          (this.form.shop_type = this.shop_type)

      this.$forceUpdate()
      this.getData(true)

    },

    async keywordSNQuerysearch() {
      const res = await this.$api.keywordSNQuerysearch({
        keyword: this.keyWord,
      })
      if (res.code !== 0) {
        return
      }
      this.keywordSNQuerylist = res.data
    },

    // 获取列表数据
    async getData(bolb, type) {
      if (type) {
        this.lists.result = []
      }
      this.goodsListIsNull = false
      this.Loadingcompleted = false
      this.loading = bolb
      this.form.pageSize = 20
      // this.form.page = this.$store.state.shoplistpage
      this.form.snId = this.snId
      this.form.sort = this.sort
      this.form.filter = this.filter
      this.form.priceStart = this.priceStart, // 开始价格
        this.form.priceEnd = this.priceEnd // 结束价格

      // 如果路由中的type值为imgSearch就代表是图片搜索
      if (this.$route.query.type == 'imgSearch') {
        // 图片搜索
        await this.$api
          .imageSearchProduct({
            imageId: this.imageId,
            shop_type: this.shop_type,
            picUrl: this.$route.query.imgUrl,
            page: this.form.page,
            pageSize: this.form.pageSize,
            ...this.imgForm,
          })
          .then((result) => {
            if (result.code != 0) {
              this.goodsListIsNull = true
              return
            }
            this.loading = false
            this.imageId = result.data.imageId
            this.slideLoading = false
            if (result.data.length == 0) {
              this.goodsListIsNull = true
              return
            }
            this.lists.result = [...this.lists.result, ...result.data.data]

            this.total = result.data.total

            if (result.data.result.length == 0 && result.data.total == 0 && this.form.page > 1) {
              this.Loadingcompleted = true
            } else if (result.data.result.length == 0 && result.data.total == 0 && this.form.page == 1) {
              this.goodsListIsNull = true
            }

          })
          .catch((err) => { })
        // 将关键词搜索的关键词清空
        this.$store.commit('getsearchInfo', '')
      } else {
        // 关键词搜索

        this.form.shop_type = this.shop_type

        await this.$api
          .EuropeKeywordSearchProduct(this.form)
          .then((result) => {
            if (result.code != 0) {
              this.goodsListIsNull = true
              return
            }
            this.slideLoading = false
            this.loading = false

            if (result.data.result) {
              this.lists.result = [...this.lists.result, ...result.data.result]
            }

            this.total = result.data.total

            this.$forceUpdate()
            if (result.data.data_type == 'goods_detail') {

              if (this.$fun.isOfficialWeb()) {
                __bl.sum('用户通过链接搜索数量统计');
              }
              this.$fun.toCommodityDetails({
                goods_id: result.data.result.goods_id,
                shop_type: result.data.result.shop_type,
                type: 'linkSearch'
              })

              return

            } else {
              if (result.data.result.length == 0 && result.data.total == 0 && this.form.page > 1) {
                this.Loadingcompleted = true
              } else if (result.data.result.length == 0 && result.data.total == 0 && this.form.page == 1) {
                this.goodsListIsNull = true
              }
            }
          })
          .catch((err) => { })
        // 将图片搜索的网址清空
        this.$store.commit('getsearchImg', '')
        this.favoriteGoodsCheckIn()
      }
    },

    Selectchange() {
      let arr = [];
      this.filter = ''
      // 1688严选
      if (this.shopchecked) {
        arr.push('jxhy')
      }
      // 严选工厂
      if (this.selectionoffactorieschecked) {
        arr.push('certifiedFactory')
      }
      // 当日出货
      if (this.Samedayshipment) {
        arr.push('shipInToday')
      }
      // 24小时出货
      if (this.Shipmentwithin24hours) {
        arr.push('shipln24Hours')
      }
      // 48小时以内出货
      if (this.Shipmentwithin48hours) {
        arr.push('shipln48Hours')
      }
      // 7天无理由退换商品
      if (this.noreasontoreturnandexchangegoodsfor7days) {
        arr.push('noReason7DReturn')
      }
      // 1件起订
      if (this.Orderonefromthebeginning) {
        arr.push('isOnePsale')
      }
      //店铺点评星级
      if (this.shopComment) {
        arr.push(this.shopComment)
      }
      if (arr.length != 0) {
        this.filter = arr.join(',')
      }
      this.form.page = 1
      this.getData(true, 'Sort')
    },
    change() {

    },
    switchSort(index) {
      this.sort = ''
      this.form.page = 1
      if (index == 6) {
        this.sortindex = !this.sortindex
        this.getData(true, 'Sort')
        this.StrictselectionArr?.forEach((item) => {
          ; (item.top = false), (item.bottom = false)
        })
        return
      }
      this.sortindex = false
      this.StrictselectionArr?.forEach((item, i) => {
        if (index == i) {
          if (i == 0) {
            item.bottom = !item.bottom
            if (item.bottom) {
              this.sort = "{'monthSold':'desc'}"
            }
            this.getData(true, 'Sort')
          }
          if (i == 1) {
            item.bottom = !item.bottom
            if (item.bottom) {
              this.sort = "{'rePurchaseRate':'desc'}"
            }
            this.getData(true, 'Sort')
          }
          if (i == 2) {
            // 说明是第一次
            if ((!item.top && !item.bottom) || (item.bottom && !item.top)) {
              item.top = true
              item.bottom = false
              // 上
              this.sort = "{'price':'asc'}"
              this.getData(true, 'Sort')
              return
            }
            if (item.top && !item.bottom) {
              item.top = false
              item.bottom = true
              // {"price":"desc"}向下
              this.sort = "{'price': 'desc'}"
              this.getData(true, 'Sort')
              return
            }
          }
        } else {
          item.bottom = false
          item.top = false
          this.sort = ''
        }
      })
    },
    toCommodityDetails(item) {
      if (this.$route.query.type == 'keywordSearch') {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('通过关键词搜索进入商品详情数量统计');
        }
        this.$fun.toCommodityDetails({
          goods_id: item.goods_id,
          shop_type: item.shop_type,
          type: 'keywordsearch'
        })
      } else if (this.$route.query.type == 'imgSearch') {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('通过图片搜索进入商品详情数量统计');
        }
        this.$fun.toCommodityDetails({
          goods_id: item.goods_id,
          shop_type: item.shop_type,
          type: 'imgSearch'
        })
      } else if (this.$route.query.type == 'classificationSearch') {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('通过分类搜索进入商品详情数量统计');
        }
        this.$fun.toCommodityDetails({
          goods_id: item.goods_id,
          shop_type: item.shop_type,
          type: 'classificationSearch',
        })
      } else {
        this.$fun.toCommodityDetails({
          goods_id: item.goods_id,
          shop_type: item.shop_type,
        })
      }


    },
    //多语言导航选择
    setSnId(item, childrenIndex) {
      if ((item.active != null && item.active != childrenIndex) || item.active == null) {
        item.active = childrenIndex;
        // if (this.$fun.isOfficialWeb()) {
        //   __bl.sum('选择多语言导航次数');
        // }
      } else if (item.active != null && item.active == childrenIndex) {
        item.active = null;
      }
      let arr = [];
      this.keywordSNQuerylist?.forEach((item) => {
        if (item.active != null) {
          arr.push(item.children[item.active].id)
        }
      })
      this.snId = arr.join(';');

      this.form.page = 1;
      this.getData(true, 'Sort')
    },
    // 查看店铺是否已收藏
    favoriteGoodsCheckIn() {
      let ids = []
      if (this.lists.result?.constructor === Array) {
        this.lists.result?.forEach((goodsItem) => {
          goodsItem.goodsFavoriteStatus = false
          ids.push(goodsItem.goods_id)
        })
      }
      if (this.lists.result.constructor === Object) {
        ids.push(this.lists.result.goods_id)
      }

      this.$api
        .favoriteGoodsCheckIn({
          goods_id: JSON.stringify(ids),
        })
        .then((res) => {
          if (this.lists.result.constructor === Array) {
            res.data.forEach((resItem, resIndex) => {
              this.lists.result[resIndex].goodsFavoriteStatus =
                resItem.inFavorite
            })
          }
          if (this.lists.result.constructor === Object) {
            this.lists.result.goodsFavoriteStatus = res.data[0].inFavorite
          }
        })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.page = 1
      this.getData(true)
    },
    handleCurrentChange(val) {



      this.$store.commit('getshoplistpage', val)

      this.getData()
    },
    getbottom() {
      // 返回滚动条垂直滚动距离
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      let numHeight = scrollTop + clientHeight
      if (numHeight > scrollHeight - 200) {
        if (!this.goodsListIsNull && this.slideLoading == false && !this.loading && !this.Loadingcompleted) {
          this.slideLoading = true
          this.form.page = this.form.page + 1

          if (!this.Loadingcompleted) {
            this.getData(false)
          }

        }
      }
    },
  },

  mounted() {
    // 当组件被挂载到 DOM 上时，添加滚动事件监听器
    window.addEventListener('scroll', this.getbottom);
    window.addEventListener('beforeunload', () => {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    });
  },
  beforeDestroy() {

  },
}
</script>

<style lang="scss">
.qieHuanPaiBan {
  min-width: 160px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  .PaiBanOpt {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;


    span {
      font-size: 14px;
      min-width: 100px;


    }

    img {
      width: 20px;

      height: 20px;
      margin-left: 22px;
    }
  }
}
</style>

<style lang="scss" scoped>
#CommoditySearch {
  @import '../../css/mixin';

  width: 100%;
  background-color: #f6f6f6;

  .platformListContainer {
    width: 1400px;
    margin: 0 auto;
    height: 40px;

    .platformContainer,
    .selectPlatformContainer {
      width: 128px;
      height: 40px;
      border: 1px solid #ff730b;

      color: #222222;
      justify-content: center;
      cursor: pointer;
    }

    .selectPlatformContainer {
      background: #ff730b !important;
      color: #fff !important;
    }

    .flexAndCenter:first-child {
      img {
        width: 38px;
        height: 20px;
        margin-right: 8px;
      }
    }

    .flexAndCenter:last-child {
      img {
        width: 26px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }

  nav.strSearch {
    // height: 144px;
    border-radius: 6px;
    padding: 20px 30px;
    padding-bottom: 0px;
    width: $pageWidth;
    margin: 0 auto 0px;
    position: relative;
    left: 0px;
    border-bottom: solid 1px #e2e2e2;
    background-color: #fff;
    margin-bottom: 20px;

    /deep/.el-image {
      margin-right: 40px;
      border: 2px solid #ff730b;
      $imgSize: 80px;
      width: $imgSize;
      height: $imgSize;
      transition: 0.3s;
      text-align: center;
      border-radius: 6px;
      padding: 2px;
      margin-left: 5px;
      vertical-align: middle;

      i {
        font-size: $imgSize/4;
        line-height: $imgSize;
      }
    }

    >div {
      display: flex;
    }

    .topCon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .keyword {
        margin: 0 10px 0 0;
      }

      .query {
        color: #608bff;
      }

      .quantity {
        margin-left: auto;

        font {
          margin: 0 5px;
        }
      }
    }

    .searchitem {
      width: 100%;
      min-height: 50px;

      border-top: 1px dashed #E2E2E2 !important;
      display: flex;
      align-items: center;
      padding: 5px 0;

      .searchitem-title {
        font-size: 14px;
        font-family: Roboto, Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        margin-right: 40px;
        line-height: 16px;
        text-align: left;
        width: 165px;
      }

      .searchitem-list {
        display: flex;

        span {
          min-height: 25px;
          text-align: center;
          display: flex;
          font-size: 14px;
          color: #333333;
          align-items: center;
          margin-right: 40px;
          cursor: pointer;
        }
      }
    }



    .spanactive {
      min-height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff !important;
      padding: 8px 8px;
      text-align: center;
      border-radius: 6px;
      background: linear-gradient(90deg, #FF7E3E, #FF5010, #FE4B0F);
    }

    .Strictselection,
    .advantage {
      display: flex;
      height: 60px;
      align-items: center;
      border-bottom: 1px dashed #cccccc;

      .fornecedor {
        min-width: 153px;
        font-family: Roboto, Roboto;
        font-weight: 500;
        font-size: 16px;
        color: #222222;
        line-height: 24px;
        margin-right: 50px;
      }

      .Strictselection-ite,
      .advantage-ite {
        margin-right: 83px;
        font-weight: 400;
        text-align: center;

        font-size: 14px;
        color: #000000;
        display: flex;
        align-items: center;

        span {
          display: flex;
          margin-left: 5px;
          font-size: 14px;
        }

        .selett-title {
          margin-right: 10px;
        }

        /deep/.el-select {
          height: 28px;
          border-radius: 6px;
          width: 130px;
          font-size: 14px;
        }

        /deep/.el-input__inner {
          border-radius: 6px;
          width: 130px;
          height: 28px;
          font-size: 14px;
        }

        /deep/.el-input__suffix-inner {
          height: 28px !important;
        }

        /deep/.el-icon-arrow-up {


          &::before {
            position: absolute;
            top: 0px;
            right: 5px;
            height: 28px !important;
            line-height: 28px;

          }
        }


      }

      /deep/.el-checkbox {


        width: 15px;
        height: 15px;

        background: #ffffff;
        border-radius: 4px;

        .el-checkbox__input {
          width: 15px !important;
          border-radius: 4px;
        }

        .el-checkbox__inner {
          width: 15px !important;
          border-radius: 4px;

          height: 15px;

          &::after {
            width: 2.5px;
            left: 5px;
            height: 7px;
          }

          &:hover {
            border-color: #ff730b !important;
          }
        }

        .is-checked {
          .el-checkbox__inner {
            background-color: #ff730b;
            border-color: #ff730b;
          }
        }
      }
    }

    .Strictselection {
      border-top: 5px solid #F6F6F6;
    }

    .advantage {
      border-bottom: 5px solid #F6F6F6;
    }

    .sort {
      display: flex;
      height: 60px;
      align-items: center;

    }

    .fornecedor {
      min-width: 102px;
      font-weight: 400;
      font-size: 14px;
      color: #222222;
      line-height: 20px;
      text-align: left;
      margin-right: 70px;
    }

    .sort-item {
      margin-right: 80px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #222222;
      line-height: 20px;


      &:hover {
        cursor: pointer;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }

      img {
        margin-left: 3px;
        width: 12px;
        height: 6px;
      }

      .pricesort {
        display: flex;
        flex-direction: column;

        padding-top: 2px;

        img {
          &:first-child {
            margin-bottom: 2px;
          }
        }
      }

      .inputbox {
        width: 115px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #999999;
        border-radius: 6px;
        padding-left: 5px;
        display: flex;
        align-items: center;

        span {
          flex: 1;
          display: flex;
          align-items: center;
        }

        input {
          border-radius: 6px;
          width: 90px;
          border: none;
        }
      }

      p {
        margin: 0 5px;
      }

      .qrbutton {
        margin-left: 20px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        height: 30px;
        background-color: #ff730b;
        border-radius: 6px;
      }
    }

    .activecolor {
      color: #ff730b !important;
      font-weight: 700;
    }

  }

  nav.imgSearch {
    padding: 20px;
    background-color: #fff;
    margin: 0px auto 20px;
    width: $pageWidth;
    border-bottom: solid 1px #e2e2e2;
    border-radius: 4px;
    display: flex;
    align-items: center;
    position: relative;

    span {
      position: absolute;
      right: 0;
      top: 0;
    }

    .crosswise {
      display: flex;
      align-items: center;
      margin-left: auto;

      // background-color: pink !important;
      .quantity {
        margin-left: auto;

        font {
          margin: 0 5px;
        }
      }
    }

    /deep/.el-image {
      margin-right: 40px;
      border: 2px solid #ff730b;
      $imgSize: 80px;
      width: $imgSize;
      height: $imgSize;
      transition: 0.3s;
      text-align: center;
      border-radius: 6px;
      padding: 2px;
      margin-left: 5px;

      i {
        font-size: $imgSize/4;
        line-height: $imgSize;
      }
    }

    .imgSearchClass {
      display: flex;

      button {
        &.active {
          color: $homePageSubjectColor;
          border-color: $homePageSubjectColor;
        }

        border: 1px solid #888888;
        padding: 4px 12px;
        font-size: 12px;
        border-radius: 5px;
        background-color: transparent;
        margin-right: 10px;
      }
    }
  }



  .tiShiBox {
    font-size: 25px;
    text-align: center;
  }

  .SearchConterCrosswise {
    width: 1400px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    min-height: 280px;
    justify-content: space-between;
    position: relative;

    .SearchConterCrosswiseCon {
      box-sizing: content-box;
      width: 262px;
      overflow: hidden;
      border-radius: 6px;
      border: 1px solid #e2e2e2;

      background-color: #fff;
      margin-bottom: 20px;

      &:nth-child(5n + 1) {
        margin-right: 0;
      }

      position: relative;

      &:hover {
        .similarProducts {
          opacity: 1;
        }
      }

      &:last-child {
        margin-right: 0px;
      }

      >div {
        cursor: pointer;

        &.SearchConterCrosswiseImg {
          overflow: hidden;
          width: 262px;
          position: relative;

          .goodsImg {
            background-color: transparent;
          }

          .el-image {
            transition: 0.3s;
            width: 100%;
            min-height: 100%;
          }

          &:hover {
            .el-image {
              transform: scale(1.2, 1.2);
            }
          }
        }

        &.rests {
          box-sizing: border-box;
          padding: 10px 20px 15px 20px;
          padding-bottom: 5px;

          p {
            font-size: 12px;
            font-weight: 400;
            color: #222;

            &.goodsTitle {
              display: block;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              height: 60px;
              font-size: 14px;
              color: #222222;
              line-height: 20px;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }

            &:nth-child(2) {
              margin-top: 6px;
              height: 35px;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              >span {
                &:first-child {
                  font-size: 16px;
                  font-weight: 600;
                  color: #ff730b;
                  line-height: 35px;
                }
              }
            }
          }
        }

      }

      .Monthlysalesvolume {
        width: 132px;
        height: 18px;
        background: #E9EEFF;
        border-radius: 2px;
        margin-bottom: 7px;
        font-family: Roboto, Roboto;
        font-weight: 400;
        font-size: 12px;
        color: #698CFF;
        margin-left: 20px;
        display: flex;
        align-items: center;

        text-align: center;
        font-style: normal;
        padding: 0 5px;
      }

      .Repurchaserate {
        width: 172px;
        height: 18px;
        padding: 0 5px;
        margin-left: 20px;
        display: flex;
        align-items: center;

        background: #E4F5F0;
        border-radius: 2px;
        font-family: Roboto, Roboto;
        font-weight: 400;
        margin-bottom: 22px;
        font-size: 12px;
        color: #47BD9B;
        line-height: 14px;
        text-align: left;
        font-style: normal;
      }
    }


  }

  .SearchConterVertical {
    width: $pageWidth;
    min-height: 300px;
    margin: 0 auto;

    /deep/.el-image__inner {
      border-radius: 6px;
    }

    .item {
      padding: 20px;
      padding-right: 30px;
      background: #ffffff;
      border-radius: 6px;
      margin-bottom: 20px;
      display: flex;
      position: relative;
      cursor: pointer;

      /deep/.el-image,
      .hengxiang {
        $imgSize: 160px;
        width: $imgSize;
        height: $imgSize;
        transition: 0.3s;
        text-align: center;
        margin-right: 20px;

        i {
          font-size: $imgSize/4;
          line-height: $imgSize;
        }
      }

      .VerticalInfo {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-start;

        span {
          &.vGoodsTitle {
            font-size: 16px;
            width: 90%;
            height: 48px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #222222;
            line-height: 24px;
          }

          &.price {
            height: 48px;
            font-size: 24px;
            font-weight: bold;
            color: #ff730b;
            line-height: 48px;
          }


        }

        .xiaoliag {
          height: 20px;
          font-size: 14px;
          color: #999999;
          line-height: 20px;
          position: absolute;
          left: 200px;
          bottom: 45px;

          P {
            font-size: 14px;
            color: #999999;
            margin-bottom: 3px;
            line-height: 20px;
          }
        }
      }

      .collect {
        width: 56px;
        height: 56px;
        background: #f5f5f5;
        border-radius: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);

        >div {
          width: 20px;
          height: 20px;
        }

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

/deep/.el-image {
  text-align: center;

  i {
    font-size: 40px;

    line-height: 330px;
  }
}

// 切换显示商品形式
.crosswise {
  width: 80px;
  height: 40px;
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    $searchImgSize: 20px;
    width: $searchImgSize;
    height: $searchImgSize;
  }

  .downIcon {
    width: 12px;
    height: 12px;
    margin-left: 8px;
  }
}

.similarProducts {
  position: absolute;
  top: 5px;
  right: 10px;
  opacity: 0;
  transition: 0.3s;
  background-color: white;
  border: solid 1px #ffa018;
  color: #ffa018;
  font-size: 14px;
  padding: 4px 12px;
  z-index: 3;
  border-radius: 5px;

  &:hover {
    color: white;
    background-color: #ffa018;
    opacity: 1;
  }
}

.keywordLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1400px;
  height: 180px;
  padding: 20px;

  .flexAndCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  img {
    width: 40px;
  }

  div {
    font-size: 14px;
  }
}

.goodsListIsNull {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  font-weight: bold;
  color: #ccc;
  height: 400px;
}

/deep/.el-loading-mask {
  z-index: 5;
}

/deep/.el-image.biggoodsImg {
  $imgSize: 240px;
  width: $imgSize;
  height: $imgSize;
  transition: 0.3s;
  text-align: center;

  i {
    font-size: $imgSize/4;
    line-height: $imgSize;
  }
}

.allpag {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;

  .el-pagination {
    display: flex;
    align-items: center;
  }

  >a {
    display: inline-block;
    border: 1px solid #dcdfe6;
    height: 28px;
    // background-color: white;
    text-align: center;
    padding: 0 10px;
    font-size: 13px;
    line-height: 28px;
    border-radius: 0;
  }

  /deep/.btn-prev {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 20px;
    padding: 0;
    margin-right: 30px;

    .el-icon {
      font-size: 15px;
    }
  }

  /deep/.btn-next {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 20px;
    padding: 0;
    margin-left: 30px;

    .el-icon {
      font-size: 15px;
    }
  }

  /* 分页被选中的页码样式 */
  /deep/ .el-pager li {
    margin: 0 10px;
    // border-radius: 0;
    min-width: 20px !important;
    font-size: 14px;
    height: 30px;
    color: #999999;
    background-color: #f6f6f6;
    // box-shadow: 0 0 6px 0 rgba(61, 61, 61, 0.1);

    &:hover {
      color: #ff730b;
    }

    &.active {
      color: #ff730b;
      border-bottom: 2px solid #ff730b;
      min-width: 20px;
    }
  }
}
</style>
