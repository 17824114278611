<template>
  <div id="commodity">
    <!-- 删除弹窗 -->
    <Dialog ref="dialog" :config="config" />
    <!-- 添加弹窗 -->
    <addDia />
    <nav style="display: flex;justify-content: space-between">
      <div class="navleft">
        <div style="display: flex;">
          <el-checkbox v-model="checked" @change="allChoice" class="chiose"></el-checkbox>
          <span>{{ $fanyi('全选') }}</span>
        </div>
        <div @click="delGoods()" class="delete">{{ $fanyi('删除') }}</div>
      </div>
      <div class="navRight">
        <div @click="addDiaStuts = !addDiaStuts" class="add">
          {{ $fanyi('添加') }}
        </div>
        <div @click="jieSuan" class="jieSuan" style="margin-right:0">
          {{ $fanyi('提出订单') }}
        </div>
      </div>
    </nav>
    <el-table v-loading="loading" :data="tableData" border :header-cell-style="{
      'text-align': 'center',
      background: '#F0F0F0',
      'font-size': '14px',
      'text-align': 'center',
      color: '#000000',
      'font-weight': 'normal',
      'background-color': '#F0F0F0',
    }" :cell-style="{ 'text-align': 'center' }">
      <el-table-column :label="$fanyi('选择')" width="85">
        <template slot-scope="scope">
          <div>
            <input class="chiose" type="checkbox" v-model="scope.row.checked" @change="oddChoice(scope.row)
              " />
          </div>
        </template>
      </el-table-column>
      <el-table-column type="index" label="NO" width="79">
        <template slot-scope="scope">
          <div>
            {{ (Number(form.page) - 1) * 10 + Number(scope.$index) + 1 }}
          </div>
        </template>
      </el-table-column>
      <el-table-column property="name" :label="$fanyi('照片')" width="120">
        <template slot-scope="scope">
          <div>
            <el-popover placement="right" trigger="hover">
              <img :src="scope.row.img_url" alt="" style="width: 300px; height: 300px" />
              <el-image slot="reference" :src="scope.row.img_url" @click="
                $fun.toCommodityDetails({
                  goods_id: scope.row.goods_id,
                  shop_type: scope.row.shop_type,
                })
                " style="width: 100px; height: 100px; cursor: pointer">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
                <div slot="placeholder">
                  <i class="el-icon-loading"></i>
                </div>
              </el-image>
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column property="name" :label="$fanyi('商品链接')" width="180">
        <template slot-scope="scope">
          <el-popover placement="bottom" width="400" trigger="hover">
            {{ scope.row.goods_title }}

            <div class="goodsUrl" slot="reference">
              <a :href="'	https://www.rakumart.us/productDetails?goods_id=' +
                scope.row.goods_id +
                '&shop_type=' +
                scope.row.shop_type
                ">
                {{ scope.row.goods_title }}
              </a>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column :label="$fanyi('商品属性')" width="175">
        <template slot-scope="scope">
          <el-popover placement="bottom" @hide="hide" width="420" trigger="click">
            <div class="chioceDetail">
              <div class="dia" v-for="(item, index) in scope.row.specification" :key="index">
                <span class="chioceTitle">{{ item.key }}:</span>
                <span class="chioceBody">
                  <span class="chioceBodyOpt" v-for="(valueItem, valueIndex) in item.value"
                    :class="{ active: item.active == valueIndex }" :key="valueIndex" @click="
                      getColor(
                        valueIndex,
                        item,
                        index,
                        scope.row.specification_checked,
                        scope.$index
                      )
                      ">
                    {{ valueItem.name }}
                  </span>
                </span>
              </div>
            </div>
            <div slot="reference" class="goodsDetail"
              @click="copyOption = JSON.parse(JSON.stringify(scope.row.option))">
              <div class="detail">
                <div class="detailCon">
                  <p v-for="(item, index) in scope.row.specification_checked" :key="index"
                    :title="item.key + ':' + item.value">
                    {{ item.key }} : {{ item.value }}
                  </p>
                  <!-- {{ scope.row.specification }} -->
                </div>
              </div>
              <img src="../../../../assets/newImg/order/modify.svg" alt="" />
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column :label="$fanyi('单价')" width="130" :show-overflow-tooltip="true">
        <template slot-scope="scope">

          <span>${{
            $fun.EURNumSegmentation(
              $fun.ceil(scope.row.price * $store.state.exchangeRate)
            )
          }}
            ({{ $fun.RMBNumSegmentation(scope.row.price) }}￥)</span>
        </template>
      </el-table-column>
      <el-table-column property="status" :label="$fanyi('商品状态')" width="145">
        <template slot-scope="scope">
          <div :class="{ shangpinstate: scope.row.status !== 1 }">
            {{ scope.row.status == 1 ? $fanyi('正常') : $fanyi('已下架') }}
          </div>
        </template>
      </el-table-column>
      <el-table-column property="num" width="158" :label="$fanyi('数量')">
        <template slot-scope="scopeer">
          <div class="inputNumberContainer">
            <el-input-number size="mini" v-model="scopeer.row.num" :min="1"
              @change="saveGoodsNum(scopeer.row, scopeer.$index)"></el-input-number>
          </div>
        </template>
      </el-table-column>
      <!-- 附加服务 -->
      <el-table-column property="name" :label="$fanyi('附加服务费用')" width="220">
        <template slot-scope="scope">
          <el-popover placement="bottom" v-model="scope.row.popoverOptionShowStatus" trigger="manual">
            <div class="optionsGroup">
              <!-- 附加服务列表 -->
              <el-table :data="optionList" cell-class-name="cellClassName" header-row-class-name="cellHeadClassName"
                :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
                <el-table-column property="date" width="40" label="">
                  <template slot-scope="scopeer">
                    <div>

                      <input class="chiose" type="checkBox" :checked="scopeer.row.checked" @click="
                        scopeer.row.checked = !scopeer.row.checked
                      setoneGoodsOptionTotalPrice(scope.row, scopeer.row)
                        " />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column property="name_translate" width="260" :label="$fanyi('附加服务选择')"></el-table-column>
                <el-table-column property="num" width="158" :label="$fanyi('数量')">
                  <template slot-scope="scopeer">
                    <div>
                      <el-input-number size="small" v-model="scopeer.row.num" :min="0" @change="
                        setoneGoodsOptionTotalPrice(scope.row, scopeer.row)
                        "></el-input-number>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="128" :label="$fanyi('单价')">
                  <template slot-scope="scopeer">
                    <div>
                      ${{
                        $fun.EURNumSegmentation(
                          (
                            scopeer.row.price * $store.state.exchangeRate
                          ).toFixed(2)
                        )
                      }}
                      <span>({{
                        $fun.RMBNumSegmentation(scopeer.row.price)
                      }}￥)</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="158" :label="$fanyi('预计费用')">
                  <template slot-scope="scope">

                    ${{
                      $fun.EURNumSegmentation(
                        (
                          scope.row.price *
                          scope.row.num *
                          $store.state.exchangeRate
                        ).toFixed(2)
                      )
                    }}
                    <span>({{
                      $fun.RMBNumSegmentation(
                        scope.row.price * scope.row.num
                      )
                    }}￥)</span>
                  </template>
                </el-table-column>
                <el-table-column width="158">
                  <template slot-scope="scope">
                    <el-input size="small" v-if="scope.row.checked && scope.row.name == 'SKU'" v-model="scope.row.fba"
                      type="textarea" placeholder="FBA（FNSKU）" />
                  </template>
                </el-table-column>
              </el-table>
              <div class="additionalservicesfoot">
                <p>
                  {{ $fanyi('合计约为') }}：<font>{{
                    oneGoodsOptionTotalPrice
                  }}</font>
                </p>
                <p>
                  <button class="react" @click="optionReact(scope.$index)">
                    {{ $fanyi('重置') }}
                  </button>
                  <button class="save" @click="saveOrderOption(scope.row)">
                    {{ $fanyi('保存') }}
                  </button>
                </p>
              </div>
            </div>
            <!-- 附加服务 -->
            <div slot="reference" @click="
              setOrderOption(scope.row.option, scope.$index)
            setoneGoodsOptionTotalPrice({
              num: scope.row.num
            })
              " class="optionsBox">
              <p class="options" v-for="(optItem, optIndex) in scope.row.option" :key="optIndex"
                v-show="optItem.checked">
                <span style="display: inline-block;" v-if="optItem.num > 0">
                  {{ optItem.tag }}{{ optItem.name_translate }} *
                  {{ optItem.num }}
                </span>
              </p>
              <p class="ploceholdX" v-show="haveOption(scope.row.option)">

                Click to select another service
              </p>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <!-- 备注 -->
      <el-table-column property="" :label="$fanyi('备注')" width="80px">
        <template v-slot="scope">
          <el-popover placement="bottom-start" ref="mergePopover" width="420" popper-class="goodsRemarksPopoverClass"
            v-model="scope.row.popoverRemarkShowStatus" trigger="manual">
            <div class="goodRemarksContainer">
              <div class="text">{{ $fanyi('商品备注详情') }}</div>
              <el-input type="textarea" style="resize:none;" :ref="'reIn' + scope.$index"
                :placeholder="$fanyi('请输入备注信息')" v-model="scope.row.remarks" v-focus />
              <div class="goodsSaveRemarksContainer">
                <div class="goodsSaveRemarks">
                  <div @click="
                    scope.row.popoverRemarkShowStatus = false
                    ">
                    {{ $fanyi('取消') }}
                  </div>
                  <div @click="
                    goodsSaveRemarks(scope.row.id, scope.row.remarks, scope.row)
                    ">
                    {{ $fanyi('保存') }}
                  </div>
                </div>
              </div>
            </div>
            <img slot="reference" :src="scope.row.note && scope.row.note.length > 0
              ? require('../../../../assets/user-img/remarks_2.png')
              : require('../../../../assets/user-img/remarks_1.png')
              " style="width:32px;height:32px;cursor: pointer" @click="updatePopoverRemarkShowStatus(scope.$index)" />
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <div class="allpag">
      <el-pagination @current-change="handleCurrentChange" :current-page="form.page" :page-size="10"
        layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>

import Dialog from '../../../../components/public/Dialog'
import addDia from './commpones/addDia.vue'

export default {
  name: 'commodity',
  data() {
    return {

      checked: false, //全选
      form: {
        page: 1,
        pageSize: 10,
      },
      loading: false,
      tableData: [], //表格数据
      optionList: [], //附加服务列表数据

      actIds: [], //选中的id

      copyOption: [],
      allactId: [], //所有选中的id
      oneGoodsOptionTotalPrice: 0,
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('提示'),
        btnTxt: [this.$fanyi('删除'), this.$fanyi('取消')],
      },
      addDiaStuts: false,
      total: 0,
    }
  },
  components: { Dialog, addDia },
  computed: {},
  created() {
    this.form.page = 1
    // 获取商品库数据
    this.getData()
    // 获取option列表
    this.getOptionList()
  },
  methods: {
    // 是都有选中option判断
    haveOption(optionList) {
      let show = true
      optionList.forEach((item) => {
        if (item.checked) {
          show = false
        }
      })
      return show
    },
    // 修改商品数量保存商品数量
    saveGoodsNum(item, goodsIndex) {
      this.$api
        .favoriteInventorySpecificationNum({
          id: item.id,
          num: item.num,
        })
        .then((res) => {

          if (res.code != 0) return this.$message.error(res.msg)

        })
      // 保存完商品数量再根据价格区间重新计算价格
      this.getPrice(item.specification_checked, goodsIndex)
    },
    // 计算单条商品附加服务总费用
    setoneGoodsOptionTotalPrice(scope, scopeer) {





      //  进行判断晒选，如果不是摄影的配套，那么当他的option 选择数量大于购买数量时，让他等于购买数量
      this.oneGoodsOptionTotalPrice = 0
      this.optionList.forEach((optionsItem) => {
        if (optionsItem.name != '摄影' && optionsItem.name != '配套') {
          if (optionsItem.num > scope.num) {
            optionsItem.num = scope.num

          }
        }
        if (optionsItem.checked)


          optionsItem.num = scope.num
        this.oneGoodsOptionTotalPrice += optionsItem.num * optionsItem.price
      })
      // 保留两位小数并添加千位符
      this.oneGoodsOptionTotalPrice = this.$fun.RMBNumSegmentation(
        this.$fun.ceil(this.oneGoodsOptionTotalPrice)
      )
      this.$forceUpdate()
    },

    async handleCurrentChange(val) {
      this.form.page = val
      this.getData(this.form)
    },
    //更新备注弹窗展示
    updatePopoverRemarkShowStatus(index) {
      this.tableData.forEach((tableItem, tableIndex) => {
        if (index === tableIndex) {
          tableItem.popoverRemarkShowStatus = true
          tableItem.remarks = tableItem.note
          this.$forceUpdate()
        } else {
          tableItem.popoverRemarkShowStatus = false
          this.$forceUpdate()
        }
      })
    },
    //   获取数据
    getData() {
      this.loading = true
      this.$api.favoriteInventoryList(this.form).then((res) => {
        this.loading = false
        // 设置option弹窗状态 备注弹窗状态
        res.data.data.forEach((item) => {
          item.popoverOptionShowStatus = false
          item.popoverRemarkShowStatus = false
          item.remarks = ''
        })
        this.tableData = res.data.data
        this.dataProcessing()


        this.total = res.data.total
        this.tableData.forEach((item) => {

          if (this.allactId.indexOf(item.id) != -1) {

            item.checked = true
          }
          this.$forceUpdate()
        })
        if (this.tableData.every((item) => item.checked == true) && this.tableData.length !== 0) {

          this.checked = true
        } else {
          this.checked = false
        }
      })
        .catch((err) => {
          console.log(err);

        })
    },
    // 数据处理
    dataProcessing() {


      this.tableData.forEach((item, index) => {
        item.checked = false
        // 函数处理商品规格
        item.specification = ((data) => {


          let skuProps = []
          Object.keys(data.specification).forEach((key) => {
            skuProps.push({
              key: key.substr(1, key.length - 2),
              value: data.specification[key],
              active: 0,
            })
          })
          return skuProps
        })(item)
        // 函数处理商品规格中文
        item.specificationChinese = ((data) => {
          let skuProps = []
          Object.keys(data.specificationChinese).forEach((key) => {
            skuProps.push({
              key: key.substr(1, key.length - 2),
              value: data.specificationChinese[key],
              active: 0,
            })
          })
          return skuProps
        })(item)
        // 函数处理商品规格选中
        item.specification_checked.forEach((ctem, cndex) => {
          item.specification[cndex].value.forEach(
            (specificationItem, specificationIndex) => {
              if (ctem.value == specificationItem.name) {
                item.specification[cndex].active = specificationIndex
              }
            }
          )
        })


        this.getPrice(item.specification_checked, index)
      })
    },
    // 商品规格选择 传入参数：选择的商品选项的下标，单个商品选项组，单个商品选项组的下标，商品所有选项， 单条商品的下标，
    getColor(
      active,
      detail,
      detailIndex,
      goodsSpecification_checked,
      goodsIndex
    ) {
      let oldActive = JSON.parse(JSON.stringify(active))
      detail.active = active
      // return console.log(detail);
      goodsSpecification_checked[detailIndex].value = detail.value[active].name
      // 将传递的商品选中规格匹配成中文
      let checked_Chinese = []
      let specificationChinese = this.tableData[goodsIndex].specificationChinese
      this.tableData[goodsIndex].specification.forEach(
        (checkedItem, checkedIndex) => {
          checked_Chinese.push({
            key: specificationChinese[checkedIndex].key,
            value:
              specificationChinese[checkedIndex].value[checkedItem.active].name,
          })
        }
      )
      this.$api
        .favoriteInventorySpecificationChecked({
          id: this.tableData[goodsIndex].id,
          specification_checked: JSON.stringify(checked_Chinese),
        })
        .then((res) => {
          if (res.code != 0) {
            active = oldActive
            goodsSpecification_checked[detailIndex] = detail.value[active].name
            return
          }

          this.getPrice(goodsSpecification_checked, goodsIndex)
          this.$forceUpdate()
        })
    },
    // 根据选择条件匹配价格
    getPrice(str, tabDaIndex) {
      // 将str的{key,value}格式转化为字符串㊖㊎格式
      str = (() => {
        let arr = []
        str.forEach((item) => {


          arr.push(item.value)
        })
        return arr.join('㊖㊎')
      })()
      let data = ''
      // 循环遍历对象寻找
      str = '' + str + ''
      if (typeof this.tableData[tabDaIndex].goods_inventory.forEach === 'function') {
        this.tableData[tabDaIndex].goods_inventory.forEach((key) => {
          if (key.keyC === str) {
            data = key;
            return false;
          }
        });
      } else {
        console.warn('goods_inventory does not have a forEach method');
      }
      let throttling = true


      if (data !== '') {


        data.valueC.forEach((ind) => {
          if (
            this.tableData[tabDaIndex].num >= ind.startQuantity && ind.startQuantity_last !== null
              ? this.tableData[tabDaIndex].num < ind.startQuantity_last
              : true
          ) {
            if (throttling) {
              this.tableData[tabDaIndex].price = ind.price
              this.tableData[tabDaIndex].sku_id = ind.skuId
              this.tableData[tabDaIndex].spec_id = ind.specId
              throttling = false
            }
          }
        })

      }
      this.$forceUpdate()
    },
    hide() {
      this.getData()
      this.$forceUpdate()
    },
    cloneGoodsRemarksContainer() {
      this.$refs.mergePopover.doClose()
    },
    // 保存商品备注
    goodsSaveRemarks(id, note, item) {
      this.$api
        .favoriteInventoryNote({
          id: id,
          note: note,
        })
        .then((res) => {
          item.popoverRemarkShowStatus = false
          item.note = note
          if (res.code != 0) return this.message.error(res.msg)
        })
    },

    // 单选
    oddChoice(item) {
      if (item.checked == true) {
        if (!this.allactId.some((item) => item == item.id)) {
        }
        this.allactId.push(item.id)
      } else if (item.checked == false) {
        if (this.allactId.some((ite) => ite == item.id)) {

          this.allactId = this.allactId.filter((i) => i !== item.id)
        }
      }


      this.isCheckedAll()
      this.$forceUpdate()

    },
    // 全选
    allChoice() {

      if (this.checked) {
        this.actIds = []
        this.tableData.forEach((item, index) => {
          this.actIds.push(item.id)
          item.checked = true
        })

        if (this.allactId == []) {
          this.allactId = [...this.actIds]
        } else {
          this.actIds.forEach((ite, index) => {
            if (this.allactId.indexOf(ite) == -1) {
              this.allactId.push(ite)
            } else {
              this.allactId = this.allactId.filter((i, index) => i !== ite)
            }
          })
        }
      } else {
        this.actIds.forEach((ind, index) => {
          if (this.allactId.indexOf(ind) == -1) {
            // this.allactId.push(ite)
          } else {
            this.allactId = this.allactId.filter((i, index) => i !== ind)
          }
        })
        this.actIds = []
        this.tableData.forEach((item, index) => {
          item.checked = false
        })
      }

      this.$forceUpdate()
    },
    // 是否全选中
    isCheckedAll() {

      this.actIds = []
      this.tableData.forEach((item) => {


        if (item.checked == true) {
          this.actIds.push(item.id)
        }
      })


      let flag = this.tableData.every((item) => item.checked);

      if (flag == true) {
        this.checked = true
      } else {
        this.checked = false
      }
      this.$forceUpdate()

    },
    // 删除商品
    delGoods() {
      if (this.actIds.length == 0) {
        return this.$message(this.$fanyi('请至少选择一个商品'))
      }
      this.$refs.dialog.open(
        this.$fanyi('是否删除选中的商品'),
        () => {
          this.$api
            .favoriteInventoryDelete({
              ids: this.actIds.join(','),
            })
            .then((res) => {

              if (res.code != 0) return
              for (let i = 0; i < this.tableData.length; i++) {
                if (this.actIds.indexOf(this.tableData[i].id) != -1) {
                  this.tableData.splice(i, 1)
                  i--
                }
              }
              if (this.tableData.length == 0) this.checked = false
            })
        },
        () => { }
      )
    },
    // 获取附加服务列表
    getOptionList() {
      this.$api.optionList().then((res) => {
        res.data.forEach((option) => {
          option.checked = false
        })
        this.optionList = res.data

      })
    },

    // 设置附加服务-重置
    optionReact(i) {
      for (var i in this.optionList) {
        this.optionList[i].checked = false
        this.optionList[i].num = 0
      }
      let checkOptionBox = document.getElementsByClassName('checkOptionBox')
      for (let i = 0; i < checkOptionBox.length; i++) {
        checkOptionBox[i].checked = false
      }
    },

    // 设置附加服务-保存选择的附加服务
    saveOrderOption(item) {
      item.option = JSON.parse(JSON.stringify(this.optionList))
      if (item.option.every((item) => item.num == 0)) {
        this.$message({
          showClose: true,
          message: this.$fanyi('附加服务数量不能为0'),
          type: 'warning',
        })
        return
      }
      let status = false
      item.option.forEach((optionItem) => {
        if (optionItem.checked === true) {
          status = true
        }
      })


      if (status === false) {
        return
      }
      this.$api
        .favoriteInventoryOption({
          id: item.id,
          option: JSON.stringify(item.option),
        })
        .then((res) => {
          item.popoverOptionShowStatus = false
          if (res.code != 0) return this.$message.error(this.$fanyi('操作失败'))
        })
      this.$forceUpdate()
    },
    // 设置附加服务-打开弹窗并初始化数据和选中已选附加服务
    setOrderOption(item, index) {
      this.tableData.forEach((tableItem, tableIndex) => {
        if (index === tableIndex) {
          tableItem.popoverOptionShowStatus = true
          this.optionList = JSON.parse(JSON.stringify(item))
          this.$forceUpdate()
        } else {
          tableItem.popoverOptionShowStatus = false
          this.$forceUpdate()
        }
      })
    },
    //提出订单
    jieSuan() {
      if (this.allactId.length == 0) {
        return this.$message(this.$fanyi('请至少选择一个商品'))
      }
      let id = this.allactId.join(',')
      this.$fun.toPage(
        '/orderDetails?id=' + id + '&type=temporary&iscomm=true'
      )
    },
    // 点击备注图片时将焦点移到备注输入框
    getFocuects(index) {

      this.$refs['reIn' + index].focus()
    },
  },
  mounted() {
    document.body.onclick = (e) => {

      if (
        e.target.className !== 'optionsBox' &&
        e.target.className !== 'ploceholdX' &&
        e.target.className !== 'optionsBox el-popover__reference'
      ) {
        // if (e.target.className == 'optionsBox') return

        const dialong = typeof e.target == Object


        if (!dialong) {
          this.tableData.forEach((tableItem, tableIndex) => {
            tableItem.popoverOptionShowStatus = false
            // location.reload()
            this.$forceUpdate()
          })
        }
      }
    }
  },
}
</script>
<style lang="scss">
.goodsRemarksPopoverClass {
  padding: 0 !important;

  .el-input-number {
    width: 68px !important;
    height: 32px !important;
    border-radius: 4px;
    border: 1px solid #222222;
  }

  .goodRemarksContainer {
    padding: 20px;
    box-sizing: border-box;
    min-height: 294px;
    height: 294px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

    .text {
      color: #000000;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 10px;
    }

    .el-textarea__inner {
      resize: none;
      height: 180px;
      border-radius: 0;
    }



    .goodsSaveRemarksContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;

      .goodsSaveRemarks {
        display: flex;
        align-items: center;

        div {
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
        }

        div:first-child {
          color: #000000;
          margin-right: 18px;
        }

        div:last-child {
          color: #ff730b;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import '../../../../css/mixin.scss';

/deep/.el-dialog__footer {
  .el-button--default {
    margin-left: 60px !important;
  }

  margin-bottom: 10px;
}


/deep/ .el-popper {
  padding: 20px !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #e2e2e2;



}

.shangpinstate {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #e3372f;
}



#commodity {
  background-color: white;
  padding: 0 30px 30px;
  width: 1180px;
  border: 1px solid #e1e1e1;
  border-radius: 6px;

  // 导航区域
  nav {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    >* {
      display: flex;
      align-items: center;
    }

    /deep/ .el-checkbox__input {
      height: 16px !important;
    }

    /deep/.el-checkbox__inner::after {


      left: 7px;
      width: 4px;
      height: 10px;
    }

    /deep/ .el-checkbox__inner {
      width: 20px;
      height: 20px;
      border-radius: 4px;
    }

    .navLeft {
      .checkBoxZu {
        display: flex;
        align-items: center;

        .che {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-right: 20px;

          input {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }

        button {
          height: 19px;
          font-size: 14px;
          color: #1e2997;
          line-height: 19px;
          background-color: transparent;
          margin-right: 40px;
        }
      }

      .inputBox {
        width: 400px;
        display: flex;
        align-items: center;

        .imgBox {
          width: 40px;
          height: 40px;
          border: $homePageSubjectColor solid 1px;
          border-right: none;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px 0 0 20px;

          img {
            width: 17px;
            height: 24px;
          }
        }

        /deep/ .el-input {
          border-radius: 0;

          .el-input__inner {
            border-left: none;
            border-right: none;
            border-color: $homePageSubjectColor;
            border-radius: 0;
          }
        }

        /deep/ .el-button {
          width: 80px;
          height: 40px;
          background: #1e2997;
          border-radius: 4px;
          margin-left: -17px;
          position: relative;
          z-index: 1;
        }
      }
    }

    .navleft {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        margin-left: 10px;
      }

      .delete {
        margin-left: 30px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #698cff;
        padding: 0 25px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .navRight {

      .add,
      .jieSuan {
        font-size: 14px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        margin-right: 20px;
        font-size: 16px;
        line-height: 40px;
        cursor: pointer;
      }

      .add {
        width: 140px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #ff730b;
        padding: 0 25px;
        color: #ff730b;
      }

      .jieSuan {
        width: 140px;
        height: 40px;
        background: #ff730b;
        text-align: center;
        border-radius: 4px;
        color: #fff;
      }

      .exportBox {
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          width: 15px;
          height: 15px;
          margin-right: 8px;
        }

        span {
          line-height: 19px;
          font-size: 14px;
          margin-right: 40px;
        }
      }

      .el-button {
        height: 40px;
        border-radius: 4px;
      }

      .ProponerUnPedido {
        background-color: #ffa724;
        border: none;
        color: white;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  /deep/ .el-table {
    .chiose {
      margin: 0 auto;
    }

    input {
      vertical-align: middle;
    }

    input[type="checkbox"] {
      /* 隐藏默认复选框 */
      appearance: none;
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #CDCDCD;
      border-radius: 4px;
      position: relative;
      cursor: pointer;
    }

    input[type="checkbox"]:checked {
      background: #FF730B;
      /* 修改为你想要的颜色 */
      border-color: #FF730B;

    }

    input[type="checkbox"]:checked::after {
      width: 10px;
      margin-left: 1px;

      height: 4px;

    }

    .goodsUrl {
      text-align: left;
      height: 117px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;

      a {
        &:hover {
          color: $homePageSubjectColor !important;
          text-decoration: underline !important;
        }
      }
    }

    .ipt {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .optionsBoxchoose {
      width: 160px;
      height: 34px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid rgba(96, 139, 255, 0.3);

      .first-span {
        margin-left: 10px;
        font-size: 12px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #222222;
        line-height: 34px;
      }

      .last-span {
        margin-right: 10px;
        font-size: 12px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 700;
        color: #222222;
        line-height: 34px;
      }
    }

    .optionsBox {
      width: 200px;
      height: 117px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      padding: 8px 10px;
      overflow-y: auto;
      cursor: pointer;

      .options {
        text-align: left;
        font-size: 12px;
        color: #000000;
        line-height: 16px;
      }

      .ploceholdX {
        color: #c0c4cc;
        text-align: left;
      }
    }

    .noteBox {
      img {
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }

    .goodsDetail {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .detail {
        flex: 1;

        .detailCon {
          width: 100%;
          overflow-y: auto;
          max-height: 100px;

          p {
            width: 100px;

            text-align: left;
          }
        }
      }

      img {
        width: 16px;
        height: 16px;
        margin-left: 20px;
      }

      .detailChoice {
        position: absolute;
        top: 0px;

        width: 420px;
        padding: 20px;
        background: red;
        border: solid;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .allpag {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;

    .el-pagination {
      display: flex;
      align-items: center;
    }

    /deep/ .el-pagination__jump,
    /deep/ .el-pagination__total {
      display: none !important;
    }

    /deep/ .el-input-number--small .el-input__inner {
      padding-left: 30px;
      padding-right: 30px;
    }

    >a {
      display: inline-block;
      border: 1px solid #dcdfe6;
      height: 28px;
      // background-color: white;
      text-align: center;
      padding: 0 10px;
      font-size: 13px;
      line-height: 28px;
      border-radius: 0;
    }

    /deep/.btn-prev {
      width: 40px;
      height: 40px;
      background: #f5f5f5;
      border-radius: 20px;
      padding: 0;
      margin-right: 30px;

      .el-icon {
        font-size: 15px;
      }
    }

    /deep/.btn-next {
      width: 40px;
      height: 40px;
      background: #f5f5f5;
      border-radius: 20px;
      padding: 0;
      margin-left: 30px;

      .el-icon {
        font-size: 15px;
      }
    }

    /* 分页被选中的页码样式 */
    /deep/ .el-pager li {
      margin: 0 10px;
      // border-radius: 0;
      min-width: 20px !important;
      font-size: 14px;
      height: 30px;
      color: #999999;
      // box-shadow: 0 0 6px 0 rgba(61, 61, 61, 0.1);

      &:hover {
        color: #ff730b;
      }

      &.active {
        color: #ff730b;
        border-bottom: 2px solid #ff730b;
        width: 20px !important;
      }
    }
  }

}



// 规格修改
.chioceDetail {
  max-height: 500px;
  overflow-y: auto;

  >.dia {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    .chioceTitle {
      display: block;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #000000;
      line-height: 18px;
    }

    .chioceBody {
      display: flex;
      flex-wrap: wrap;

      .chioceBodyOpt {
        margin-right: 10px;
        border-radius: 2px;
        font-size: 12px;
        margin-bottom: 10px;
        line-height: 16px;

        border: 1px solid #cccccc;
        padding: 7px 8px;
        cursor: pointer;

        &.active {
          color: #ffa724;
          border: 1px solid #ffa724;
        }
      }
    }
  }
}

// 附加服务弹窗
/deep/ .cellClassName {
  font-size: 12px;
  color: #000000;
  line-height: 16px;
  border: none;
  padding: 5px;
}

/deep/ .cellHeadClassName {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  line-height: 16px;
}

.additionalservicesfoot {
  margin-top: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  >p {
    font-size: 14px;
    margin-right: 20px;
    font-weight: bold;
    color: #000000;
    border: 1px solid transparent;

    line-height: 16px;

    font {
      color: #ffa724;
    }

    &:first-child {
      margin-bottom: 12px;

      margin-top: 13px;
    }

    button {
      font-size: 14px;

      color: #000000;
      line-height: 20px;
      background-color: transparent;
      border: solid 1px #47bd9b;
      border-radius: 5px;
      padding: 5px 12px;
      outline: none;
      color: #47bd9b;

      &.react {
        margin-right: 20px;
      }

      &.save {
        background-color: #47bd9b;
        color: $homePageSubjectColor;
        color: white;
      }
    }
  }
}
</style>
