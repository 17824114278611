<template>
  <!-- 订单运输中，配送单 -->
  <div id="userOrder">
    <Dialog ref="dialog" :config="config" />

    <div class="tabsContainer">
      <el-tabs v-model="form.status" @tab-click="handleClick" ref="tabs">
        <el-tab-pane :label="allTabText" name="全部"></el-tab-pane>
        <el-tab-pane :label="Tobequoted" name="待报价"></el-tab-pane>
        <el-tab-pane :label="Tobepaid" name="待支付"></el-tab-pane>
        <el-tab-pane :label="orderForDeliveryTabText" name="待发货配送单"></el-tab-pane>
        <el-tab-pane :label="deliveryInTransitTabText" name="运输中配送单"></el-tab-pane>
        <el-tab-pane :label="spanishDeliveryOrderTabText" name="西班牙派送配送单"></el-tab-pane>
        <el-tab-pane :label="internationalLogisticsTabText" name="已签收配送单"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="list">
      <div class="searchBox">
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input type="text" v-model="form.searchText" @keydown.enter="getData()"
            :placeholder="$fanyi('请输入订单号或配送单号搜索')" />
        </div>
        <el-date-picker v-model="value" type="datetimerange" :range-separator="$fanyi('')"
          :start-placeholder="$fanyi('开始日期')" :end-placeholder="$fanyi('结束日期')" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
        <button class="searchBtn" @click="
          form.page = 1
        getData(form)
          ">
          {{ $fanyi('搜索') }}
        </button>

        <!-- <button v-else @click="Completed" class="return">
          {{ $fanyi('返回') }}
        </button> -->
      </div>
      <!-- 表格 -->
      <div class="dataBody">
        <div class="myTable">
          <ol class="tableHead">
            <li class="flex05 color">NO</li>
            <li class="flex2 color" :style="form.status == '已签收配送单' ? 'flex:2.3' : 'flex:2.5'">
              {{ $fanyi('订单号') }}
            </li>
            <li class="flex3 color" :style="form.status == '已签收配送单' ? 'flex:2.3' : 'flex:2.5'">
              {{ $fanyi('配送单号') }}
            </li>

            <!-- 提出时间爱 -->
            <li class="time color">
              {{ $fanyi('提出时间') }}
            </li>
            <!-- 签收时间 -->
            <li v-if="form.status == '全部' || form.status == '已签收配送单'" class="time color">
              {{ $fanyi('签收时间') }}
            </li>
            <li class="color">&nbsp;{{ $fanyi('国际运费') }}($)</li>
            <li class="color">&nbsp; {{ $fanyi('重量') }}(kg)</li>
            <li class="flex4 color">{{ $fanyi('状态') }}</li>
            <li class="flex15 color" v-show="form.status !== '已签收配送单'">
              {{ $fanyi('操作') }}
            </li>
          </ol>
          <!-- 表格区域 -->
          <div class="tableBody">
            <ul v-for="(item, index) in tableData" :key="index">
              <li class="flex05">{{ index + 1 }}</li>
              <!-- 订单号 -->
              <li class="flex2 dingdan">
                <p v-for="item1 in item.order_set" :key="item1" @click="
                  $fun.toPage(
                    '/orderDetails?type=purchase&order_sn=' + item1, true
                  )
                  ">
                  {{ item1 }}
                </p>
              </li>
              <!-- 配送单号 -->
              <li class="flex3 peisongdan" @click="
                $fun.toPage(
                  '/deliveryDetails?order_sn=' + item.porder_sn + '&status=' + item.status, true
                )
                ">
                {{ item.porder_sn }}
              </li>

              <!-- 提出时间 -->
              <li class="time">
                {{ item.created_at ? item.created_at : '---' }}
              </li>
              <!-- 签收时间 -->
              <li v-if="form.status == '全部' || form.status == '已签收配送单'" class="time">
                {{ item.client_receive ? item.client_receive : '---' }}
              </li>
              <!-- 国际运费 -->
              <li>
                {{ item.logistics_price ? item.logistics_price : '---' }}
              </li>
              <!-- 重量 -->
              <li>{{ item.weight ? item.weight : '---' }}</li>
              <li class="flex4" :class="[
                item.statusName == '待发货' ? 'blue' : '',
                item.statusName == '待报价' ? 'green' : '',
                item.statusName == '等待付款' ? 'yellow' : '',
                item.statusName == '美国国内配送中' ? 'blue' : '',
                item.statusName == '已发货' ? 'shipped' : '',
              ]">
                {{ $fanyi(item.statusName) }}
              </li>
              <li v-if="form.status !== '已签收配送单'" class="flex15">
                <button class="Receipt" v-if="item.statusName == '美国国内配送中'" type="primary"
                  @click="qianShou(item.porder_sn)">
                  {{ $fanyi('确认签收') }}
                </button>
                <button class="pay" v-else-if="item.statusName == '等待付款'" type="primary" @click="
                  $fun.toPage(
                    '/deliveryDetails?type=purchase&order_sn=' +
                    item.porder_sn
                  )
                  ">
                  {{ $fanyi('去支付') }}
                </button>
                <span v-else>- -</span>
              </li>
            </ul>

          </div>
        </div>
      </div>
      <div class="allpag">
        <el-pagination @current-change="handleCurrentChange" :current-page="form.page" :page-size="10"
          layout="prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from '../../../../components/public/Dialog.vue'

import { Loading } from 'element-ui'
export default {
  components: {
    Dialog,
  },
  data() {
    return {

      allTabText: this.$fanyi('全部') + ` (0)`,
      loading: true,
      value: '',
      loadingInstance: '',
      orderForDeliveryTabText: this.$fanyi('待发货配送单') + ` (0)`,
      deliveryInTransitTabText: this.$fanyi('国际运输中') + ` (0)`,
      Tobepaid: this.$fanyi('待支付') + ` (0)`,
      Tobequoted: this.$fanyi('待报价') + ` (0)`,
      deliveryFormToRAKUMARTTransportCentreTabText:
        this.$fanyi('到达RAKUMART物流中心') + ` (0)`,
      spanishDeliveryOrderTabText: this.$fanyi('美国派送中') + ` (0)`,
      internationalLogisticsTabText: this.$fanyi('已签收') + ` (0)`,
      form: {
        status: '全部',
        page: 1,
        pageSize: 10,
        searchText: '',
      },
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('提示'),
        btnTxt: [this.$fanyi('确定'), this.$fanyi('取消')],
      },
      tableData: [],
      total: 0,
    }
  },

  computed: {

  },
  created() {
    if (this.$route.query.status) {
      switch (this.$route.query.status) {
        case 'orderForDelivery':
          this.form.status = '待发货配送单'
          break
        case 'deliveryInTransit':
          this.form.status = '运输中配送单'
          break
        case 'deliveryFormToRAKUMARTTransportCentre':
          this.form.status = '到达RAKUMART运输中心配送单'
          break
        case 'Usdeliveryunderway':
          this.form.status = '西班牙派送配送单'
          break
        case 'Signedandreceived':
          this.form.status = '已签收配送单'
          this.flag = false
          break
        case 'Tobepaid':
          this.form.status = '待支付'
          break
        case 'internationalFreightToBeQuotedDeliverySheet':
          this.form.status = '待报价'
          break
        default:
          this.form.status = this.$route.query.status
          break
      }
    }
    this.loadingInstance = Loading.service()

    if (this.$route.query.type) {
      this.form.status = this.$fun.fromCode(this.$route.query.type)
    }
    this.$forceUpdate()
    this.getData()
    this.getTitleNumber()
  },
  methods: {

    getTitleNumber() {
      this.$api.logisticsOrderCountNum().then((res) => {
        if (res.code != 0) return false
        if (res.data.length == 0) return false
        res.data.forEach((item) => {
          switch (item.status) {
            case '待发货配送单':
              this.orderForDeliveryTabText =
                this.$fanyi('待发货配送单') + ` (${item.num})`
              break
            case '运输中配送单':
              this.deliveryInTransitTabText =
                this.$fanyi('运输中配送单') + ` (${item.num})`
              break
            case '到达RAKUMART运输中心配送单':
              this.deliveryFormToRAKUMARTTransportCentreTabText =
                this.$fanyi('到达RAKUMART物流中心') + ` (${item.num})`
              break
            case '西班牙派送配送单':
              this.spanishDeliveryOrderTabText =
                this.$fanyi('美国派送中') + ` (${item.num})`
              break
            case '待报价':
              this.Tobequoted = this.$fanyi('待报价') + ` (${item.num})`
              break
            case '待支付':
              this.Tobepaid = this.$fanyi('待支付') + ` (${item.num})`
              break
            case '已签收配送单':
              this.internationalLogisticsTabText =
                this.$fanyi('已签收') + ` (${item.num})`
              break
            case '全部状态':
              this.allTabText = this.$fanyi('全部') + ` (${item.num})`
              break
          }
        })
        this.tableTypeNum = res.data
      })
    },
    handleClick(tab) {

      this.form.page = 1
      switch (tab.name) {
        case '待发货配送单':
          this.$store.commit('updateRouterQuery', 'orderForDelivery')
          break
        case '运输中配送单':
          this.$store.commit('updateRouterQuery', 'deliveryInTransit')
          break
        case '到达RAKUMART运输中心配送单':
          this.$store.commit(
            'updateRouterQuery',
            'deliveryFormToRAKUMARTTransportCentre'
          )
          break
        case '西班牙派送配送单':
          this.$store.commit('updateRouterQuery', 'Usdeliveryunderway')
          break
        case '已签收配送单':
          this.$store.commit('updateRouterQuery', 'Signedandreceived')
          break
        case '全部':
          this.$store.commit('updateRouterQuery', '')
          break
      }
      this.form.status = tab.name
      this.getData()
    },
    gettabsoffset(tab) {
      this.$nextTick(() => {
        const currentEL = this.$refs.tabs.$el.querySelector('.el-tabs__item.is-active') // 拿到当前高亮的

        const barEl = this.$refs.tabs.$el.querySelector('.el-tabs__active-bar')
        barEl.style.transform = `translateX(${currentEL.offsetLeft + 20}px)`
      })
    },
    // 获取数据
    getData() {
      let datas = {
        status: this.form.status,
        pageSize: this.form.pageSize,
        page: this.form.page,
        searchText: this.form.searchText,
        startTime: this.value[0],
        endTime: this.value[1],
      }

      this.$api.logisticsOrderList(datas).then((res) => {
        if (res.code != 0) return false
        res.data.data.forEach((item) => {
          item.porder_detail.forEach((items) => {
            items.order_detail_list = []
            items.order_detail.detail = JSON.parse(items.order_detail.detail)
            items.order_detail_list.push(items.order_detail)
          })
        })
        this.tableData = res.data.data

        this.value = ''
        this.dataProcessing(this.tableData)
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.loadingInstance.close()
        })
        this.total = res.data.total
      })
    },
    //派送中配送单签收
    qianShou(sn) {
      this.$refs.dialog.open(
        this.$fanyi('是否签收选中的商品？'),
        () => {
          this.$api
            .internationalLogisticsClientReceive({ porder_sn: sn })
            .then((res) => {

              if (res.code != 0) return
              this.$message.success(this.$fanyi('签收成功'))
              // 签收完成重新获取数据
              this.getData()
              this.getTitleNumber()
            })
        },
        () => {
          this.$message(this.$fanyi('已取消签收'))
        }
      )
    },
    // 数据处理
    dataProcessing(data) {
      data.forEach((dataItem) => {
        // 添加是否显示子数据的参数
        dataItem.showChildData = false
        // 订单商品数量
        dataItem.orderNum = 0
        //订单内商品数量总计
        dataItem.totalNum = 0
        // 订单小计
        dataItem.totalPrice = 0
        // 国内运费
        dataItem.confirm_freight = 0
        dataItem.porder_detail.forEach((item) => {
          item.order_detail_list.forEach((goodsItem) => {
            dataItem.orderNum += goodsItem.num
            // 商品小计计算
            goodsItem.totalPrice = Number(
              this.$fun.ceil(Number(goodsItem.price) * goodsItem.num)
            )
            dataItem.totalPrice += Number(goodsItem.totalPrice)
            dataItem.totalNum += goodsItem.num
            dataItem.confirm_freight += Number(goodsItem.confirm_freight)
          })
        })
        dataItem.totalPrice = this.$fun.ceil(dataItem.totalPrice)
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageSize = 1
      this.getData(this.form)
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getData(this.form)
    },
  },
}
</script>
<style lang="scss" scoped>
#userOrder {
  @import '../../../../css/mixin';
  width: 1180px;
  border-radius: 6px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #e2e2e2;

  .tabsContainer {
    border-radius: 0 !important;
    border-bottom: 1px solid #e2e2e2 !important;
    padding: 0px 20px;
    height: 55px;

    /deep/ .el-tabs__nav-wrap::after {
      display: none;
    }

    /deep/.el-tabs__nav-scroll {
      height: 55px;
      padding-left: 10px;

      .el-tabs__nav {
        height: 55px;
        padding-top: 8px;

      }
    }
  }

  button {
    cursor: pointer;
  }

  .dingdan {
    padding-top: 20px;
    color: #ff730b;
    padding-bottom: 20px;

    &:hover {
      text-decoration: underline;
      color: #ff730b;
    }
  }

  // 状态颜色
  .green {
    color: #00c18b;
  }

  .yellow {
    color: #ff730b;
  }

  .blue {
    color: #698cff;
  }

  .orange {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #e3372f;
  }

  .pay {
    width: 100px;
    height: 40px;
    background: #ff730b;
    border-radius: 4px;
    background: #ff730b;

    color: #ffffff;

    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }

  .Receipt {
    width: 100px;
    height: 40px;
    background: #698cff;
    border-radius: 4px;

    border-radius: 4px;
    color: #fff;

    span {
      margin: 20px;
    }
  }

  .shipped {
    color: #222222;
  }

  .peisongdan {
    color: #ff730b;

    &:hover {
      text-decoration: underline;
      color: #ff730b;
    }
  }

  .color {
    text-align: center;
    width: 42px;
    height: 20px;
    font-size: 14px;
    background-color: #f0f0f0 !important;

    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 600;
    color: #222222;
    line-height: 20px;
  }

  .return {
    width: 68px;
    height: 32px;
    width: 140px;
    height: 40px;
    border-radius: 4px !important;
    border: 1px solid #ff730b;
    margin-left: 250px;
    color: #ff730b;
  }

  .completed {
    width: 140px;
    height: 40px;
    border-radius: 4px !important;
    border: 1px solid #ff730b;
    background: #ffffff;

    margin-left: 200px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff730b;
  }



  .list {
    border-radius: 0 !important;
    padding: 30px;
    background: #ffffff;

    border-radius: 6px !important;
    padding: 20px 20px 37px 20px;

    .searchBox {
      display: flex;
      margin-bottom: 20px;

      .myInput {
        width: 320px;
        height: 40px;
        background: #ffffff;
        border-radius: 4px;

        position: relative;
        display: flex;
        align-items: center;
        // overflow: hidden;
        margin-right: 20px;

        >i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }

        >input {
          border: 1px solid #e2e2e2;
          width: 100%;
          height: 40px;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;
          border-radius: 4px;

          &:focus {
            border-color: #1e2997;
          }
        }
      }

      /deep/.el-range-separator {
        width: 3px;
        height: 20px;

        background: url('../../../../assets/newImg/order/date.png') no-repeat center;
      }

      /deep/.el-date-editor {
        border-radius: 4px !important;

        .el-input__icon {
          display: none;
        }

        width: 344px;
        height: 40px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e2e2e2;
        margin-right: 20px;
        line-height: 40px !important;

        .el-range-input {
          font-size: 14px;
        }

        .el-range-separator {
          width: 50px;
          line-height: 23px;
        }

        .el-range__icon {
          line-height: 25px;
        }
      }

      /deep/ .el-input__inner {
        border-radius: 0;
      }

      .searchBtn {
        width: 80px;
        height: 40px;
        background: #ff730b;
        border-radius: 4px;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
      }
    }

    .dataBody {
      margin-bottom: 20px;
      background-color: #fff;
      border: 1px solid #e2e2e2;

      .myTable {
        // 设置表格所有样式
        font-size: 14px;
        color: #2f2f2f;
        line-height: 19px;

        * {
          transition: 0.3s;
        }

        li {
          flex: 1.1;
          background-color: #fff;
        }

        .flex05 {
          flex: 0.5;
          cursor: pointer;
        }

        .flex2 {
          flex: 2.5;
          display: block;
          text-align: center;
          align-items: center;
          cursor: pointer;
        }

        .flex3 {
          flex: 2.5;

          cursor: pointer;
        }

        .time {
          text-align: center;
          flex: 1.3;
        }

        .flex4 {
          flex: 1.3;
          padding: 0 10px;
          text-align: center;
        }

        .flex15 {
          flex: 1.5;
        }

        ul {
          display: flex;
          min-height: 59px;
          background: #fafafa;
          border-top: 1px solid #f1f1f1;

          li {
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #f1f1f1;

            &:nth-last-child(1) {
              border: 0;
            }

            img {
              cursor: pointer;
            }
          }
        }

        // 设置表头样式
        .tableHead {
          height: 56px !important;
          background: #f0f0f0;
          display: flex;
          align-items: center;
          // line-height: 56px !important;
          text-align: center;

          li {
            display: flex;
            align-items: center;
            // // line-height: 56px !important;
            // text-align: center;
            justify-content: center;
            line-height: 18px;
            font-weight: normal;
            font-size: 14px;


          }
        }

        // 设置表格内容样式
        .tableBody {
          display: block;


        }

        // 单个单元格样式
        // NO

        // 照片
        .picture {
          min-width: 100px !important;
          max-width: 100px !important;
          width: 100px !important;
        }

        // 属性
        .detail {
          max-width: 200px !important;
          min-width: 200px !important;
          width: 200px !important;

          &.body {
            overflow-y: auto;
          }

          // height: 120px;
          .detailBox {
            margin: 0 auto;
            width: 170px;
            max-height: 110px;
            min-height: 20px;
            display: flex;

            flex-direction: column;
            align-items: flex-start;

            >p {
              text-align: left;
            }
          }
        }

        .status {
          width: 250px !important;

          .gooodsQurestStatus {
            color: #ffa018;
          }

          .goGoods {
            cursor: pointer;
          }
        }

        .num,
        .price,
        .total,
        .freight,
        .remark {
          width: 100px;
          min-width: 100px !important;
          max-width: 100px !important;
        }

        .remark img {
          cursor: pointer;
        }
      }
    }

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      .el-pagination {
        display: flex;
        align-items: center;
      }

      >a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        // background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 0;
      }

      /deep/.btn-prev {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-right: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /deep/.btn-next {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-left: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /* 分页被选中的页码样式 */
      /deep/ .el-pager li {
        margin: 0 10px;
        // border-radius: 0;
        min-width: 20px !important;
        font-size: 14px;
        height: 30px;
        color: #999999;
        // box-shadow: 0 0 6px 0 rgba(61, 61, 61, 0.1);

        &:hover {
          color: #ff730b;
        }

        &.active {
          color: #ff730b;
          border-bottom: 2px solid #ff730b;
          width: 20px !important;
        }
      }
    }

  }

}
</style>
