<template>
  <!-- 订单详情 -->
  <div class="newOrderDetails" v-if="!placingSuccessfully">
    <HomePageTop />
    <!-- 订单内容区域 -->
    <div class="OrderDetailscontent">
      <!-- 列表区域 -->
      <!-- 临时保存 修改订单，报价中展示 -->
      <div class="ordermain" v-if="
        ['temporary', 'editing', 'offer', 'cancel'].indexOf(orderStatus) !==
        -1
      ">
        <div class="content">
          <div class="headerOrder">
            <div class="order_sn">
              {{ $fanyi('订单号') }}：{{ datas.order_sn }}
            </div>
            <div class="right_time">
              <span class="time" v-if="datas.created_at">
                {{ $fanyi('时间') }} ：{{ datas.created_at }}
              </span>
              <button v-if="isedit && orderStatus != 'temporary'" class="editOrder" @click="editorder">
                {{ $fanyi('修改订单') }}
              </button>
            </div>
          </div>
          <div class="tablebox" v-loading="loading" id="myElementtablebox">
            <div class="tableheader">
              <!-- 全选 -->
              <div class="headCheckBox">
                <input class="CheckBox" type="checkbox" name="" id="" v-model="checked" @change="allChoice" />
                {{ $fanyi('全选') }}
              </div>
              <!-- 产品 -->
              <div class="goods">{{ $fanyi('产品') }}</div>

              <!-- 属性 -->
              <div class="detail">{{ $fanyi('属性') }}</div>
              <!-- 单价 -->
              <div class="price">{{ $fanyi('单价') }}</div>
              <!-- 购买数量 -->
              <div class="num">{{ $fanyi('购买数量') }}</div>
              <!-- 总价 -->
              <div class="totalprice">{{ $fanyi('总价') }}</div>

              <!-- 附加服务费费用 -->
              <div class="options">{{ $fanyi('附加服务费用') }}</div>

              <!-- 备注 -->
              <div class="remark">{{ $fanyi('备注') }}</div>
            </div>
            <div class="tablebody" id="myElementGoodslist">
              <!-- 店铺 -->
              <div class="shopsitem" v-for="(aitem, aindex) in newList" :key="aitem.shop_id">
                <div class="shopheader">
                  <h1>
                    <div class="checkedBox">
                      <input class="shopchecked" @change="shopAll(aindex)" type="checkbox" v-model="aitem.checked" />
                    </div>

                    {{ $fanyi('店铺') }}：{{ aitem.shop_name }}
                    <span class="img1688box"><img v-if="aitem.list[0].shop_type == '1688'"
                        src="../../../../../assets/newImg/order/al.svg" alt="" />
                    </span>
                  </h1>
                </div>
                <div class="shopbody">
                  <div class="goodsitem" v-for="item in aitem.list" :key="item.id">
                    <!-- 单选 -->
                    <div class="goodsheadCheckBox">
                      <!--单选-->
                      <div class="goodscheckedBox">
                        <input class="goodschecked" type="checkbox" name="" id="" @change="radio(aitem)"
                          v-model="item.checked" />
                      </div>
                      <!-- 商品番号 -->
                      <span class="rowNumber">{{ item.sorting }}</span>
                      <el-popover placement="right" trigger="hover">
                        <img :src="item.pic" alt="" style="width: 288px; height: 298px" />

                        <img class="goodimg" @click="
                          $fun.toCommodityDetails({
                            goods_id: item.goods_id,
                            shop_type: item.shop_type,
                          })
                          " :src="item.pic" alt="" slot="reference" />
                      </el-popover>
                    </div>
                    <!-- 产品 -->
                    <div class="goodsproduct">
                      <el-popover placement="bottom" width="400" trigger="hover">
                        {{ item.goods_title }}
                        <div class="showGoodsTitle" :title="item.goods_title" slot="reference" @click="
                          $fun.toCommodityDetails({
                            goods_id: item.goods_id,
                            shop_type: item.from_platform,
                          })
                          ">
                          <div class="title">
                            {{ item.goods_title }}
                          </div>
                        </div>
                      </el-popover>
                    </div>
                    <!-- 属性 -->
                    <div class="goodsdetail">
                      <el-popover placement="top" trigger="hover">
                        <div class="showdetailWindowBody">
                          <div class="detailOpt" v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex">
                            <h1>{{ $fun.trunUpperCase(bitem.key) }} :</h1>
                            <div class="optClass">
                              <span>{{ bitem.value }}</span>
                            </div>
                          </div>
                        </div>
                        <!-- 展示 -->
                        <div slot="reference" class="showDetail">
                          <div class="showDetailCon" style="width: 150px;">
                            <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex" class="goodsDetailOpt">
                              {{ $fun.trunUpperCase(bitem.key) }}:{{
                                bitem.value
                              }}
                            </p>
                          </div>
                        </div>
                      </el-popover>
                      <!-- 可以修改，临时保存和修改订单 -->
                      <el-popover placement="bottom" trigger="click" width="340">
                        <div class="detailWindowBody">
                          <div class="detailOpt" v-for="(goodsGuiGeItem,
                            goodsGuiGeIndex) in item.pageSpecification" :key="goodsGuiGeIndex">
                            <h1>{{ goodsGuiGeItem.key }}</h1>
                            <div class="optClass">
                              <span v-for="(actGuige,
                                actIndex) in goodsGuiGeItem.value" :key="actIndex" @click="
                                  goodsDetailChoice(
                                    item.detail[goodsGuiGeIndex],
                                    actGuige,
                                    item,
                                    goodsGuiGeItem,
                                    actIndex,
                                    goodsGuiGeIndex
                                  )
                                  " :class="actIndex == goodsGuiGeItem.active
                                    ? 'active'
                                    : ''
                                    ">{{ actGuige.name }}</span>
                            </div>
                          </div>
                        </div>
                        <!-- 可选择 -->
                        <div slot="reference" class="editDetail" v-if="
                          ['temporary', 'editing'].indexOf(orderStatus) != -1
                        ">
                          <img src="../../../../../assets/baianji.svg" alt="" />
                        </div>
                      </el-popover>
                    </div>
                    <!-- 单价 -->
                    <div class="goodsprice">
                      <span>$
                        {{
                          $fun.EURNumSegmentation(
                            $fun.ceil(
                              (item.confirm_price && item.confirm_price > 0
                                ? item.confirm_price
                                : item.price) * datas.exchange_rate
                            )
                          )
                        }}</span>
                      <span>(￥{{
                        $fun.RMBNumSegmentation(
                          $fun.ceil(
                            item.confirm_price && item.confirm_price > 0
                              ? item.confirm_price
                              : item.price
                          )
                        )
                      }})</span>
                    </div>
                    <!-- 购买数量 -->
                    <div class="goodsnum">
                      <!-- 临时保存，提出订单。修改订单展示 -->
                      <el-input-number v-if="
                        ['temporary', 'editing'].indexOf(orderStatus) != -1
                      " v-model="item.num" step-strictly @change="getTotalPrice(item)" :min="1"
                        size="mini"></el-input-number>
                      <!-- 报价中数量 -->
                      <span v-else>{{ item.num }}</span>
                    </div>
                    <!-- 总价 -->
                    <div class="goodstotalprice">
                      <span>$
                        {{
                          $fun.EURNumSegmentation(
                            $fun.ceil(item.totalPrice * datas.exchange_rate)
                          )
                        }}
                      </span>
                      <span>
                        (￥{{ $fun.RMBNumSegmentation(item.totalPrice) }})</span>
                    </div>
                    <!-- 附加服务费费用 -->
                    <div class="goodsoptions">
                      <!-- 数标点击显示option列表 -->
                      <el-popover :ref="'popoverref' + item.id" :popper-class="item.option.length == 0 &&
                        ['temporary', 'editing'].indexOf(orderStatus) == -1
                        ? 'nooptionList'
                        : ''
                        " :width="item.option.length > 0 ||
                          ['temporary', 'editing'].indexOf(orderStatus) != -1
                          ? 860
                          : ''
                          " placement="top" trigger="click">
                        <!-- 临时保存，修改订单展示 -->
                        <div class="additionalServices" v-if="
                          ['temporary', 'editing'].indexOf(orderStatus) != -1
                        ">
                          <div class="obody">
                            <!-- 头部 -->
                            <div class="optionhead">
                              <!-- 附加服务选项 -->
                              <div class="optionname">
                                {{ $fanyi('附加服务选项') }}
                              </div>

                              <!--  数量-->
                              <div class="optionnum">{{ $fanyi('数量') }}</div>

                              <!-- 单价 -->
                              <div class="optionprice">
                                {{ $fanyi('单价') }}
                              </div>
                              <!-- 预计费用 -->
                              <div class="optiontotalprice">
                                {{ $fanyi('预计费用') }}
                              </div>
                            </div>
                            <div class="optionListitem" v-for="(optionListitem, index) in item.option_old"
                              :key="'optionListitem' + index">
                              <!-- 选择框 -->
                              <div class="optionname">
                                <input type="checkbox" class="checkOptionBox" v-model="optionListitem.checked" @change="
                                  setOptionsNum(optionListitem, item.num)
                                setOneGoodsOptionTotalPrice(item)
                                  " />
                                <span class="tag">{{ optionListitem.tag }}
                                  {{ optionListitem.name_translate }}</span>
                              </div>
                              <!-- 输入框 -->
                              <div class="optionnum">
                                <el-input-number v-model="optionListitem.num" :max="['摄影', '配套'].indexOf(
                                  optionListitem.name
                                ) !== -1
                                  ? Number.MAX_SAFE_INTEGER
                                  : item.num
                                  " step-strictly @change="setOneGoodsOptionTotalPrice(item)" :min="0"
                                  size="mini"></el-input-number>
                              </div>
                              <!-- 单价 -->
                              <div class="optionprice">
                                <span>${{
                                  $fun.EURNumSegmentation(
                                    $fun.ceil(
                                      optionListitem.price *
                                      datas.exchange_rate
                                    )
                                  )
                                }}</span>
                                <span>(¥{{
                                  $fun.RMBNumSegmentation(
                                    optionListitem.price
                                  )
                                }})</span>
                              </div>
                              <!-- 预估总费用 -->
                              <div class="optiontotalprice">
                                <span>${{
                                  $fun.EURNumSegmentation(
                                    $fun.ceil(
                                      optionListitem.price *
                                      optionListitem.num *
                                      datas.exchange_rate
                                    )
                                  )
                                }}</span>
                                <span>(¥{{
                                  $fun.RMBNumSegmentation(
                                    optionListitem.price * optionListitem.num
                                  )
                                }})</span>
                              </div>
                            </div>
                          </div>
                          <div class="foot">
                            <div class="hejibox">
                              <p>{{ $fanyi('合计约为') }}：</p>
                              <p>
                                <span>${{
                                  $fun.RMBNumSegmentation(
                                    $fun.ceil(
                                      oneGoodsOptionTotalPrice *
                                      datas.exchange_rate
                                    )
                                  )
                                }}
                                </span>
                                <span>
                                  (￥{{
                                    $fun.RMBNumSegmentation(
                                      oneGoodsOptionTotalPrice
                                    )
                                  }})</span>
                              </p>
                            </div>
                            <p class="confirmbox">
                              <button class="react" @click="optionReact(item, item.id)">
                                {{ $fanyi('重置') }}</button><button class="save" @click="saveOrderOption(item, item.id)">
                                {{ $fanyi('保存') }}
                              </button>
                            </p>
                          </div>
                        </div>
                        <!-- 报价中显示 -->
                        <div class="additionalServices" v-else>
                          <div class="obody">
                            <div class="optionListitem" v-for="(optionListitem, index) in filteredOptions(
                              item.option
                            )" :key="'optionListitem' + index">
                              <!-- 选择框 -->
                              <div class="optionname">
                                <!-- 选择框 -->
                                <span class="tag">{{ optionListitem.tag }}
                                  {{ optionListitem.name_translate }}</span>
                              </div>
                              <!-- 数量 -->
                              <div class="optionnum">
                                {{ optionListitem.num }}
                              </div>

                              <!-- 单价 -->
                              <div class="optionprice">
                                <span>${{
                                  $fun.EURNumSegmentation(
                                    $fun.ceil(
                                      optionListitem.price *
                                      datas.exchange_rate
                                    )
                                  )
                                }}</span>
                                <span>(¥{{
                                  $fun.RMBNumSegmentation(
                                    optionListitem.price
                                  )
                                }})</span>
                              </div>
                              <!-- 预估总费用 -->
                              <div class="optiontotalprice">
                                <span>${{
                                  $fun.EURNumSegmentation(
                                    $fun.ceil(
                                      optionListitem.price *
                                      optionListitem.num *
                                      datas.exchange_rate
                                    )
                                  )
                                }}</span>
                                <span>(¥{{
                                  $fun.RMBNumSegmentation(
                                    optionListitem.price * optionListitem.num
                                  )
                                }})</span>
                              </div>
                            </div>
                          </div>
                          <div class="nooption" v-if="item.option.length == 0">
                            {{ $fanyi('无附加服务') }}
                          </div>
                          <div class="foot" v-if="item.option.length > 0">
                            <div class="hejibox">
                              <p>{{ $fanyi('合计约为') }}：</p>
                              <p>
                                <span>${{
                                  $fun.RMBNumSegmentation(
                                    $fun.ceil(
                                      oneGoodsOptionTotalPrice *
                                      datas.exchange_rate
                                    )
                                  )
                                }}
                                </span>
                                <span>
                                  (￥{{
                                    $fun.RMBNumSegmentation(
                                      oneGoodsOptionTotalPrice
                                    )
                                  }})</span>
                              </p>
                            </div>
                            <!-- <p class="confirmbox">
                            <button class="react" @click="optionReact(item)">
                              {{ $fanyi('重置') }}</button><button class="save"
                              @click="saveOrderOption(item, item.sorting)">
                              {{ $fanyi('保存') }}
                            </button>
                          </p> -->
                          </div>
                        </div>
                        <!-- 临时保存，修改订单展示 -->
                        <div class="optionInput" slot="reference">
                          <div class="inputOption " v-if="
                            ['temporary', 'editing'].indexOf(orderStatus) !=
                            -1
                          " @click="setOrderOption(item, item.id)">
                            <!-- 默认没有选择option展示 -->
                            <p class="NOData" v-if="item.option.every((i) => i.num == 0)">
                              Click to select another service
                            </p>

                            <div class="optionData" v-else>
                              <p class="haveData" v-for="(eeitem, index) in filteredOptions(
                                item.option
                              )" :key="index">
                                {{ eeitem.tag }}&nbsp;{{
                                  eeitem.name_translate
                                }}
                                <i>*{{ eeitem.num }}</i>
                              </p>
                            </div>
                          </div>
                          <!-- 报价中展示 -->
                          <div class="inputOption" v-else @click="setOrderOption(item)">
                            <!-- 默认没有选择option展示 -->
                            <p class="NOData dibsedData" v-if="item.option.every((i) => i.num == 0)">
                              {{ $fanyi('无附加服务') }}
                            </p>
                            <div class="optionData dibsedData" v-else>
                              <p class="haveData" v-for="(eeitem, index) in filteredOptions(
                                item.option
                              )" :key="index">
                                {{ eeitem.tag }}&nbsp;{{
                                  eeitem.name_translate
                                }}
                                <i>*{{ eeitem.num }}</i>
                              </p>
                            </div>
                          </div>
                        </div>
                      </el-popover>
                    </div>
                    <!-- 备注 -->
                    <div class="goodsremark">
                      <textarea v-if="
                        ['temporary', 'editing'].indexOf(orderStatus) != -1
                      " v-model="item.client_remark" :placeholder="$fanyi('您可以在这边添加商品备注')"
                        style="resize: none"></textarea>
                      <p class="beizhu" v-if="['offer', 'cancel'].indexOf(orderStatus) != -1">
                        <span v-if="item.client_remark">
                          {{ item.client_remark }}</span>
                        <span v-else style="color: #999999">{{
                          $fanyi('没有备注')
                        }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="showall">
              <span @click="Scrollinganimation()">{{ showall == false ? $fanyi('展开') : $fanyi('收起') }}
                <img :class="{ deg180: showall }" src="../../../../../assets/icon/putdown.png" alt="" />
                <!-- <spanclass="el-icon-arrow-down"></span> -->
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- 报价后的状态展示  付款后样式要进行修改不然放不下-->
      <div class="obligationordermain" :class="{
        Uponconfirmationofpayment:
          [
            'shipping',
            'purchased',
            'warehouse',
            'purchase',
            'shelves',
            'cancel',
            'delete',
            'In transit',
            'Delivered',
          ].indexOf(orderStatus) != -1,
      }" v-else>
        <div class="content">
          <div class="headerOrder">
            <div class="order_sn">
              {{ $fanyi('订单号') }}：{{ datas.order_sn }}
            </div>
            <div class="right_time">
              <span class="time" v-if="datas.created_at">
                {{ $fanyi('时间') }} ：{{ datas.created_at }}
              </span>
              <button v-if="isedit && orderStatus != 'temporary'" class="editOrder" @click="editorder">
                {{ $fanyi('修改订单') }}
              </button>
              <!-- 下载 -->
              <button v-if="ispayafter == true" class="Downloadpackinglist" @click="Downloadpackinglist">
                <img src="../../../../../assets/newImg/Packinglist/download.svg" alt="">
                {{ $fanyi('下载包装清单') }}
              </button>
            </div>
          </div>

          <!-- 如果是付款后就展示付款后页面可进行切换 -->
          <el-tabs :class="{ ispayaftershow: ispayafter == false }" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="$fanyi('订单详情')" name="order">
              <!-- 表格区域 -->
              <div class="tablebox" v-loading="loading" id="myElementtablebox">
                <div class="tableheader">
                  <!-- 全选 -->
                  <div class="headCheckBox">
                    <input class="CheckBox" type="checkbox" name="" id="" v-model="checked" @change="allChoice" />
                    {{ $fanyi('全选') }}
                  </div>

                  <!-- 产品 -->
                  <!-- <div class="goods">{{ $fanyi('产品') }}</div> -->
                  <!-- 属性 -->
                  <div class="detail">{{ $fanyi('属性') }}</div>
                  <!-- 单价 -->
                  <div class="price">{{ $fanyi('单价') }}</div>

                  <!-- 提出数量 -->
                  <div v-if="
                    [
                      'cancel',
                      'delete',
                      'temporary',
                      'offer',
                      'editing',
                      'obligation',
                      'Pending financial confirmation',
                    ].includes(orderStatus)
                  " class="Proposedquantity">
                    {{ $fanyi('提出数量') }}
                  </div>

                  <!-- 提出数量 -->
                  <div v-if="['cancel'].indexOf(orderStatus) == -1" class="Quantityinstock">
                    {{ $fanyi('提出数量') }}
                  </div>

                  <!-- 购买数量 付完款后实际采购数量  -->
                  <div class="Actualpurchasedquantity" v-if="
                    [
                      'purchase',
                      'purchased',
                      'shelves',
                      'warehouse',
                      'shipping',
                      'In transit',
                      'Delivered',
                    ].indexOf(orderStatus) !== -1
                  ">
                    {{ $fanyi('在库数') }}
                  </div>
                  <!-- 中国国内所有费用 -->
                  <div v-if="
                    [
                      'obligation',
                      'shipping',
                      'Pending financial confirmation',
                      'purchased',
                      'warehouse',
                      'purchase',
                      'shelves',
                      'cancel',
                      'delete',

                      'In transit',
                      'Delivered',
                    ].indexOf(orderStatus) != -1
                  " class="AllexpensesinChina">
                    {{ $fanyi('中国费用') }}
                  </div>

                  <!-- 资金变动明细 -->
                  <!-- <div v-if="
                    [
                      'shipping',
                      'purchased',
                      'warehouse',
                      'purchase',
                      'shelves',

                      'In transit',
                      'Delivered',
                    ].indexOf(orderStatus) != -1
                  " class="Detailofchangesinfunds">
                    {{ $fanyi('资金变动明细') }}
                  </div> -->

                  <!-- 附加服务费用 -->
                  <div v-if="
                    [
                      'obligation',
                      'shipping',
                      'Pending financial confirmation',
                      'purchased',
                      'warehouse',
                      'purchase',
                      'shelves',
                      'cancel',
                      'delete',

                      'In transit',
                      'Delivered',
                    ].indexOf(orderStatus) != -1
                  " class="options">
                    {{ $fanyi('附加服务费用') }}
                  </div>
                  <!--  备注-->
                  <div v-if="
                    [
                      'obligation',
                      'shipping',
                      'Pending financial confirmation',
                      'purchased',
                      'warehouse',
                      'purchase',
                      'shelves',
                      'cancel',
                      'delete',
                      'In transit',
                      'Delivered',
                    ].indexOf(orderStatus) != -1
                  " class="remarks">
                    {{ $fanyi('备注') }}
                  </div>
                  <!-- 业务备注回复 -->
                  <div v-if="
                    [
                      'obligation',
                      'shipping',
                      'Pending financial confirmation',
                      'purchased',
                      'warehouse',
                      'purchase',
                      'shelves',
                      'cancel',

                      'delete',
                      'In transit',
                      'Delivered',
                    ].indexOf(orderStatus) != -1
                  " class="Replytobusinessremarks">
                    {{ $fanyi('业务备注回复') }}
                  </div>
                  <!-- 商品状态 -->
                  <div v-if="
                    [
                      'obligation',
                      'shipping',
                      'Pending financial confirmation',
                      'purchased',
                      'warehouse',
                      'purchase',
                      'shelves',
                      'cancel',
                      'delete',
                      'In transit',
                      'Delivered',
                    ].indexOf(orderStatus) != -1
                  " class="Commoditystate">
                    {{ $fanyi('商品状态') }}
                  </div>
                </div>
                <div class="tablebody" id="myElementGoodslist">
                  <!-- 店铺 -->
                  <div class="shopsitem" v-for="(aitem, aindex) in newList" :key="aitem.shop_id">
                    <div class="shopheader">
                      <h1>
                        <div class="checkedBox">
                          <input class="shopchecked" @change="shopAll(aindex)" type="checkbox"
                            v-model="aitem.checked" />
                        </div>

                        {{ $fanyi('店铺') }}：{{ aitem.shop_name }}
                        <span class="img1688box"><img v-if="aitem.list[0].shop_type == '1688'"
                            src="../../../../../assets/newImg/order/al.svg" alt="" />
                        </span>
                      </h1>
                    </div>
                    <div class="shopbody">
                      <div class="goodsitem" :class="{
                        bordercolorred:
                          item.confirm_num == 0 &&
                          item.order_detail_replace.length == 0 &&
                          orderStatus == 'obligation',
                        bordercolorblue:
                          item.order_detail_replace.length > 0 &&
                          orderStatus == 'obligation',
                      }" v-for="item in aitem.list" :key="item.id">
                        <!-- 产品不可用 -->
                        <div v-if="
                          item.confirm_num == 0 && orderStatus == 'obligation'
                        " class="unavailable">
                          {{ $fanyi('产品不可用') }}
                        </div>
                        <div v-if="
                          item.order_detail_replace.length > 0 &&
                          orderStatus == 'obligation'
                        " class="unavailable">
                          {{ $fanyi('产品不可用') }}
                        </div>
                        <!-- 单选 -->
                        <div class="goodsheadCheckBox">
                          <!--单选-->
                          <div class="goodscheckedBox">
                            <input class="goodschecked" type="checkbox" name="" id="" @change="radio(aitem)"
                              v-model="item.checked" />
                          </div>
                          <!-- 商品番号 -->
                          <span class="rowNumber">{{ item.sorting }}</span>
                          <el-popover placement="right" trigger="hover">
                            <img :src="item.pic" alt="" style="width: 288px; height: 298px" />

                            <img class="goodimg" @click="
                              $fun.toCommodityDetails({
                                goods_id: item.goods_id,
                                shop_type: item.shop_type,
                              })
                              " :src="item.pic" alt="" slot="reference" />
                          </el-popover>
                        </div>
                        <!-- 产品 -->
                        <!-- <div class="goodsproduct">
                          <el-popover placement="bottom" width="400" trigger="hover">
                            {{ item.goods_title }}
                            <div class="showGoodsTitle" :title="item.goods_title" slot="reference" @click="
                              $fun.toCommodityDetails({
                                goods_id: item.goods_id,
                                shop_type: item.from_platform,
                              })
                              ">
                              <div class="title">
                                {{ item.goods_title }}
                              </div>
                            </div>
                          </el-popover>
                        </div> -->
                        <!-- 属性 -->
                        <div class="goodsdetail">
                          <el-popover placement="top" trigger="hover">
                            <div class="showdetailWindowBody">
                              <div class="detailOpt" v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex">
                                <h1>{{ $fun.trunUpperCase(bitem.key) }} :</h1>
                                <div class="optClass">
                                  <span>{{ bitem.value }}</span>
                                </div>
                              </div>
                            </div>
                            <!-- 展示 -->
                            <div slot="reference" class="showDetail obligationaffter">
                              <div class="showDetailCon" style="width: 120px;">
                                <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                                  class="goodsDetailOpt">
                                  {{ $fun.trunUpperCase(bitem.key) }}:{{
                                    bitem.value
                                  }}
                                </p>
                              </div>
                            </div>
                          </el-popover>
                        </div>
                        <!-- 单价 -->
                        <div class="goodsprice">
                          <span>$
                            {{
                              $fun.EURNumSegmentation(
                                $fun.ceil(
                                  (item.confirm_price && item.confirm_price > 0
                                    ? item.confirm_price
                                    : item.price) * datas.exchange_rate
                                )
                              )
                            }}</span>
                          <span>(￥{{
                            $fun.RMBNumSegmentation(
                              $fun.ceil(
                                item.confirm_price && item.confirm_price > 0
                                  ? item.confirm_price
                                  : item.price
                              )
                            )
                          }})</span>
                        </div>
                        <!-- 提出数量 -->
                        <div class="goodsProposedquantity" v-if="
                          [
                            'cancel',
                            'delete',
                            'temporary',
                            'offer',
                            'editing',
                            'obligation',
                            'Pending financial confirmation',
                          ].includes(orderStatus)
                        ">
                          {{ item.num }}
                        </div>

                        <!-- 调查在库数量 -->
                        <div class="goodsQuantityinstock" v-if="['cancel'].indexOf(orderStatus) == -1">
                          {{ item.confirm_num }}
                        </div>
                        <!-- 实际采购数量 -->
                        <div v-if="
                          [
                            'purchase',
                            'purchased',
                            'shelves',
                            'warehouse',
                            'shipping',
                            'In transit',
                            'Delivered',
                          ].indexOf(orderStatus) !== -1
                        " class="goodsActualpurchasedquantity">
                          {{ item.purchase_num }}
                        </div>
                        <!-- 中国国内所有费用 没有替换链接展示-->
                        <div class="goodsAllexpensesinChina" v-if="
                          orderStatus == 'obligation'
                            ? [
                              'obligation',
                              'shipping',
                              'Pending financial confirmation',
                              'purchased',
                              'warehouse',
                              'purchase',
                              'shelves',
                              'cancel',
                              'delete',

                              'In transit',
                              'Delivered',
                            ].indexOf(orderStatus) != -1 &&
                            item.order_detail_replace.length == 0
                            : [
                              'obligation',
                              'shipping',
                              'Pending financial confirmation',
                              'purchased',
                              'warehouse',
                              'purchase',
                              'shelves',
                              'cancel',
                              'delete',

                              'In transit',
                              'Delivered',
                            ].indexOf(orderStatus) != -1
                        ">
                          <div class="china_amount">
                            ${{
                              $fun.EURNumSegmentation(
                                item.china_amount.sum_amount
                              )
                            }}
                          </div>
                          <el-popover placement="bottom" trigger="click">
                            <div class="goodsChinatotalcostpopover">
                              <p>
                                <span class="title">
                                  {{ $fanyi('产品费用') }}：
                                </span>
                                <span class="content">
                                  ${{
                                    $fun.EURNumSegmentation(
                                      item.china_amount.list.goods_amount
                                    )
                                  }}
                                </span>
                              </p>
                              <p>
                                <span class="title">
                                  {{ $fanyi('佣金') }}：
                                </span>
                                <span class="content" style="font-weight: 700">
                                  ${{
                                    $fun.EURNumSegmentation(
                                      item.china_amount.list.service_amount
                                    )
                                  }}
                                </span>
                              </p>
                              <p>
                                <span class="title">
                                  {{ $fanyi('附加服务') }}：
                                </span>
                                <span class="content" style="font-weight: 700">
                                  ${{
                                    $fun.EURNumSegmentation(
                                      item.china_amount.list.option_amount
                                    )
                                  }}
                                </span>
                              </p>
                              <p>
                                <span class="title">
                                  {{ $fanyi('中国物流费用') }}：
                                </span>
                                <span class="content">
                                  ${{
                                    $fun.EURNumSegmentation(
                                      item.china_amount.list
                                        .china_freight_amount
                                    )
                                  }}
                                </span>
                              </p>
                            </div>
                            <span class="mingXi" slot="reference">
                              {{ $fanyi('明细') }}
                              <div class="el-icon-arrow-down"></div>
                            </span>
                          </el-popover>
                        </div>

                        <!-- 资金变动明细 -->
                        <!-- <div v-if="
                          [
                            'shipping',
                            'purchased',
                            'warehouse',
                            'purchase',
                            'shelves',

                            'In transit',
                            'Delivered',
                          ].indexOf(orderStatus) != -1
                        " class="goodsDetailofchangesinfunds">
                          <div class="change_price">
                            ${{
                              $fun.RMBNumSegmentation(
                                $fun.ceil(item.change_price)
                              )
                            }}
                          </div>
                          <div class="goodsDetailofchangesinfundsmingXi" @click="
                            goodsPriceUpdateDetailDialogVisible = true
                          getdetails(item.id)
                            ">
                            {{ $fanyi('详细') }}
                            <span class=" el-icon-arrow-right"></span>
                          </div>
                        </div> -->
                        <!-- 附加服务费用 -->
                        <div v-if="!shouldShowItem(item)" class="goodsoptions">
                          <div class="service" v-if="
                            item.option.length > 0 &&
                            item.option.some((i) => i.num != 0)
                          ">
                            <p class="item" v-for="(optitem, index) in item.option.filter(
                              (i) => i.num > 0
                            )" :key="index">
                              {{ optitem.tag }}{{ optitem.name_translate }}*{{
                                optitem.num
                              }}
                            </p>
                          </div>
                          <div class="noserve" v-else>
                            {{ $fanyi('无附加服务') }}
                          </div>
                        </div>
                        <!-- 备注 -->
                        <div v-if="!shouldShowItem(item)" class="goodsremarks">
                          <div class="remarksbox" v-if="item.client_remark">
                            {{ item.client_remark }}
                          </div>
                          <!-- 没有备注 -->
                          <div class="noremark" v-else>
                            {{ $fanyi('没有备注') }}
                          </div>
                        </div>
                        <!-- 业务备注回复 -->
                        <div v-if="!shouldShowItem(item)" class="goodsReplytobusinessremarks">
                          <div class="y_replybox" v-if="item.y_reply">
                            {{ item.y_reply }}
                          </div>
                          <!-- 没有备注 -->
                          <div class="noy_reply" v-else>
                            {{ $fanyi('没有备注') }}
                          </div>
                        </div>
                        <!-- 商品状态 -->
                        <div v-if="!shouldShowItem(item)" class="goodsCommoditystate">
                          {{ status_name ? $fanyi(status_name) : '---' }}
                        </div>
                        <div v-if="shouldShowItem(item)" class="Viewbox">
                          <!-- 查看商品详情 -->

                          <div @click="clickGoodsDetails(item)" class="ViewProducts">
                            {{ $fanyi('产品详情') }}
                          </div>

                          <!-- 替换链接 -->
                          <div class="Replacementlink">
                            <!-- 替换产品 -->
                            <SustituciónDeBienes :goodsToBeReplaced="item" :shopName="aitem.shop_name"
                              :SustituciónDeBienes="item.order_detail_replace"></SustituciónDeBienes>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="showall">
                  <span @click="Scrollinganimation()">{{ showall == false ? $fanyi('展开') : $fanyi('收起') }}
                    <img :class="{ deg180: showall }" src="../../../../../assets/icon/putdown.png" alt="" />
                    <!-- <spanclass="el-icon-arrow-down"></span> -->
                  </span>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$fanyi('装箱单') + '(' + packinglistNum + ')'" name="deliveryorder">
              <!-- 装箱单信息-->
              <div class="Packinglist">
                <div class="headerdeliveryorderbox">
                  <span v-for="(packingitem, ind) in deliverypackinglist"
                    :class="{ activepackingitem: packingitem.checked }" @click="packingitemclick(packingitem)"
                    :key="ind">{{
                      packingitem.porder_sn }}</span>
                </div>
                <!--费用地址信息  -->
                <div class="costbox">
                  <div class="costboxrow">
                    <!-- 国际运输费用 -->
                    <div class="Internationaltransportationcosts">
                      <h2>{{ $fanyi('国际运输费用') }}</h2>
                      <div class="cost">
                        <!-- 国际物流成本 -->
                        <div class="costitem">
                          <span>{{ $fanyi('国际物流成本') }}:</span>
                          <span>${{ packinglistdetails.logistics_price }}</span>
                        </div>
                        <!-- 美国国内物流成本： -->
                        <div class="costitem">
                          <span>{{ $fanyi('美国国内物流成本') }}:</span>
                          <span>${{ packinglistdetails.foreign_express_price }}</span>
                        </div>
                        <!--其他费用  -->
                        <div class="costitem">
                          <span>{{ $fanyi('其他费用') }}:</span>
                          <span>${{ packinglistdetails.other_price }}</span>
                        </div>
                        <!--汇率  -->
                        <div class="costitem">
                          <span>{{ $fanyi('汇率') }}:</span>
                          <span>1CNY ≈ {{ packinglistdetails.exchange_rate }}</span>
                        </div>
                        <!-- 总成本 -->
                        <div class="costitem">
                          <span>{{ $fanyi('总成本') }}:</span>
                          <span>${{ packinglistdetails.total_price }}</span>
                        </div>
                      </div>

                      <!-- 装箱单状态 -->
                      <div class="Packingliststatusbox">
                        <div class="Packingliststatus">
                          <!-- 装箱单状态 -->
                          <span>{{ $fanyi('装箱单状态') }}</span>
                          <span class="status">{{ Packingliststatus(packinglistdetails.status) }}</span>
                        </div>
                        <div v-if="Number(packinglistdetails.status) == 30" @click="pay(porder_sn)" class="pay">{{
                          $fanyi('支付')
                        }}
                        </div>
                      </div>
                    </div>

                    <!-- 发货信息 -->
                    <div class="Shippinginformation">
                      <h2>{{ $fanyi('发货信息') }}</h2>
                      <div class="informationbox">
                        <div class="information">
                          <!-- Rakumart装箱单单号 -->
                          <span>{{ $fanyi('Rakumart装箱单单号') }} :</span>
                          <span> {{ packinglistdetails.porder_sn }}</span>
                        </div>
                        <!-- 收件人 -->
                        <div class="information">
                          <span> {{ $fanyi('收件人') }}:</span>
                          <span> {{ packinglistdetails.receive.contacts }}</span>
                        </div>
                        <!-- 地址 -->
                        <div class="information">
                          <span>{{ $fanyi('地址') }}: </span>
                          <span>{{ packinglistdetails.receive.address }}
                          </span>
                        </div>
                        <!-- 城市 -->
                        <div class="information">
                          <span>{{ $fanyi('城市') }}: </span>
                          <span>{{ packinglistdetails.receive.city }} </span>
                        </div>
                        <!-- 州 -->
                        <div class="information">
                          <span>{{ $fanyi('州') }}: </span>
                          <span> {{ packinglistdetails.receive.state }}</span>
                        </div>
                        <!-- 邮编 -->
                        <div class="information">
                          <span>{{ $fanyi('邮编') }}: </span>
                          <span> {{ packinglistdetails.receive.zip_code }}</span>
                        </div>
                        <!-- 电话 -->
                        <div class="information">
                          <span> {{ $fanyi('电话') }}:</span>
                          <span> {{ packinglistdetails.receive.mobile }} </span>
                        </div>
                        <!-- 邮箱 -->
                        <div class="information">
                          <span> {{ $fanyi('邮箱') }}:</span>
                          <span>{{ packinglistdetails.receive.email }} </span>
                        </div>
                      </div>
                    </div>

                    <!-- 装箱单备注 -->
                    <div class="Remarksofpackinglist">
                      <h2>{{ $fanyi('装箱单备注') }}</h2>
                      <textarea v-model="packinglistdetails.client_remark" :disabled="true"></textarea>
                      <h2>{{ $fanyi('包括以下订单中的产品') }}</h2>
                      <el-table height="132" :header-cell-style="{
                        'text-align': 'center',
                        'font-size': '14px ',
                        color: 'black',
                        'font-weight': '500',
                        background: '#F6F6F6',
                        height: '32px !important',
                        padding: '5px'
                      }" :cell-style="{
                        'text-align': 'center',
                        padding: '4px',
                        height: '32px !important', 'font-size': '14px',
                      }" :data="packinglistdetails.order_sn" border style="width: 100%">
                        <el-table-column prop="no" label="NO" width="139">
                        </el-table-column>
                        <el-table-column prop="order_sn" :label="$fanyi('订单号')" width="260">
                          <template slot-scope="scope">
                            <div @click="openorderDetails(scope.row.order_sn)" class="order_sn">
                              {{ scope.row.order_sn }}
                            </div>
                          </template>

                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>


                <!-- 物流信息 -->
                <div class="Logisticsinformation"> <!-- 配送单列表 -->
                  <div class="Distributionlist">
                    <el-table :header-cell-style="{
                      'text-align': 'center',
                      'font-size': '14px ',
                      color: '#222222 ',
                      'font-weight': '500',
                      background: '#F6F6F6',
                      height: '40px !important',
                      padding: '5px'
                    }" :cell-style="{
                      'text-align': 'center',
                      padding: '4px',
                      height: '50px !important', 'font-size': '14px',
                    }" :data="LogisticsInfo" border style="width: 100%">
                      <!-- 运单号 -->
                      <el-table-column :label="$fanyi('运单号')">
                        <template slot-scope="scope">
                          <div @click="waybill(scope.row)" class="waybill">
                            {{ scope.row.express_no }}
                          </div>
                        </template>
                      </el-table-column>
                      <!-- 物流方式 -->
                      <el-table-column :label="$fanyi('物流方式')">
                        <template slot-scope="scope">
                          <div>{{ scope.row ? scope.row.logistics_info.name : '' }}</div>
                        </template>
                      </el-table-column>
                      <!-- 装箱数量 -->
                      <el-table-column prop="box_number" :label="$fanyi('装箱数量')"> <template slot-scope="scope">
                          <div>{{ scope.row.box_number }}</div>
                        </template>
                      </el-table-column>
                      <!-- 重量 -->
                      <el-table-column prop="weight" :label="$fanyi('重量') + '(KG)'"><template slot-scope="scope">
                          <div>{{ scope.row.weight }}</div>
                        </template>
                      </el-table-column>
                      <!-- 体积 -->
                      <el-table-column prop="volume" :label="$fanyi('体积') + '(m³)'"><template slot-scope="scope">
                          <div>{{ scope.row.volume }}</div>
                        </template>
                      </el-table-column>
                    </el-table>

                    <!-- 提示 -->
                    <div class="tip">
                      {{ $fanyi('提示') }} ：{{ $fanyi('您可以点击运单号查看实时物流信息') }}
                    </div>
                  </div>

                  <!-- 跟踪物流状态 -->

                  <div class="TrackingStatus">
                    <h2>{{ $fanyi('跟踪状态') }}</h2>

                    <div class="iconbox">
                      <!-- Rakumart已经发货 -->
                      <div class="iconitem"
                        :class="{ activeicon: getStatusArray(LogisticsInfo[0].logistics_status, 0) }">
                        <img v-if="getStatusArray(LogisticsInfo[0].logistics_status, 0)"
                          src="../../../../../assets/newImg/Packinglist/1-1.svg" alt="">
                        <img v-else src="../../../../../assets/newImg/Packinglist/1-2.svg" alt="">

                        <p>{{ $fanyi('Rakumart已经发货') }}</p>
                      </div>
                      <div class="line">

                      </div>
                      <!-- 中转 -->
                      <div class="iconitem"
                        :class="{ activeicon: getStatusArray(LogisticsInfo[0].logistics_status, 1) }">
                        <img v-if="getStatusArray(LogisticsInfo[0].logistics_status, 1)"
                          src="../../../../../assets/newImg/Packinglist/2-1.svg" alt="">
                        <img v-else src="../../../../../assets/newImg/Packinglist/2-2.svg" alt="">
                        <p>{{ $fanyi('中转') }}</p>
                      </div>
                      <div class="line">

                      </div>
                      <!-- 美国国内派送 -->
                      <div class="iconitem"
                        :class="{ activeicon: getStatusArray(LogisticsInfo[0].logistics_status, 2) }">
                        <img v-if="getStatusArray(LogisticsInfo[0].logistics_status, 2)"
                          src="../../../../../assets/newImg/Packinglist/3-1.svg" alt="">
                        <img v-else src="../../../../../assets/newImg/Packinglist/3-2.svg" alt="">
                        <p>{{ $fanyi('美国国内派送') }}</p>

                      </div>
                      <div class="line">

                      </div>
                      <!--交付 -->
                      <div class="iconitem"
                        :class="{ activeicon: getStatusArray(LogisticsInfo[0].logistics_status, 3) }">
                        <img v-if="getStatusArray(LogisticsInfo[0].logistics_status, 3)"
                          src="../../../../../assets/newImg/Packinglist/4-1.svg" alt="">
                        <img v-else src="../../../../../assets/newImg/Packinglist/4-2.svg" alt="">
                        <p>{{ $fanyi('交付') }}</p>
                      </div>

                    </div>

                    <div class="textareabox" v-if="LogisticsInfo[0].information.length > 0">

                      <p v-for="(itm, i) in LogisticsInfo[0].information" :key="i"> <span>{{ itm.datetime
                          }}</span> <span>{{ itm.msg }}</span> </p>
                    </div>
                    <div class="noData" v-else>{{
                      $fanyi('暂无数据')
                    }}</div>

                  </div>
                </div>
                <!-- 包装单详情 Packinglist details -->
                <div class="Packinglistdetails">
                  <div class="detailscontent" :class="{ showCloseheight: showClose }" id="detailscontentRef">
                    <div class="headertitle">
                      <!--  @click="headerclick(ite)" -->
                      <div class="left" @click="headerclick">
                        <!-- 装箱单详细信息-->
                        <span>{{ $fanyi('装箱单详细信息') }}</span>
                        <!-- 提示 -->
                        <span class="tip">
                          {{ $fanyi('提示') + ': ' + $fanyi('点击此处展开或折叠所有部分') }}
                        </span>
                      </div>
                      <div class="right">
                        <!-- 展开 -->
                        <img v-if="showClose" @click="headerclick" src="@/assets/newImg/Packinglist/one-2.svg" alt="">
                        <!-- 收齐 -->
                        <img v-else @click="headerclick" src="@/assets/newImg/Packinglist/one-1.svg" alt="">

                      </div>

                    </div>
                    <div class="maintable" v-for="(ite, index) in Packinginformation" :key="index" id="maintableRef">
                      <el-collapse v-model="activeNames">
                        <el-collapse-item title="一致性 Consistency" name="1">
                          <template slot="title">
                            <div class="itemheader">

                              <!-- 箱号 -->
                              <div class="Cartonnumber">
                                <span> {{ $fanyi('箱号') }} :</span>
                                <span>{{ ite.number }}</span>
                              </div>
                              <!-- 规格 -->
                              <div class="specification">
                                <!-- 装有相同货物的箱子数量 -->

                                <div class="samegoods">
                                  <span>{{ $fanyi('装有相同货物的箱子数量') }} :</span><span>{{ ite.total }}</span>
                                </div>
                                <!-- 重量 -->
                                <div class="Weight"> <span>{{ $fanyi('重量') }}(kg) :</span><span>{{ ite.weight }}</span>
                                </div>

                                <!-- 体积 -->
                                <div class="Volume"> <span>{{ $fanyi('体积') }} (m³):</span><span>{{ ite.volume }}</span>
                                </div>
                                <!-- 长 -->
                                <div class="Length"> <span>{{ $fanyi('长') }}(cm) :</span><span>{{ ite.length }}</span>
                                </div>
                                <!-- 宽 -->
                                <div class="Width"> <span>{{ $fanyi('宽') }}(cm) :</span><span>{{ ite.width }}</span>
                                </div>
                                <!-- 高 -->
                                <div class="Height"> <span>{{ $fanyi('高') }}(cm) :</span><span>{{ ite.height }}</span>
                                </div>

                              </div>
                            </div>

                          </template>
                          <!-- 表格区域 -->
                          <el-table :header-cell-style="{
                            'text-align': 'center',
                            'font-size': '14px ',
                            color: '#222222 ',
                            'font-weight': '500',
                            background: '#F6F6F6',
                            height: '40px !important',
                            padding: '5px'
                          }" :cell-style="{
                            'text-align': 'center',
                            padding: '4px',
                            height: '100px', 'font-size': '14px',
                          }" :data="ite.goods" border style="width: 100%">
                            <!-- 订单号 -->
                            <el-table-column width="220" :label="$fanyi('订单号')">
                              <template slot-scope="scope">
                                <div class="waybill">
                                  {{ scope.row.order_sn }}
                                </div>
                              </template>
                            </el-table-column>
                            <!-- 序号 -->
                            <el-table-column prop="sorting" width="80" :label="$fanyi('NO')">
                            </el-table-column>
                            <!-- 商品图片 -->
                            <el-table-column prop="pic" width="110" :label="$fanyi('商品图片')">
                              <template slot-scope="scope">
                                <el-popover placement="right" trigger="hover">
                                  <img :src="scope.row.pic" alt="" style="width: 288px; height: 298px" />

                                  <img class="goodimg" :src="scope.row.pic" alt="" slot="reference" />
                                </el-popover>
                              </template>

                            </el-table-column>
                            <!-- 商品链接 -->
                            <el-table-column prop="url" width="200" :label="$fanyi('商品链接')">
                              <template slot-scope="scope">

                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <div>{{ scope.row.url }}</div>
                                  <div slot="reference" class="goods_title">
                                    {{ scope.row.url }}
                                  </div>

                                </el-popover>

                              </template>
                            </el-table-column>
                            <!-- 产品英文名称 -->
                            <el-table-column prop="goods_title" width="160" :label="$fanyi('产品英文名称')">
                              <template slot-scope="scope">

                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <div>{{ scope.row.goods_title }}</div>
                                  <div slot="reference" class="goods_title">
                                    {{ scope.row.goods_title }}
                                  </div>

                                </el-popover>

                              </template>
                            </el-table-column>
                            <!-- 商品属性 -->
                            <el-table-column prop="name" width="200" :label="$fanyi('商品属性')
                              "> <template slot-scope="scope">
                                <p v-for="(column, ind) in scope.row.detail" :key="ind"><span>{{ column.key }}:</span>
                                  <span>{{
                                    column.value }}</span>
                                </p>
                              </template>
                            </el-table-column>
                            <!-- 包装数量 -->
                            <el-table-column prop="num" :label="$fanyi('包装数量')">
                            </el-table-column>
                            <!-- 单价 -->
                            <el-table-column prop="price_usd" :label="$fanyi('单价') + '($)'">
                            </el-table-column>
                            <!-- 小计 -->
                            <el-table-column prop="total_price_usd" :label="$fanyi('小计') + '($)'">
                            </el-table-column>
                          </el-table>
                        </el-collapse-item>

                      </el-collapse>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <!-- 提出订单操作栏 -->
      <div class="operatingOptions" v-if="['warehouse'].indexOf(orderStatus) == -1">
        <div class="operatingOptionsLeft">
          <div class="choies">
            <input class="chioseInput" type="checkbox" name="" id="" v-model="checked" @change="allChoice" />
            <button class="qx" @click="
              checked = !checked
            allChoice()
              ">
              {{ $fanyi('全选') }}
            </button>
          </div>
          <div class="caozuoBox">
            <!-- 加入商品库 除了修改和临时保存，其他状态展示-->
            <div class="caoZuoAnNiuGroup" v-if="['temporary', 'editing'].indexOf(orderStatus) == -1">
              <button class="addLibrary" @click="addLibraryOfGoods">
                {{ $fanyi('加入商品库') }}
              </button>
            </div>
            <!--  临时保存和修改的时候展示 -->
            <div class="caoZuoAnNiuGroup" v-else>
              <el-popover placement="top" min-width="180" trigger="click">
                <el-button slot="reference" class="opt">{{
                  $fanyi('操作')
                }}</el-button>

                <!-- 删除 -->
                <div class="delBtn" v-if="['temporary', 'editing'].indexOf(orderStatus) != -1" @click="delOrder">
                  {{ $fanyi('删除') }}
                </div>
                <!--  -->
                <div class="addgoods" v-if="['temporary', 'editing'].indexOf(orderStatus) != -1" @click="addGoodsbtn()">
                  {{ $fanyi('追加商品') }}
                </div>

                <div class="addLibraryOfGoods" @click="addLibraryOfGoods">
                  {{ $fanyi('加入商品库') }}
                </div>
              </el-popover>
            </div>
          </div>
          <!-- 底部订单付款展示 -->
          <div class="orderDataBoxobligation" v-if="orderStatus == 'obligation'">
            <span> {{ $fanyi('数量总计') }}：{{ quantityOfCommodity }} </span>
            <div v-if="
              [
                'obligation',
                'offer',
                'purchase',
                'purchased',
                'delete',
                'shipping',
                'Pending financial confirmation',
                'warehouse',
                'shelves',
                'In transit',
                'Delivered',
              ].indexOf(orderStatus) != -1
            ">
              {{ $fanyi('总体积') }}：&nbsp;{{
                $fun.ceil(datas.orderVolume_m3 || 0)
              }}&nbsp;m³
            </div>
          </div>
          <!-- 其他状态展示 -->
          <div class="orderDataBox" v-else>
            <span> {{ $fanyi('数量总计') }}：{{ quantityOfCommodity }} </span>
            <span v-if="
              [
                'obligation',
                'offer',
                'purchase',
                'purchased',
                'delete',
                'shipping',
                'Pending financial confirmation',
                'warehouse',
                'shelves',
                'In transit',
                'Delivered',
              ].indexOf(orderStatus) != -1
            ">{{ $fanyi('总体积') }}：&nbsp;{{
              $fun.ceil(datas.orderVolume_m3 || 0)
            }}&nbsp;m³</span>
          </div>
        </div>

        <!--右边 -->
        <div class="operatingOptionsRight">
          <!-- 支付 -->
          <div class="Amountpaid">
            <!-- 优惠券 -->
            <div class="youhuijuan" v-if="orderStatus == 'obligation'">
              <!-- -->
              <el-checkbox v-model="couponChecked" @change="showCouponList">
                {{ $fanyi('使用优惠券') }}
              </el-checkbox>
              <span>{{ datas.coupon_num }}</span>
            </div>
            <!-- 实际支付金额 -->
            <div class="left">
              <div class="zongjiaBox">
                <span> {{ $fanyi('实际支付金额') }}:</span>
                <span>
                  <font class="priceBox">￥{{ orderPriceGroup.sum_discount_price || 0 }}</font>
                </span>
              </div>
              <div class="zongjiaBoxMeiYuan">
                <span class="huiLv"> （1￥≈${{ datas.exchange_rate }}） </span>
                <span class="meiyuanPrice">
                  ${{
                    $fun.EURNumSegmentation(
                      $fun.ceil(orderPriceGroup.sum_discount_price_dollar || 0)
                    )
                  }}
                </span>
              </div>
            </div>
            <!-- 付款 -->
            <div class="caoZuoBtnBox" v-if="orderStatus == 'obligation'">
              <button class="payBtn" @click="payMent">
                {{ $fanyi('付款') }}
              </button>
            </div>
            <!-- 提出配送单 -->
            <div class="caoZuoBtnBox" v-if="['temporary'].indexOf(orderStatus) != -1">
              <button class="baocun" @click="OrdersSubmitted(10)">
                {{ $fanyi('临时保存') }}
              </button>
              <button class="tichu" @click="OrdersSubmitted(20)">
                {{ $fanyi('提出订单') }}
              </button>
            </div>
            <!--修改的 提出订单 -->

            <div class="caoZuoBtnBox" v-if="['editing'].indexOf(orderStatus) != -1">
              <button class="cancel" @click="pageLoad">
                {{ $fanyi('取消') }}
              </button>
              <button class="chongXinTiChu" @click="resubmit">
                {{ $fanyi('重新提出') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- 备注栏,费用栏-->
      <SpecialNoteVue v-if="
        ['temporary', 'editing', 'offer', 'delete', 'cancel'].indexOf(
          orderStatus
        ) != -1
      " />
      <!--    RAKUMART订单价值-->
      <RAKUMARTOrderValue v-if="
        [
          'obligation',
          'purchased',
          'shipping',
          'purchase',
          'warehouse',
          'Pending financial confirmation',
          'In transit',
          'shelves',
          'Delivered',
        ].indexOf(orderStatus) != -1 && activeName == 'order'
      " />
      <ModeOofTransportation @Selectivelogistics="Selectivelogistics" ref="odeOofTrans" v-if="
        [
          'purchased',
          'shipping',
          'purchase',
          'warehouse',
          'shelves',
          'obligation',
          'Pending financial confirmation',
          'In transit',
          'Delivered',
        ].indexOf(orderStatus) != -1 && activeName == 'order'
      " />
      <!-- <shippingInformation v-if="ShippingOrderInformation.length !== 0 && activeName == 'order'"
        :list="ShippingOrderInformation" /> -->
      <!-- 优惠劵弹窗 -->
      <Coupons @Usecoupons="Usecoupons" ref="CouponDialogRef" />
      <!-- 追加商品 -->
      <el-dialog class="cartDia" custom-class="addGoodsdialog" :visible.sync="showCart" width="1400px">
        <addGoods v-if="showCart"></addGoods>
      </el-dialog>
      <!--!资金变动明细弹窗  暂时不要了 -->
      <el-dialog :lock-scroll="false" ref="Changedetails" custom-class="Changedetailsdialog"
        :visible.sync="goodsPriceUpdateDetailDialogVisible" :show-close="false">
        <goodsPriceUpdateDetail ref="Changedetails" @cloneGoodsPriceUpdateDetailDialogVisible="cloneGoodsPriceUpdateDetailDialogVisible
          " />
      </el-dialog>
      <!-- 库存为0 提示 为删除或者修改订单 -->
      <el-dialog :visible.sync="zeroInventoryGoodsDialogVisible" custom-class="zeroInventoryGoodsDialog" width="400px"
        :show-close="false">
        <div class="zeroInventoryGoodsDialogVisibleHeader flexAndCenter">
          <div>{{ $fanyi('提醒') }}</div>
          <img src="../../../../../assets/newImg/order/cancel.png" alt=""
            @click="zeroInventoryGoodsDialogVisible = false" />
        </div>
        <div class="zeroInventoryGoodsDialogVisibleCenter">
          <img src="../../../../../assets//newImg/order/remind.png" alt="" />
          <div>
            {{
              $fanyi(
                '当前订单商品暂无库存，无法完成购买。您可以调整订单重新提出 也可以直接删除该订单。'
              )
            }}
          </div>
        </div>
        <div class="zeroInventoryGoodsDialogVisibleFooter flexAndCenter">
          <div @click="delObligationOrder">{{ $fanyi('删除订单') }}</div>
          <div @click="editorder()">
            {{ $fanyi('修改订单') }}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
  <placingSuccessfullyPage v-else ref="Proposesuccessfully" :order_sn="datas.order_sn"></placingSuccessfullyPage>
</template>
<script>
import HomePageTop from '../../../../../components/head/HomePageTop.vue'
import SpecialNoteVue from './components/SpecialNote.vue'
import addGoods from './components/addGoods.vue'
import SustituciónDeBienes from './components/SustituciónDeBienes.vue'
import RAKUMARTOrderValue from './components/RAKUMARTOrderValue.vue'
import Coupons from './components/Coupons.vue'
import ModeOofTransportation from './components/ModeOofTransportation.vue'
import goodsPriceUpdateDetail from './components/goodsPriceUpdateDetail.vue'
import shippingInformation from './components/shippingInformation.vue'
import placingSuccessfullyPage from './components/placingSuccessfullyPage'
import Europess from '@/api/wangZhi'
let Europe = Europess + '/client/'
import axios from 'axios';
export default {
  name: 'newOrderDetails',
  data() {
    return {
      orderStatus: '', //订单状态名(临时保存的：temporary，报价中：offer，等待付款：obligation，购买中：purchase，购买完成：purchased，删除：delete,修改中 : editing, warehouse 上架完了)
      datas: {}, // 订单获取到的所有数据
      oldobligation: '', // 记录老订单状态
      isedit: false, // 是否是修改订单
      optionList: [], // 附加服务列表
      loading: false, //页面加载loading
      newList: [], // 列表
      logistics: '', // 物流名称
      orderPriceGroup: {
        sum_discount_price: 0,
        sum_discount_price_dollar: 0,
      }, //计算完价格数据
      checked: false, // 全选
      showall: false, // 展开更多
      showCart: false, // 购物车弹窗状态
      CouponsData: {
        id: '',
        title: '',
      }, // 优惠券使用数据
      showClose: true,
      porder_sn: "",//选中的配送单号
      ispayafter: false, // 是否付款后
      isPackinglist: false, // 是否有装箱单
      packinglistNum: 0,// 装箱单数量
      deliverypackinglist: [],//配送单单号列表
      packinglistdetails: {
        receive: {}
      },
      LogisticsInfo: [{
        information: '', logistics_info: {
          name: ''
        }
      }],//物流信息
      Packinginformation: [],// 装箱信息
      oneGoodsOptionTotalPrice: 0, //一条商品的附加服务总价
      checkItem: {}, //记录正在操作的列表
      quantityOfCommodity: 0, //商品数量
      couponChecked: false, // 是否使用优惠劵
      client_remark: '', //订单特殊要求备注
      placingSuccessfully: false, //是否显示成功提出订单页面
      Switchlogistics: '', //物流id
      activeNames: ['1'],
      ShippingOrderInformation: [], // 配送单
      activeName: 'order',
      zeroInventoryGoodsDialogVisible: false,
      goodsPriceUpdateDetailDialogVisible: false, // 资金变动明细弹窗
      wuLiuOptions: [], //物流列表

    }
  },
  created() {
    // 判断是购物车还是订单列表跳转的页面
    // 状态赋值 ，后面调用详情接口会更新
    this.orderStatus = this.$route.query.type
    this.pageLoad()
    this.getwuLiu()

  },
  components: {
    HomePageTop,
    SpecialNoteVue,
    addGoods,
    SustituciónDeBienes,
    RAKUMARTOrderValue,
    Coupons,
    ModeOofTransportation,
    goodsPriceUpdateDetail,
    shippingInformation,
    placingSuccessfullyPage,
  },
  mounted() { },
  // 计算顺序属性
  computed: {
    // 计算店铺数量、产品数量和附加服务明细
    numberOfStores() {
      let stores = 0
      let goods = 0
      let useOption = []
      // 假设 newList 是一个数组
      for (const store of this.newList) {
        stores++
        for (const listEntry of store.list) {
          goods++
          // 使用 map 函数将 option 数组转换为 useOption 需要的格式
          useOption.push(
            ...listEntry.option.map(({ name, num }) => ({ name, num }))
          )
        }
      }
      let optionList = JSON.parse(JSON.stringify(this.optionList))
      optionList.forEach((item) => {
        item.num = 0
        useOption.forEach((btem) => {
          if (item.name == btem.name) {
            item.num = Number(item.num) + Number(btem.num)
          }
        })
        item.total = this.$fun.ceil(item.num * item.price)
      })
      // 不显示为0的附加服务
      optionList = optionList.filter((item) => item.num !== 0)
      return {
        stores: stores,
        goods: goods,
        useOption: optionList,
      }
    },
  },
  methods: {
    pageLoad() {
      // 判断是购物车还是订单列表跳转的页面
      if (this.$route.query.id) {
        this.orderStatus = 'temporary'
        // 调用预览接口
        this.newOrder()
      } else if (this.$route.query.order_sn) {
        // 如果有订单编号就调用详情接口
        this.getData(this.$route.query.order_sn)
      }
      // 获取附加服务附加服务列表
      this.getOptionList()
    }, // 获取附加服务列表
    getOptionList() {
      this.$api.optionList().then((res) => {
        this.optionList = res.data
      })
    },
    // 订单预览
    newOrder(ids) {
      this.loading = true
      // 判断是购物车来的，还是商品库来的
      if (ids) {
        var datas = {
          cat_id: ids,
        }
      } else {
        // 商品库提出订单
        if (this.$route.query.iscomm) {
          var datas = {
            inventory_id: this.$route.query.id,
          }
        } else {
          var datas = {
            cat_id: this.$route.query.id,
          }
        }
      }
      if (this.$route.query.iscomm) {
        this.ids = datas.inventory_id.split(',')
      } else {
        this.ids = datas.cat_id.split(',')
      }

      this.$api.orderPreview(datas).then((res) => {
        this.datas = res.data
        this.newList = res.data.detail
        // 如果有物流
        if (res.data.logistics) {
          this.logistics = res.data.logistics
        }
        this.loading = false
        this.orderPriceGroup = res.data.order_money_group

        this.dataProcessing()
        // 计算滚动盒子的高度
        this.$nextTick(() => {
          this.Scrollinganimation()
        })
      })
    },
    waybill(row) {

      if (row.logistics_info.url) {
        if (Number(row.logistics_info.is_inquire) == 1) {
          window.open(row.logistics_info.url + row.express_no)
        } else {
          window.open(row.logistics_info.url)
        }

      }



    },
    //点击产品详情
    clickGoodsDetails(item) {
      this.$fun.toCommodityDetails({
        goods_id: item.goods_id,
        shop_type: item.shop_type,
      })
    },
    // 通过订单号获取数据
    getData(order_sn) {
      this.loading = true
      this.$api
        .orderDetail({
          order_sn: order_sn,
        })
        .then((res) => {
          // 如果状态是待报价和接口返回的edit是true就跳到修改订单页面
          this.loading = false
          // 数据赋值
          this.isedit = res.data.edit
          this.datas = res.data
          if (res.data.orderOfferLogistics?.length > 0) {
            this.Switchlogistics = res.data.orderOfferLogistics[0].id
          }

          // 配送单
          this.ShippingOrderInformation = res.data.porder_detail
          if (res.data.logistics) {
            // 如果有物流
            this.logistics = res.data.logistics
          }

          this.orderPriceGroup = res.data.order_money_group
          this.client_remark = res.data.client_remark
          this.orderStatus = res.data.order_status
          this.status_name = res.data.status_name
          if (
            [
              'purchase',
              'purchased',
              'warehouse',
              'shelves',
              'shipping',
              'In transit',
              'Delivered',
            ].indexOf(this.orderStatus) != -1
          ) {
            //说明是付款后 然后在判断是不是有配送单
            this.packinglistNum = res.data.packinglistNum


            if (res.data.packinglistNum >= 1) {
              this.ispayafter = true
              // 获取配送单的packinglist
              res.data.packinglist.forEach((i, index) => {
                this.deliverypackinglist.push({
                  porder_sn: i,
                  checked: index == 0 ? true : false
                })
              })
              this.packingList(this.deliverypackinglist[0].porder_sn)
              this.getLogisticsInfo(this.deliverypackinglist[0].porder_sn)
              this.getPackingInfo(this.deliverypackinglist[0].porder_sn)
              this.porder_sn = this.deliverypackinglist[0].porder_sn
            }



          }
          // 循环添加FALSE 控制商品每一项底部option 显示
          res.data.detail.forEach((item) => {
            item.list.forEach((i) => {
              i.type = true
              i.len = 1
            })
          })

          // 如果是代付款状态
          if (this.orderStatus == 'obliggation') {
            // 红色状态
            let redstate = []
            // 蓝色状态
            let bluesate = []
            // 正常状态
            let state = []
            res.data.detail.forEach((item) => {
              item.list.forEach((i) => {
                if (i.confirm_num === 0 && i.order_detail_replace.length == 0) {
                  redstate.push(i)
                } else if (i.order_detail_replace.length !== 0) {
                  bluesate.push(i)
                } else if (
                  i.order_detail_replace.length == 0 &&
                  i.confirm_num > 0
                ) {
                  state.push(i)
                }
              })
              item.list = [...redstate, ...bluesate, ...state]
              redstate = []
              bluesate = []
              state = []
            })
            //替换链接数组
            let chainingarray = []
            let newdetail = []
            let arr = []
            res.data.detail.forEach((item) => {
              for (let i = 0; i < item.list.length; i++) {
                if (
                  item.list[i].confirm_num == 0 &&
                  item.list[i].order_detail_replace.length == 0
                ) {
                  // 库存不足
                  newdetail.push(item)
                  return
                } else if (item.list[i].order_detail_replace.length > 0) {
                  chainingarray.push(item)
                  // 替换链接
                  return
                } else if (
                  item.list[i].order_detail_replace.length == 0 &&
                  item.list[i].confirm_num > 0
                ) {
                  arr.push(item)

                  return
                }
              }
            })
            res.data.detail = [...newdetail, ...chainingarray, ...arr]
            newdetail = []
            chainingarray = []
            arr = []
          }

          this.newList = res.data.detail

          // 状态	中文	英文
          // -2	已取消	cancel
          //   - 1	已删除订单	delete
          //     10	临时保存	temporary
          // 20	订单正式提交	offer
          // 21	业务已翻译待确认	offer
          // 22	采购已确认	offer
          // 30	已报价	obliggation
          // 40	已支付待确认	Pending financial confirmation
          // 50	已支付已确认	purchase
          // 60	采购已购买完成	purchased
          // 65	上架中	shelves
          // 70	上架完成	warehouse
          // 80	出货完了	shipping

          // 付完款后，匹配只展示付款时选的物流
          if (res.data.status > 30) {
            let arr = []
            res.data.orderOfferLogistics.forEach((logisticsIt) => {
              if (logisticsIt.logistics_id === res.data.logistics) {
                arr.push(logisticsIt)
              }
            })
            res.data.orderOfferLogistics = arr
          }
          // 调用处理数据的函数
          this.dataProcessing()

          this.$nextTick(() => {
            this.Scrollinganimation()
          })
        })
    },
    // 配送单的packinglist
    async packingList(porder_sn) {


      const res = await this.$api.packinglist({
        porder_sn: porder_sn,
      })
      if (res.code !== 0) {
        this.$message.error(res.msg)
        return
      }
      this.packinglistdetails = res.data


    },

    Packingliststatus(status) {
      //       已删除 ：-1
      // 临时保存 ：10
      // 正式提交 ：20
      // 业务已翻译待装箱 ：21
      // 已装箱完成待报价 ：22
      // 已报价 ：30 待支付 ：30
      // 已支付待确认 ：40
      // 已支付已确认 ：50
      // 已订舱待回复 ：51
      // 待装柜 ：52
      // 待出货 ：53
      // 已发出 ：60
      // 已通关 ：62
      // 西班牙国内开始派送 ：64
      // 客户已签收 ： 70
      // 异常 ： -2
      let textStr = ''
      if (Number(status) == -1) {
        textStr = this.$fanyi('已删除')
      }
      if (Number(status) == 10) {
        textStr = this.$fanyi('临时保存')
      }
      if (Number(status) == 20) {
        textStr = this.$fanyi('正式提交')
      }
      if (Number(status) == 21) {
        textStr = this.$fanyi('业务已翻译待装箱')
      }
      if (Number(status) == 22) {
        textStr = this.$fanyi('已装箱完成待报价')
      }
      if (Number(status) == 30) {
        textStr = this.$fanyi('待支付')
      }
      if (Number(status) == 40) {
        textStr = this.$fanyi('已支付待确认')
      }
      if (Number(status) == 50) {
        textStr = this.$fanyi('已支付已确认')
      }
      if (Number(status) == 51) {
        textStr = this.$fanyi('已订舱待回复')
      }
      if (Number(status) == 52) {
        textStr = this.$fanyi('待装柜')
      }
      if (Number(status) == 53) {
        textStr = this.$fanyi('待出货')
      }
      if (Number(status) == 60) {
        textStr = this.$fanyi('已发出')
      }
      if (Number(status) == 62) {
        textStr = this.$fanyi('已通关')
      }
      if (Number(status) == 64) {
        textStr = this.$fanyi('美国国内配送中')
      }
      if (Number(status) == 70) {
        textStr = this.$fanyi('已签收')
      }
      if (Number(status) == -2) {
        textStr = this.$fanyi('异常')
      }


      return textStr
    },
    async getLogisticsInfo(porder_sn) {
      const res = await this.$api.getLogisticsInfo({
        porder_sn: porder_sn,
      })
      if (res.code !== 0) {
        this.$message.error(res.msg)
        return
      }
      this.LogisticsInfo = res.data
    },

    async getPackingInfo(porder_sn) {
      const res = await this.$api.getPackingInfo({
        porder_sn: porder_sn,
      })
      if (res.code !== 0) {
        this.$message.error(res.msg)
        return
      }
      this.Packinginformation = res.data
    },

    getStatusArray(status, index) {
      let ind = ['rakumart', 'in transit', 'US domestic dilivery', 'delivered'].findIndex((i) => i == status)


      if (ind !== -1) {
        if (index <= ind) {
          return true
        }
        else {
          return false
        }
      } else {
        return false
      }
    },

    packingitemclick(packingitem) {
      this.deliverypackinglist.forEach((item) => {
        item.checked = false
      })
      packingitem.checked = true
      this.porder_sn = packingitem.porder_sn
      this.packingList(packingitem.porder_sn)
      this.getLogisticsInfo(packingitem.porder_sn)
      this.getPackingInfo(packingitem.porder_sn)
      this.$forceUpdate()
    },
    // 下载表格数据 
    async Downloadpackinglist() {
      axios.post(Europe + 'download.exportPackingList', {
        porder_sn: this.porder_sn,
      }, {
        responseType: "blob", // 1.首先设置responseType对象格式为 blob:
      }).then((res) => {
        console.log(res);

        if (res.size < 200) {
          return this.$message.warning(this.$fanyi("操作失败"));
        }
        let blob = new Blob([res],
          { type: "text/html" });

        // "application/vnd.ms-excel"

        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = this.porder_sn + ".xls";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);


      }).catch((error) => {
        this.$message(error.message);
        return
      });

    },
    // 配送单支付
    pay(porder_sn) {
      this.$fun.toPage(
        '/payment?porder_sn=' +
        porder_sn +
        '&peiSong=true'
      )
    },
    // 打新新页面详情
    openorderDetails(order_sn) {
      let url = '/orderDetails?order_sn=' + order_sn
      window.open(url)
    },
    // 店铺全选
    shopAll(i) {
      if (this.newList[i].checked) {
        this.newList[i].list.forEach((item) => {
          item.checked = true
        })
      } else {
        this.newList[i].list.forEach((item) => {
          item.checked = false
        })
      }
      this.$forceUpdate()
      this.isCheckedAll()
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.newList.every((item) => item.checked == true)
      if (flag == true) {
        this.checked = true
      } else {
        this.checked = false
      }
      this.$forceUpdate()
    },
    // 点击table切换
    handleClick() { },
    shouldShowItem(item) {
      return (
        item.order_detail_replace.length > 0 &&
        this.orderStatus === 'obligation'
      )
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.newList.forEach((item, index) => {
          item.checked = true
          this.shopAll(index)
        })
      } else {
        this.newList.forEach((item, index) => {
          item.checked = false
          this.shopAll(index)
        })
      }
      this.$forceUpdate()
    },
    Usecoupons(row) {

      // 重新计算价格
      this.CouponsData = row
      if (row !== '') {
        this.couponChecked = true
      } else {
        this.couponChecked = false
      }
      this.calculateOrder()

      this.$forceUpdate()
    },
    // 单选
    radio(row) {
      let flag = row.list.every((item) => item.checked == true)
      if (flag) {
        row.checked = true
      } else {
        row.checked = false
      }
      this.isCheckedAll()
      this.$forceUpdate()
    },
    dataProcessing() {
      this.sorting = 1
      let quantityOfCommodity = 0

      this.newList.forEach((category) => {
        category.list.forEach((item) => {
          if (item.num === 0) {
            item.num = 1
          }
          item.optionSelectStatus = false
          item.showOption = false
          if (this.orderStatus === 'temporary') {
            item.sorting = this.sorting
          }
          this.sorting++

          // 计算总价
          let priceMultiplier =
            item.confirm_num && item.confirm_num > 0
              ? item.confirm_num
              : item.num
          let priceToUse =
            item.confirm_num && item.confirm_num > 0
              ? item.confirm_price
              : item.price
          item.totalPrice = Number(this.$fun.ceil(priceToUse * priceMultiplier))

          // 处理规格数据
          if (item.specification) {
            let specificationType = typeof item.specification

            if (specificationType !== 'object') {
              console.log(item.specification)

              item.pageSpecification = JSON.parse(item.specification)
              item.pageSpecificationChinese = JSON.parse(
                item.specificationChinese
              )
            } else {
              item.pageSpecification = item.specification
              item.pageSpecificationChinese = item.specificationChinese
            }

            item.pageSpecificationChinese = Object.keys(
              item.pageSpecificationChinese
            ).map((key) => ({
              key: key.substr(1, key.length - 2),
              value: item.pageSpecificationChinese[key],
              active: 0,
            }))

            item.pageSpecification = Object.keys(item.pageSpecification).map(
              (key) => {
                let enshuXing = item.pageSpecification[key]
                let active = 0
                item.detail.forEach((xuanZhongitem) => {
                  enshuXing.forEach((enshuXingItem, index) => {
                    if (enshuXingItem.name === xuanZhongitem.value) {
                      active = index
                    }
                  })
                })
                return {
                  key: key.substr(1, key.length - 2),
                  value: enshuXing,
                  active: active,
                }
              }
            )
          }
          item.checked = this.checked

          // 计算商品数量
          if (
            ['temporary', 'editing', 'offer', 'delete', 'cancel'].includes(
              this.orderStatus
            )
          ) {
            quantityOfCommodity += item.num
          } else if (
            ['obligation', 'Pending financial confirmation'].includes(
              this.orderStatus
            )
          ) {
            // 调查
            quantityOfCommodity += item.confirm_num
          } else {
            // 实际购买 purchase purchased shelves warehouse shipping
            quantityOfCommodity += item.purchase_num
          }

          if (!item.option) {
            item.option = []
          }
        })
      })

      this.quantityOfCommodity = quantityOfCommodity
    },
    //展示优惠券列表
    showCouponList() {
      this.couponChecked = true
      if (this.CouponsData.id !== '') {
        this.$refs.CouponDialogRef.open('order', '', this.CouponsData)
      } else {
        this.$refs.CouponDialogRef.open('order')
      }
    },
    // 根据传入的匹配物流
    Selectivelogistics(id, index) {
      this.Switchlogistics = id
      this.ind = index
    },
    // 商品详情选择，传入参数：已选中的选项，选择的选项,单条商品数据
    goodsDetailChoice(
      detail,
      checkedDetail,
      oneGoods,
      goodsGuiGeItem,
      actIndex,
      goodsGuiGeIndex
    ) {
      // 想办法拿到选中的先进行判断
      let oneGoodscheckeddetail = JSON.parse(JSON.stringify(oneGoods.detail))
      oneGoodscheckeddetail[goodsGuiGeIndex].value = checkedDetail.name
      // 然后再修改复制出来这一份的进行判断
      let str = []
      oneGoodscheckeddetail.forEach((item) => {
        str.push(item.value)
      })
      str = str.join('㊖㊎')

      // 符号条件的一样
      let data = ''
      // 循环遍历对象寻找
      if (typeof oneGoods.goods_inventory != 'object') {
        var goods_inventory = JSON.parse(oneGoods.goods_inventory)
      } else {
        var goods_inventory = oneGoods.goods_inventory
      }
      goods_inventory.forEach((item) => {
        if (item.keyC == str) {
          data = item
        }
      })
      let throttling = true
      // 找的到选中的sku
      if (data !== '') {
        goodsGuiGeItem.active = actIndex
        if (checkedDetail.picUrl) {
          oneGoods.pic = checkedDetail.picUrl
        }
        detail.value = checkedDetail.name
        data.valueC.forEach((ind) => {
          if (
            oneGoods.num >= ind.startQuantity && ind.startQuantity_last !== null
              ? oneGoods.num < ind.startQuantity_last
              : true
          ) {
            if (throttling) {
              oneGoods.price = ind.price
              oneGoods.sku_id = ind.skuId
              oneGoods.spec_id = ind.specId
              throttling = false
            }
          }
        })
        // 计算总价
        oneGoods.totalPrice = Number(
          this.$fun.ceil(oneGoods.num * oneGoods.price)
        )
        this.calculateOrder()
        this.$forceUpdate()
      } else {
        // 找不到也可以修改，取消限制，后台采购报价时会提示，并且填写替换链接
        goodsGuiGeItem.active = actIndex
        if (checkedDetail.picUrl) {
          oneGoods.pic = checkedDetail.picUrl
        }
        detail.value = checkedDetail.name
        // 计算总价
        oneGoods.totalPrice = Number(
          this.$fun.ceil(oneGoods.num * oneGoods.price)
        )
        this.calculateOrder()
        this.$forceUpdate()
      }
    },
    // 获取变更明细
    async getdetails(id) {
      const { data } = await this.$api.orderChangeDetails({
        order_detail_id: id,
      })

      this.$refs.Changedetails.Changedetail = data
    },
    getOrderPropertyKey() {
      const orderStatusMap = {
        temporary: 'num',
        offer: 'num',
        delete: 'num',
        editing: 'num',
        obligation: 'confirm_num',
        purchased: 'purchase_num', // 修正了这里的双引号问题
        purchase: 'purchase_num',
        shelves: 'purchase_num',
        warehouse: 'purchase_num',
        default: 'num',
      }
      return orderStatusMap[this.orderStatus] || orderStatusMap.default
    },
    filteredOptions(options) {
      // 确保 options 存在，否则返回空数组
      const optionlist = options || []
      return optionlist.filter(
        (eeitem) =>
          typeof eeitem !== 'string' && eeitem.checked && eeitem.num > 0
      )
    },
    // 计算走总价
    getTotalPrice(item) {
      // 去匹配价格区间
      let str = []
      item.detail.forEach((item) => {
        str.push(item.value)
      })
      str = str.join('㊖㊎')
      // 符号条件的一样
      let data = ''
      // 循环遍历对象寻找
      if (typeof item.goods_inventory != 'object') {
        var goods_inventory = JSON.parse(item.goods_inventory)
      } else {
        var goods_inventory = item.goods_inventory
      }
      goods_inventory.forEach((ite) => {
        if (ite.keyC == str) {
          data = ite
        }
      })
      let throttling = true
      // 找的到选中的sku
      if (data !== '') {
        data.valueC.forEach((ind) => {
          if (
            item.num >= ind.startQuantity && ind.startQuantity_last !== null
              ? item.num < ind.startQuantity_last
              : true
          ) {
            if (throttling) {
              item.price = ind.price
              item.sku_id = ind.skuId
              item.spec_id = ind.specId
              throttling = false
            }
          }
        })
        // 计算总价
        item.totalPrice = Number(
          (item.totalPrice = this.$fun.ceil(
            Number(item.price) * Number(item.num)
          ))
        )
        // 如果有人工报价价格和人工报价数量，就使用人工报价和人工报价数量计算总价
        if (item.confirm_num && item.confirm_num > 0) {
          // 计算总价时防止出现小数位过多情况限制最多显示三位小数
          item.totalPrice = this.$fun.ceil(
            item.confirm_price * item.confirm_num
          )
        }
        this.calculateOrder()
        this.$forceUpdate()
      } else {
        // 计算总价
        item.totalPrice = Number(
          (item.totalPrice = this.$fun.ceil(
            Number(item.price) * Number(item.num)
          ))
        )
      }
    },
    calculateOrder() {
      this.quantityOfCommodity = 0
      let datas = []

      // 假设 this.newList 是一个包含多个商品的数组，每个商品有一个 list 属性
      this.newList.forEach((group) => {
        group.list.forEach((item) => {
          if (item.option === '') {
            item.option = []
          }
          if (this.orderStatus == 'Pending financial confirmation') {
            this.quantityOfCommodity += item.confirm_num
          } else {
            const propertyKey = this.getOrderPropertyKey()
            this.quantityOfCommodity += item[propertyKey]
          }

          datas.push(item)
        })
      })

      datas = JSON.stringify(datas)

      this.$api
        .calculateOrder({ data: datas, coupon_ids: this.CouponsData.id })
        .then((res) => {
          if (res.code !== 0) {
            this.youHuiQuanShiYong = false
            this.CouponsData.id = ''
            return this.$message.error(this.$fanyi(res.msg))
          }
          // 处理成功的情况（如果需要）
          this.orderPriceGroup = res.data
        })
        .catch((error) => {
          // 处理API调用失败的情况
          this.loading = false
          this.$message.error('请求失败')
        })
    }, // 追加商品,传入参数：添加的商品数据
    addTo(addGoodsData) {
      // 如果是待支付状态的订单，就把追加商品的价格转换成报价价格字段
      if (this.oldobligation == 'obligation') {
        addGoodsData.newList.forEach((shopitem) => {
          shopitem.list.forEach((goodsitem) => {
            // 从商品库添加的商品如果数量为0就把他改为1
            if (goodsitem.num == 0) {
              goodsitem.num = 1
            }
            goodsitem.confirm_price = goodsitem.price
            goodsitem.confirm_num = goodsitem.num
          })
        })
      }
      this.addToDataProcessing(addGoodsData)
    },
    // 追加商品数据处理
    addToDataProcessing(addData) {
      // 将追加的商品数据放入当前列表
      addData.newList.forEach((item) => {
        // 判断是否是同一店铺，是的话将商品加入同一店铺下,因为不同种类id不同所以不用判断种类
        let isSame = false
        this.newList.forEach((jtem) => {
          if (item.shop_name == jtem.shop_name) {
            item.list.forEach((etem) => {
              jtem.list.push(etem)
              isSame = true
            })
          }
        })
        if (isSame == false) {
          this.newList.push(item)
        }
      })
      this.$forceUpdate()

      this.calculateOrder()
      this.dataProcessing()
      // 页面更新完然后判断高度
      this.$nextTick(() => {
        this.Scrollinganimation('delete')
      })
    },
    // 设置附加服务-重置

    optionReact(item) {
      item.option_old.forEach((i) => {
        i.checked = false
        i.num = 0
        delete i.fba
      })
      this.oneGoodsOptionTotalPrice = 0
    }, // 设置附加服务-保存选择的附加服务
    saveOrderOption(item, index) {
      if (item.option_old.every((ite) => ite.checked == false)) {
        item.option = []
      } else {
        item.option = JSON.parse(JSON.stringify(item.option_old))
      }

      this.calculateOrder()
      // 用索引查找追加商品后会有问题，点击全部关闭
      console.log(this.$refs['popoverref' + index])
      this.$refs['popoverref' + index].forEach((item) => {
        item.doClose()
      })

      this.$forceUpdate()
    },
    // 获取物流方式列表
    getwuLiu() {
      this.$api.internationalLogisticsTransportation().then((res) => {
        if (res.code != 0) return
        this.wuLiuOptions = res.data
        // 物流名称默认使用第一个
        this.logistics = res.data[0].name
      })
    },
    // 选中附加服务时将商品数量填进附加服务数量
    setOptionsNum(optionItem, goodsNum) {
      if (optionItem.checked) {
        if (optionItem.name == '摄影') {
          optionItem.num = 1
        } else {
          optionItem.num = goodsNum
        }
      } else {
        optionItem.num = 0
      }
    },
    setOrderOption(item, index) {
      // 如果是修改订单 打开弹窗
      if (this.orderStatus == 'editing') {
        this.$refs['popoverref' + index][0].doShow()
      }

      // 初始化数
      this.checkItem = item

      if (item.option && item.option.length > 0) {
        let optionlist = JSON.parse(JSON.stringify(item.option))
        item.option_old = optionlist
      }
      this.setOneGoodsOptionTotalPrice(item)

      // 判断如果是重置，但是选中的有就把对应的数据该回去
      this.$forceUpdate()
    },
    // 追加商品
    addGoodsbtn() {
      if (this.numberOfStores.goods >= 60) {
        return this.$message.warning(
          this.$fanyi('单个订单不能超过60番产品，请调整提出番数')
        )
      }
      this.showCart = !this.showCart
    },

    // 删除商品
    delOrder() {
      if (this.checked) {
        this.newList = []
        this.checked = false
        this.dataProcessing()
        // 提交后端计算价格
        this.calculateOrder()
        this.$nextTick(() => {
          this.Scrollinganimation('delete')
        })

        this.$message({
          message: this.$fanyi('删除成功'),
          type: 'success',
        })
        return
      }

      // 首先检查是否至少有一个商品被选中
      if (
        !this.newList.some((item) =>
          item.list.some((product) => product.checked)
        )
      ) {
        this.$message(this.$fanyi('请至少选择一个商品'))
        return
      }
      // 使用filter()方法来移除选中的商品
      const filteredItems = this.newList.map((item) => {
        const filteredList = item.list.filter((product) => !product.checked)
        return { ...item, list: filteredList }
      })

      // 再次过滤，移除那些没有商品的店铺
      const filteredShops = filteredItems.filter((item) => item.list.length > 0)

      // 更新newList
      this.newList = filteredShops

      // 执行数据处理和订单计算
      this.dataProcessing()
      this.calculateOrder()

      this.$nextTick(() => {
        this.Scrollinganimation('delete')
      })

      // 显示成功消息
      this.$message({
        message: this.$fanyi('删除成功'),
        type: 'success',
      })
      this.$forceUpdate()
    },
    // 加入商品库
    addLibraryOfGoods() {
      // 循环查找已选中的商品
      let chechkedGoods = []
      this.newList.forEach((newListItem) => {
        const { shop_id, shop_name } = newListItem

        newListItem.list.forEach((item) => {
          if (item.checked) {
            const detailChinese = item.detail.map((_, index) => ({
              key: item.pageSpecificationChinese[index].key,
              value:
                item.pageSpecificationChinese[index].value[
                  item.pageSpecification[index].active
                ].name,
            }))
            const specificationChecked = item.pageSpecification.map(
              (spec, index) => ({
                key: item.pageSpecificationChinese[index].key,
                value:
                  item.pageSpecificationChinese[index].value[spec.active].name,
              })
            )
            chechkedGoods.push({
              shop_type: 1688,
              goods_id: item.goods_id,
              goods_title: item.goods_title,
              shop_id,
              shop_name,
              img_url: item.pic,
              price_range: item.price_range,
              detailChinese,
              specification_checked: specificationChecked,
              option: JSON.stringify(item.option),
            })
          }
        })
      })
      if (chechkedGoods.length == 0) {
        return this.$message(this.$fanyi('请至少选择一个商品'))
      }
      this.$api
        .favoriteInventoryBatchSave({
          data: JSON.stringify(chechkedGoods),
        })
        .then((res) => {
          if (res.code != 0) return
          this.$message.success(this.$fanyi('加入商品库成功'))
        })
    },
    // 计算单条商品订单的附加服务费用(只计算选中的附加服务)
    setOneGoodsOptionTotalPrice(item) {
      // 进行判断，如果除了摄影和配套，其他的附加服务的数量大于购买数量，那就让他等于购买数量
      let sum = 0
      item.option_old.forEach((tt) => {
        if (tt.checked == true) {
          sum += tt.num * tt.price
        }
      })
      this.oneGoodsOptionTotalPrice = sum
      this.$forceUpdate()
    },
    // 提交或临时保存
    OrdersSubmitted(type) {
      if (this.newList.length == 0) {
        return this.$message(this.$fanyi('订单里请至少添加一款商品'))
      } else if (this.newList.length > 60) {
        return this.$message(
          this.$fanyi('单个订单不能超过60番产品，请调整提出番数')
        )
      }
      let dataList = JSON.parse(JSON.stringify(this.datas))

      this.newList.forEach((listItem) => {
        listItem.list.forEach((Item) => {
          // 将用户选中的规格匹配中文传回后台 pageSpecification pageSpecificationChinese
          Item.detailChinese = ((
            detailEnglish,
            pageSpecification,
            pageSpecificationChinese
          ) => {
            let detailChinese = []
            detailEnglish.forEach((item, index) => {
              detailChinese.push({
                key: pageSpecificationChinese[index].key,
                value:
                  pageSpecificationChinese[index].value[
                    pageSpecification[index].active
                  ].name,
              })
            })
            return detailChinese
          })(Item.detail, Item.pageSpecification, Item.pageSpecificationChinese)

          if (Item.option == '') {
            Item.option = []
          }
          if (typeof Item.price_range == 'object') {
            Item.price_range = JSON.stringify(Item.price_range)
          }
          if (typeof Item.goods_inventory == 'object') {
            Item.goods_inventory = JSON.stringify(Item.goods_inventory)
          }
          if (typeof Item.specification == 'object') {
            Item.specification = JSON.stringify(Item.specification)
          }
        })
      })
      dataList.detail = this.newList
      dataList.logistics = this.logistics
      let datas = {
        status: type,
        data: JSON.stringify(dataList),
        client_remark: this.client_remark,
      }
      console.log(datas)
      this.$api.orderCreate(datas).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
        // 假如点击的是临时保存按钮
        if (type == 10) {
          this.$fun.toPage('/user/order?status=temporary')
        }
        // 假如点击的是提出按钮
        if (type == 20) {
          // 进行判断埋点
          if (this.$fun.isOfficialWeb()) {
            this.newList.forEach((item) => {
              item.list.forEach((ite) => {
                if (ite.trace) {
                  if (ite.trace == 'linkSearch') {
                    __bl.sum(
                      '通过链接搜索进入商品详情且加入购物车提出订单数量统计'
                    )
                  }
                  if (ite.trace == 'keywordSearch') {
                    __bl.sum(
                      '通过关键词搜索进入商品详情且加入购物车提出订单数量统计'
                    )
                  }
                  if (ite.trace == 'imgSearch') {
                    __bl.sum(
                      '通过图片搜索进入商品详情且加入购物车提出订单数量统计'
                    )
                  }
                  if (ite.trace == 'classificationSearch') {
                    __bl.sum(
                      '通过分类搜索进入商品详情且加入购物车提出订单数量统计'
                    )
                  }
                  if (ite.trace == 'taobaolinkSearch') {
                    __bl.sum(
                      '通过淘宝链接搜索进入商品详情且加入购物车提出订单数量统计'
                    )
                  }
                  if (ite.trace == '1688linkSearch') {
                    __bl.sum(
                      '通过1688链接搜索进入商品详情且加入购物车提出订单数量统计'
                    )
                  }
                  if (ite.trace == 'tmalllinkSearch') {
                    __bl.sum(
                      '通过天猫链接搜索进入商品详情且加入购物车提出订单数量统计'
                    )
                  }
                  if (ite.trace == 'rakumartlinkSearch') {
                    __bl.sum(
                      '通过rakumart链接搜索进入商品详情且加入购物车提出订单数量统计'
                    )
                  }
                }
              })
            })
          }
          this.placingSuccessfully = true
          this.$nextTick(() => {
            this.$refs.Proposesuccessfully.getBuyGoodsArr()
          })
          this.$store.dispatch('sidebarNumList')
        }
      })
    },
    //删除订单
    delObligationOrder() {
      this.$api
        .orderDelete({
          order_sn: this.$route.query.order_sn,
        })
        .then((res) => {
          if (res.code != 0) return
          this.zeroInventoryGoodsDialogVisible = false
          this.$fun.toPage('/user/order?status=all')
        })
    },
    Scrollinganimation(text) {
      var element = document.getElementById('myElementGoodslist')
      var elementTable = document.getElementById('myElementtablebox')
      if (!element) return
      var originalHeight = element.offsetHeight + 110 // 获取原始高度

      if (text == 'delete') {
        if (this.newList.length > 0) {
          // 动画开始时设置高度
          setTimeout(function () {
            elementTable.style.transition = 'height 0.5s ease'
            elementTable.style.height = originalHeight + 'px' // 恢复到原始高度
          }, 0)
        } else {
          // 动画开始时设置高度
          setTimeout(function () {
            elementTable.style.transition = 'height 0.5s ease'
            elementTable.style.height = '436' + 'px' // 恢复到原始高度
          }, 0)
        }
      } else {
        this.showall = !this.showall
        if (this.showall == false) {
          // 动画开始时设置高度
          setTimeout(function () {
            elementTable.style.transition = 'height 0.5s ease'
            elementTable.style.height = '436' + 'px' // 恢复到原始高度
          }, 0)
        } else {
          // 动画开始时设置高度
          setTimeout(function () {
            elementTable.style.transition = 'height 0.5s ease'
            elementTable.style.height = originalHeight + 'px' // 恢复到原始高度
          }, 0)
        }
      }
    },

    shoWmaintable(blob, row) {
      if (blob) {
        row.showClose = true
      } else {

        row.showClose = false
      }
      this.$forceUpdate()
    },

    headerclick(row) {
      this.showClose = !this.showClose
      this.$forceUpdate()
    },
    collapsechange(val) {

    },
    //关闭商品金额变更明细弹窗
    cloneGoodsPriceUpdateDetailDialogVisible(msg) {
      this.goodsPriceUpdateDetailDialogVisible = msg

    },

    // 跳转到付款页面
    payMent() {
      if (this.$refs.odeOofTrans.userActOpt == -1) {
        return this.$message.warning(this.$fanyi('请至少选择一项物流方式'))
      }
      // 修改物流方式成功之后跳转，否则弹出错误信息
      this.$api
        .updateOrderLogistics({
          order_sn: this.$route.query.order_sn,
          logistics: this.datas.orderOfferLogistics[
            this.$refs.odeOofTrans.userActOpt
          ].logistics.id,
        })
        .then((res) => {
          if (res.code != 0) return this.$message.error(res.msg)
          let urlStr = '?order_sn=' + this.$route.query.order_sn
          this.$api
            .confirmLibrary({
              order_sn: this.$route.query.order_sn,
            })
            .then((res) => {
              if (!res.success) {
                this.zeroInventoryGoodsDialogVisible = true
                return
              }
              if (this.CouponsData.id) {
                urlStr += '&coupon_ids=' + this.CouponsData.id
              }
              this.$fun.toPage('/payment' + urlStr)
              setTimeout(() => {
                this.$bus.emit('parameterName', this.newList)
              }, 100)
            })
        })
    },
    // 重新提出
    resubmit() {
      this.datas.detail.forEach((listItem) => {
        listItem.list.forEach((Item) => {
          // 将用户选中的规格匹配中文传回后台 pageSpecification pageSpecificationChinese
          Item.detailChinese = ((
            detailEnglish,
            pageSpecification,
            pageSpecificationChinese
          ) => {
            let detailChinese = []
            detailEnglish.forEach((item, index) => {
              detailChinese.push({
                key: pageSpecificationChinese[index].key,
                value:
                  pageSpecificationChinese[index].value[
                    pageSpecification[index].active
                  ].name,
              })
            })
            return detailChinese
          })(Item.detail, Item.pageSpecification, Item.pageSpecificationChinese)
        })
      })

      this.$api.orderEdit({ data: JSON.stringify(this.datas) }).then((res) => {
        if (res.code == 0) {
          this.$fun.toPage('/user/order?status=all')
        }
      })
    },
    // 修改订单
    editorder() {
      this.oldobligation = this.orderStatus
      this.orderStatus = 'editing'
      this.isedit = false
      this.zeroInventoryGoodsDialogVisible = false
      this.calculateOrder()
      this.dataProcessing()
    },
  },
}
</script>
<style lang="scss">
.nooptionList {
  padding: 0px !important;
}

.zeroInventoryGoodsDialog {
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0px;
  }

  .zeroInventoryGoodsDialogVisibleHeader {
    height: 60px;
    padding: 0 20px;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;

    div {
      color: #222222;
      font-size: 18px;
      font-weight: 600;
    }

    img {
      width: 14px;
      height: 14px;
    }
  }

  .zeroInventoryGoodsDialogVisibleCenter {
    display: flex;
    padding: 30px;

    img {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      margin-right: 8px;
    }

    div {
      color: #222222;
      margin-top: -4px;
    }
  }

  .zeroInventoryGoodsDialogVisibleFooter {
    justify-content: space-between;
    padding: 0 60px 30px;

    div {
      width: 120px;
      height: 40px;
      border: 1px solid #e2e2e2;
      cursor: pointer;
      text-align: center;
      line-height: 40px;
      background-color: #fff;
      color: #222222;
      border-radius: 4px !important;
    }

    div:first-child {
      margin-right: 40px;
    }

    div:last-child {
      color: #ffffff;
      border: 1px solid #ff730b;
      background-color: #ff730b;
    }
  }
}

.addGoodsdialog {
  border-radius: 10px;

  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
  }
}

.Changedetailsdialog {
  width: 1400px !important;

  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e2e2e2;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0px;
  }
}
</style>
<style lang="scss" scoped>
@import '../../../../../css/mixin.scss';

/deep/ .el-dialog__footer {
  padding: 0 30px 30px;
}

.newOrderDetails {
  .OrderDetailscontent {
    padding-top: 20px;
    background: #f6f6f6;
    min-height: 88vh;
    padding-bottom: 128px;

    // 报价前
    .ordermain {
      margin: auto;
      width: 1400px;

      .content {
        background: #ffffff;
        padding: 20px;
        border-radius: 6px;
        padding-bottom: 0px;
        position: relative;

        .headerOrder {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .order_sn {
            font-weight: 500;
            font-size: 18px;
            color: #222222;
            line-height: 28px;
            text-align: left;
          }

          .right_time {
            display: flex;
            align-items: center;

            .time {
              font-size: 14px;
              color: #666666;
              line-height: 20px;
              text-align: center;
            }

            .editOrder {
              min-width: 120px;
              height: 32px;
              margin-left: 38px;
              padding: 0 17px;
              background: #ff730b;
              display: flex;
              font-size: 14px;
              color: #ffffff;
              align-items: center;
              justify-content: center;
              border-radius: 4px;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }

        .tablebox {
          width: 100%;
          min-height: 436px;
          transition: all 0.8s ease-in-out;
          overflow: hidden;

          .tableheader {
            width: 1360px;
            height: 60px;
            background: #f6f6f6;
            border-radius: 6px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            >div {
              display: flex;
              align-items: center;
              font-weight: normal;
              font-size: 14px;
              height: 100%;
              flex-wrap: wrap;
              text-align: center;

              color: #222222;
              justify-content: center;
            }

            .headCheckBox {
              flex: 0 0 168px;
              justify-content: flex-start;

              .CheckBox {
                width: 20px;
                height: 20px;
                border-radius: 4px;
                margin-right: 10px;

                &:hover {
                  cursor: pointer;
                }
              }

              input[type='checkbox'] {
                background-color: #fff;
                -webkit-appearance: none;
                border: 1px solid #c0c4cc;
                border-radius: 4px;
                outline: none;
              }

              input[type='checkbox']:checked {
                background: #ff730b;

                border: 1px solid #ff730b;

                &::after {
                  height: 4px;
                  width: 8px;
                }
              }
            }

            .goods {
              flex: 0 0 180px;
            }

            .detail {
              flex: 0 0 180px;
            }

            .price {
              flex: 0 0 128px;
            }

            .num {
              flex: 0 0 120px;
            }

            .totalprice {
              flex: 0 0 120px;
            }

            .options {
              flex: 0 0 160px;
            }

            .remark {
              flex: 0 0 160px;
            }
          }

          .tablebody {
            .shopsitem {
              border-bottom: 1px dashed #e2e2e2;

              .shopheader {
                display: flex;
                padding: 0 20px;

                h1 {
                  display: flex;
                  font-weight: normal;
                  font-size: 14px;
                  color: #121212;
                  margin-top: 10px;
                  font-weight: 500;
                  margin-bottom: 10px;

                  .checkedBox {
                    margin-right: 10px;

                    .shopchecked {
                      &:hover {
                        cursor: pointer;
                      }
                    }
                  }

                  input[type='checkbox'] {
                    background-color: #fff;
                    -webkit-appearance: none;
                    border: 1px solid #c0c4cc;
                    border-radius: 4px;
                    outline: none;
                  }

                  input[type='checkbox']:checked {
                    background: #ff730b;

                    border: 1px solid #ff730b;

                    &::after {
                      height: 4px;
                      width: 8px;
                    }
                  }

                  .img1688box {
                    margin-left: 12px;
                  }
                }
              }

              .shopbody {
                padding-bottom: 20px;

                .goodsitem {
                  padding: 10px 20px;
                  min-height: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  >div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                  }

                  .goodsheadCheckBox {
                    flex: 0 0 170px;
                    display: flex;
                    justify-content: space-between;

                    .goodscheckedBox {
                      margin-right: 5px;

                      &:hover {
                        cursor: pointer;
                      }

                      input[type='checkbox'] {
                        background-color: #fff;
                        -webkit-appearance: none;
                        border: 1px solid #c0c4cc;
                        border-radius: 4px;
                        outline: none;

                        &:hover {
                          cursor: pointer;
                        }
                      }

                      input[type='checkbox']:checked {
                        background: #ff730b;

                        border: 1px solid #ff730b;

                        &::after {
                          height: 4px;
                          width: 8px;
                        }
                      }
                    }

                    .rowNumber {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    .goodimg {
                      width: 80px;
                      height: 80px;
                      background: #ededed;
                      border-radius: 6px;
                    }
                  }

                  .goodsproduct {
                    flex: 0 0 180px;

                    .showGoodsTitle {
                      height: 80px;
                      display: flex;

                      &:hover {
                        cursor: pointer;
                      }

                      .title {
                        height: 80px;
                        font-weight: 400;
                        font-size: 14px;
                        color: #121212;
                        line-height: 16px;
                        // 四行文本溢出
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 5;
                        overflow: hidden;
                      }
                    }
                  }

                  .goodsdetail {
                    flex: 0 0 180px;
                    display: flex;
                    justify-content: center;
                  }

                  .goodsprice {
                    flex: 0 0 128px;
                    flex-direction: column;

                    span {
                      font-weight: 400;
                      font-size: 14px;
                      color: #222222;
                      line-height: 20px;
                    }
                  }

                  .goodsnum {
                    flex: 0 0 120px;

                    /deep/.el-input-number {
                      overflow: hidden !important;
                      width: 88px;
                      height: 32px;
                      background: #ffffff;
                      border-radius: 4px;
                      border: 1px solid #222222;
                      justify-content: space-between;
                      display: flex;
                      align-items: center !important;

                      &:focus {
                        background-color: pink;
                      }
                    }

                    /deep/ .el-input__inner {
                      width: 88px;
                      height: 32px;
                      background: #ffffff;
                      border-radius: 4px;
                      border: 1px solid #222222;
                      font-weight: 400;
                      font-size: 14px;
                      padding: 0 30px;
                      color: #121212;
                      border: 1px solid #dfdfdf !important;
                    }

                    /deep/ .el-input-number__increase {
                      display: flex;
                      align-items: center;
                      width: 24px;
                      height: 24px;
                      background: #f2f2f2;
                      justify-content: center;
                      border-radius: 2px;
                      right: 4px;
                      padding-right: 3px;
                      margin-top: 2px;
                    }

                    /deep/ .el-input-number__decrease {
                      width: 24px;
                      height: 24px;
                      display: flex;
                      background: #f2f2f2;
                      border-radius: 2px;
                      justify-content: center;
                      align-items: center;
                      left: 4px;
                      margin-top: 2px;
                    }
                  }

                  /deep/.el-input-number__decrease:active,
                  /deep/.el-input-number__increase:active {
                    background-color: #ff730b;

                    i {
                      color: white;
                    }
                  }

                  .goodstotalprice {
                    flex: 0 0 120px;
                    flex-direction: column;

                    span {
                      font-size: 14px;
                      color: #ff730b;
                      line-height: 20px;
                      text-align: left;
                    }
                  }

                  .goodsoptions {
                    flex: 0 0 160px;

                    .optionInput {
                      .NOData {
                        width: 160px;

                        padding: 10px;
                        font-family: Roboto;
                        font-weight: 400;
                        font-size: 12px;
                        color: #999999;
                        line-height: 18px;
                        height: 80px;
                        background: #ffffff;
                        border-radius: 4px;
                        border: 1px solid #ededed;

                        &:hover {
                          cursor: pointer;
                        }

                        border: 1px solid #dfdfdf;
                      }

                      .optionData {
                        width: 160px;
                        padding: 10px;
                        font-family: Roboto;
                        font-weight: 400;
                        overflow: auto;
                        font-size: 13px;
                        color: #999999;
                        line-height: 16px;
                        height: 80px;
                        background: #ffffff;
                        border-radius: 4px;
                        border: 1px solid #ededed;

                        // 滚动条样式
                        &::-webkit-scrollbar {
                          width: 6px;
                        }

                        &:hover {
                          cursor: pointer;
                        }

                        border: 1px solid #dfdfdf;

                        .haveData {
                          display: flex;
                          align-items: center;
                          font-weight: 400;
                          font-size: 12px;
                          margin-bottom: 8px;
                          color: #121212; // 文字换行
                          word-break: break-all;

                          i {
                            color: #ff730b;
                            font-style: normal; // 文字换行
                          }
                        }
                      }

                      .dibsedData {
                        background: #fafafa;
                      }
                    }
                  }

                  .goodsremark {
                    flex: 0 0 160px;

                    textarea {
                      width: 160px;
                      height: 80px;
                      background: #ffffff;
                      line-height: 18px;
                      border-radius: 4px;
                      font-size: 12px;
                      border: 1px solid #ededed;
                      padding: 10px;
                    }

                    textarea::placeholder {
                      font-size: 12px;
                      color: #999999;
                      line-height: 18px;
                      /* Firefox */
                    }

                    .beizhu {
                      width: 160px;
                      height: 80px;
                      padding: 10px;
                      font-size: 12px;
                      background: #f9f9f9;
                      overflow-y: scroll;
                      border-radius: 4px;
                      border: 1px solid #e2e2e2;
                    }
                  }
                }
              }
            }
          }
        }

        // 展开更多
        .showall {
          width: 100%;
          height: 40px;
          position: absolute;
          left: 0px;
          bottom: 0px;
          z-index: 100;
          background: #ffffff;
          box-shadow: 0px 1px 8px 0px rgba(4, 0, 0, 0.1);
          padding-right: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border-radius: 0 0 6px 6px;

          &:hover {
            cursor: pointer;
          }

          span {
            font-family: Adobe Heiti Std;
            font-weight: normal;
            font-size: 14px;
            font-weight: 600;
            color: #121212;
          }

          img {
            transition: all 0.2s linear;
            width: 20px;
            height: 10px;
            margin-left: 8px;
          }

          .deg180 {
            transform: rotate(-180deg);
          }
        }
      }
    }

    // 报价后
    .obligationordermain {
      margin: auto;
      width: 1400px;

      .content {
        background: #ffffff;
        padding: 20px;
        border-radius: 6px;
        padding-bottom: 0px;
        position: relative;

        .headerOrder {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .order_sn {
            font-weight: 500;
            font-size: 18px;
            color: #222222;
            line-height: 28px;
            text-align: left;
          }

          .right_time {
            display: flex;
            align-items: center;

            .time {
              font-size: 14px;
              color: #666666;
              line-height: 20px;
              text-align: center;
            }

            .editOrder {
              min-width: 120px;
              height: 32px;
              margin-left: 38px;
              padding: 0 17px;
              background: #ff730b;
              display: flex;
              font-size: 14px;
              color: #ffffff;
              align-items: center;
              justify-content: center;
              border-radius: 4px;

              &:hover {
                cursor: pointer;
              }
            }

            .Downloadpackinglist {
              margin-left: 20px;

              height: 32px;
              padding: 0px 10px;
              border-radius: 4px;
              border: 1px solid #FF730B;
              font-weight: 400;
              font-size: 14px;
              color: #FF730B;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }

        /deep/.el-tabs {
          .el-tabs__header {
            height: 36px;
            margin-bottom: 20px;

            .el-tabs__nav-wrap {
              height: 36px;
            }
          }

          .el-tabs__nav-scroll {
            height: 36px;
          }

          .el-tabs__nav {
            height: 36px;
          }

          .el-tabs__item {
            height: 36px;
            font-size: 16px;
            color: #222222;
            line-height: 24px;
            text-align: center;
            line-height: normal;
          }

          .el-tabs__nav-wrap {
            height: 36px;
          }

          .is-active {
            font-weight: 500;
            font-size: 16px;
            color: #ff730b;
          }
        }

        .tablebox {
          width: 100%;
          min-height: 436px;
          transition: all 0.8s ease-in-out;
          overflow: hidden;

          .tableheader {
            width: 1360px;
            height: 60px;
            background: #f6f6f6;
            border-radius: 6px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            >div {
              display: flex;
              align-items: center;
              font-weight: normal;
              font-size: 14px;
              height: 100%;
              flex-wrap: wrap;
              text-align: center;

              color: #222222;
              justify-content: center;
            }

            .headCheckBox {
              flex: 0 0 170px;
              justify-content: flex-start;

              .CheckBox {
                width: 20px;
                height: 20px;
                border-radius: 4px;
                margin-right: 10px;

                &:hover {
                  cursor: pointer;
                }
              }

              input[type='checkbox'] {
                background-color: #fff;
                -webkit-appearance: none;
                border: 1px solid #c0c4cc;
                border-radius: 4px;
                outline: none;
              }

              input[type='checkbox']:checked {
                background: #ff730b;

                border: 1px solid #ff730b;

                &::after {
                  height: 4px;
                  width: 8px;
                }
              }
            }

            .goods {
              flex: 0 0 160px;
            }

            .detail {
              flex: 0 0 120px;
            }

            // 单价

            .price {
              flex: 0 0 80px;
            }

            // 提出数量

            .Proposedquantity {
              flex: 0 0 70px;
            }

            // 调查在库数量

            .Quantityinstock {
              flex: 0 0 70px;
            }

            // 中国国内所有费用
            .AllexpensesinChina {
              flex: 0 0 120px;
            }

            //  附加服务费用
            .options {
              flex: 0 0 120px;
            }

            // 备注
            .remarks {
              flex: 0 0 120px;
            }

            // 业务备注回复
            .Replytobusinessremarks {
              flex: 0 0 120px;
            }

            .Actualpurchasedquantity {
              flex: 0 0 70px;
            }

            // 商品状态
            .Commoditystate {
              flex: 0 0 70px;
            }
          }

          .tablebody {
            .shopsitem {
              border-bottom: 1px dashed #e2e2e2;

              .shopheader {
                display: flex;
                padding: 0 20px;

                h1 {
                  display: flex;
                  font-weight: normal;
                  font-size: 14px;
                  color: #121212;
                  margin-top: 10px;
                  font-weight: 500;
                  margin-bottom: 10px;

                  .checkedBox {
                    margin-right: 10px;

                    .shopchecked {
                      &:hover {
                        cursor: pointer;
                      }
                    }
                  }

                  input[type='checkbox'] {
                    background-color: #fff;
                    -webkit-appearance: none;
                    border: 1px solid #c0c4cc;
                    border-radius: 4px;
                    outline: none;
                  }

                  input[type='checkbox']:checked {
                    background: #ff730b;

                    border: 1px solid #ff730b;

                    &::after {
                      height: 4px;
                      width: 8px;
                    }
                  }

                  .img1688box {
                    margin-left: 12px;
                  }
                }
              }

              .shopbody {
                padding-bottom: 20px;

                .goodsitem {
                  padding: 10px 20px;
                  min-height: 120px;
                  display: flex;
                  align-items: center;
                  margin-bottom: 10px;
                  justify-content: space-between;

                  >div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                  }

                  .goodsheadCheckBox {
                    flex: 0 0 168px;
                    display: flex;
                    justify-content: space-between;

                    .goodscheckedBox {
                      margin-right: 5px;

                      &:hover {
                        cursor: pointer;
                      }

                      input[type='checkbox'] {
                        background-color: #fff;
                        -webkit-appearance: none;
                        border: 1px solid #c0c4cc;
                        border-radius: 4px;
                        outline: none;

                        &:hover {
                          cursor: pointer;
                        }
                      }

                      input[type='checkbox']:checked {
                        background: #ff730b;

                        border: 1px solid #ff730b;

                        &::after {
                          height: 4px;
                          width: 8px;
                        }
                      }
                    }

                    .rowNumber {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    .goodimg {
                      width: 80px;
                      height: 80px;
                      background: #ededed;
                      border-radius: 6px;
                    }
                  }

                  .goodsproduct {
                    flex: 0 0 160px;

                    .showGoodsTitle {
                      height: 80px;
                      display: flex;

                      &:hover {
                        cursor: pointer;
                      }

                      .title {
                        height: 80px;
                        font-weight: 400;
                        font-size: 14px;
                        color: #222222;
                        line-height: 20px;
                        // 四行文本溢出
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                        overflow: hidden;
                      }
                    }
                  }

                  .goodsdetail {
                    flex: 0 0 120px;
                  }

                  .goodsprice {
                    flex: 0 0 80px;
                    flex-direction: column;

                    span {
                      font-weight: 400;
                      font-size: 14px;
                      color: #222222;
                      line-height: 20px;
                    }
                  }

                  // 提出数量
                  .goodsProposedquantity {
                    flex: 0 0 70px;
                    font-size: 14px;
                    color: #222222;
                    line-height: 20px;
                  }

                  // 调查在库数量
                  .goodsQuantityinstock {
                    flex: 0 0 70px;
                    font-size: 14px;
                    color: #222222;
                    line-height: 20px;
                  }

                  // 实际采购数量
                  .goodsActualpurchasedquantity {
                    flex: 0 0 70px;
                    font-size: 14px;
                    color: #222222;
                    line-height: 20px;
                  }

                  // 中国国内所有费用
                  .goodsAllexpensesinChina {
                    flex: 0 0 120px;
                    flex-direction: column;

                    .china_amount {
                      font-size: 14px;
                      color: #222222;
                      line-height: 20px;
                      margin-bottom: 10px;
                    }

                    .mingXi {
                      width: 110px;
                      height: 34px;
                      background: #e9f8fb;
                      border-radius: 4px;
                      border: 1px solid #49c9e0;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-weight: 400;
                      font-size: 12px;
                      color: #222222;

                      &:hover {
                        cursor: pointer;
                      }

                      /deep/.el-icon-arrow-down {
                        margin-left: 5px;
                        font-weight: 600;
                      }
                    }
                  }

                  // 附加服务费用
                  .goodsoptions {
                    flex: 0 0 120px;

                    .service {
                      width: 120px;
                      height: 80px;
                      background: #f9f9f9;
                      border-radius: 4px;
                      border: 1px solid #e2e2e2;
                      padding: 6px 10px;
                      overflow-y: scroll;
                      font-weight: 400;
                      padding-right: 5px;
                      font-size: 12px;
                      color: #999999;
                      line-height: 18px;

                      // 滚动条样式
                      &::-webkit-scrollbar {
                        width: 6px;
                      }

                      .item {
                        font-weight: 400;
                        font-size: 12px;
                        color: #222222;
                        line-height: 18px;
                      }
                    }

                    .noserve {
                      width: 120px;
                      height: 80px;
                      background: #f9f9f9;
                      border-radius: 4px;
                      border: 1px solid #e2e2e2;
                      padding: 6px 10px;
                      overflow-y: scroll;
                      font-weight: 400;
                      font-size: 12px;
                      padding-right: 5px;
                      color: #999999;
                      line-height: 18px;
                    }
                  }

                  // 备注
                  .goodsremarks {
                    flex: 0 0 120px;

                    .remarksbox {
                      width: 120px;
                      height: 80px;
                      background: #f9f9f9;
                      border-radius: 4px;
                      border: 1px solid #e2e2e2;
                      padding: 6px 10px;
                      overflow-y: scroll;
                      font-weight: 400;
                      padding-right: 5px; // 滚动条样式

                      &::-webkit-scrollbar {
                        width: 6px;
                      }

                      font-weight: 400;
                      font-size: 12px;
                      color: #222222;
                      line-height: 18px;
                    }

                    .noremark {
                      width: 120px;
                      height: 80px;
                      background: #f9f9f9;
                      border-radius: 4px;
                      border: 1px solid #e2e2e2;
                      padding: 6px 10px;
                      overflow-y: scroll;
                      font-weight: 400;
                      font-size: 12px;
                      padding-right: 5px;
                      color: #999999;
                      line-height: 18px;
                    }
                  }

                  // 业务备注回复
                  .goodsReplytobusinessremarks {
                    flex: 0 0 120px;

                    .y_replybox {
                      width: 120px;
                      height: 80px;

                      border-radius: 4px;

                      padding: 6px 10px;
                      overflow-y: scroll;
                      font-weight: 400;
                      padding-right: 5px; // 滚动条样式

                      &::-webkit-scrollbar {
                        width: 6px;
                      }

                      font-weight: 400;
                      font-size: 12px;
                      color: #222222;
                      line-height: 18px;
                      background: #fdeeed;
                      border-radius: 4px;
                      border: 1px solid #f8cac5;
                    }

                    .noy_reply {
                      width: 120px;
                      height: 80px;
                      background: #f9f9f9;
                      border-radius: 4px;
                      border: 1px solid #e2e2e2;
                      padding: 6px 10px;
                      overflow-y: scroll;
                      font-weight: 400;
                      font-size: 12px;
                      padding-right: 5px;
                      color: #999999;
                      line-height: 18px;
                    }
                  }

                  // 商品状态
                  .goodsCommoditystate {
                    flex: 0 0 70px;
                    color: #222222;
                    line-height: 20px;
                    text-align: center;
                  }

                  .Viewbox {
                    flex: 0 0 588px;
                    display: flex;
                    justify-content: flex-end;

                    .ViewProducts {
                      min-width: 146px;
                      height: 34px;
                      background: #eff4ff;
                      padding: 0 15px;
                      border-radius: 4px;
                      border: 1px solid #608bff;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-weight: 400;
                      font-size: 12px;
                      color: #608bff;
                      line-height: 18px;

                      &:hover {
                        cursor: pointer;
                      }
                    }

                    .Replacementlink {
                      min-width: 146px;
                      height: 34px;

                      background: #608bff;
                      border-radius: 4px;
                      display: flex;
                      margin-left: 20px;
                      align-items: center;
                      font-weight: 400;
                      font-size: 12px;
                      color: #ffffff;
                      line-height: 18px;
                      text-align: left;
                      justify-content: center;

                      &:hover {
                        cursor: pointer;
                      }
                    }
                  }
                }

                .bordercolorred {
                  background: #fdf3f2;
                  border-radius: 6px;
                  border: 1px solid #e74c3c;
                  position: relative;

                  .unavailable {
                    min-width: 124px;
                    position: absolute;
                    top: 0px;
                    z-index: 20;
                    left: 0px;
                    color: #ffffff;
                    height: 20px;
                    background: #e74c3c;
                    border-radius: 6px 0px 6px 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 8px;
                    font-size: 12px;
                    color: #ffffff;
                  }
                }

                .bordercolorblue {
                  background: #eff4ff;
                  border-radius: 6px;
                  border: 1px solid #608bff;
                  position: relative;

                  .unavailable {
                    position: absolute;
                    min-width: 124px;
                    height: 20px;
                    top: 0px;
                    z-index: 20;
                    color: #ffffff;
                    left: 0px;
                    background: #608bff;
                    border-radius: 6px 0px 6px 0px;
                    display: flex;
                    font-size: 12px;
                    color: #ffffff;
                    align-items: center;
                    justify-content: center;
                    padding: 0 8px;
                  }
                }
              }
            }
          }
        }

        //装箱单样式
        .Packinglist {
          width: 100%;
          padding-bottom: 10px;

          .headerdeliveryorderbox {
            width: 100%;
            min-height: 40px;
            background: #f6f6f6;
            border-radius: 6px;
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;

            span {
              width: 210px;
              display: flex;
              font-weight: 500;
              font-size: 14px;
              color: #222222;
              align-items: center;
              justify-content: center;
              height: 32px;

              &:hover {
                cursor: pointer;
              }
            }

            .activepackingitem {
              background: #FFFFFF;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
              border-radius: 4px;
            }
          }

          .costbox {
            .costboxrow {
              width: 100%;
              min-height: 370px;
              background: #f6f6f6;
              border-radius: 10px;
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              padding: 10px;

              .Internationaltransportationcosts {
                width: 440px;
                background: #ffffff;
                border-radius: 6px;
                min-height: 350px;
                padding: 20px;

                h2 {
                  height: 30px;

                  font-weight: 500;
                  font-size: 16px;
                  color: #222222;
                  line-height: 30px;
                  text-align: left;
                  font-style: normal;
                  margin-bottom: 10px;
                }

                .cost {
                  .costitem {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    line-height: 20px;

                    span {
                      width: 200px;

                      &:first-child {
                        font-weight: 400;
                        font-size: 14px;
                        color: #666666 !important;
                        text-align: left;
                      }

                      &:last-child {
                        text-align: right;
                        font-weight: 500;
                        font-size: 14px;
                        color: #222222;
                      }
                    }
                  }
                }

                .Packingliststatusbox {
                  margin-top: 25px;
                  min-height: 102px;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  align-items: flex-end;

                  .Packingliststatus {
                    display: flex;
                    width: 400px;
                    justify-content: space-between;
                    align-items: center;

                    span {
                      font-weight: 500;
                      font-size: 16px;
                      color: #222222;
                    }

                    .status {
                      height: 32px;
                      background: #fff8f3;
                      border-radius: 6px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      padding: 0 10px;
                      font-weight: 500;
                      font-size: 14px;
                      color: #ff730b;
                    }
                  }

                  .pay {
                    margin-top: 20px;
                    width: 400px;
                    height: 50px;
                    background: #ff730b;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    color: #ffffff;

                    &:hover {
                      cursor: pointer;
                    }
                  }
                }
              }

              .Shippinginformation {
                width: 440px;
                min-height: 350px;
                background: #ffffff;
                border-radius: 6px;
                padding: 20px;

                h2 {
                  height: 30px;

                  font-weight: 500;
                  font-size: 16px;
                  color: #222222;
                  line-height: 30px;
                  text-align: left;
                  font-style: normal;
                  margin-bottom: 10px;
                }

                .informationbox {
                  .information {
                    display: flex;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    line-height: 20px;

                    span {
                      width: 200px;
                      // 放不下换行
                      word-break: break-all;

                      &:first-child {
                        font-weight: 400;
                        font-size: 14px;
                        color: #666666 !important;
                        text-align: left;
                      }

                      &:last-child {
                        text-align: right;
                        font-weight: 500;
                        font-size: 14px;
                        color: #222222;
                      }
                    }
                  }
                }
              }

              .Remarksofpackinglist {
                width: 440px;
                min-height: 350px;
                background: #ffffff;
                padding: 20px;
                border-radius: 6px;

                h2 {
                  height: 30px;

                  font-weight: 500;
                  font-size: 16px;
                  color: #222222;
                  line-height: 30px;
                  text-align: left;
                  margin-bottom: 10px;
                  font-style: normal;
                }

                textarea {
                  width: 400px;
                  height: 80px;
                  padding: 10px;
                  background: #f9f9f9;
                  border-radius: 4px;
                  border: 1px solid #e2e2e2;
                  margin-bottom: 20px;
                }

                .order_sn {
                  font-weight: 400;
                  font-size: 14px;
                  color: #FF730B;
                  text-decoration: underline;

                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }

          /deep/.el-table {
            border-radius: 6px;
            overflow: hidden;
          }

          .Logisticsinformation {
            min-height: 200px;
            background: #F6F6F6;
            border-radius: 10px;
            padding: 10px;

            // 配送单列表
            .Distributionlist {
              padding: 20px;
              background: #FFFFFF;
              border-radius: 6px;
              margin-bottom: 10px;

              .waybill {
                font-weight: 400;
                font-size: 14px;
                color: #FF730B;
                line-height: 20px;
                text-align: center;
                font-style: normal;
                text-decoration-line: underline;

                &:hover {
                  cursor: pointer;
                }
              }

              .tip {
                font-weight: 400;
                font-size: 14px;
                color: #E3372F;
                line-height: 20px;
                text-align: left;
                margin-top: 10px;
              }
            }

            .TrackingStatus {
              min-height: 104px;
              background: #FFFFFF;
              border-radius: 6px;
              padding: 20px;

              h2 {
                font-weight: 500;
                font-size: 16px;
                color: #222222;
                line-height: 30px;
                text-align: left;
              }

              .iconbox {
                padding: 30px;
                display: flex;
                align-items: center;
                justify-content: center;

                .line {
                  width: 160px;
                  height: 2px;
                  background: #F8F8F8;
                }

                .iconitem {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  img {
                    width: 80px;
                    height: 80px;
                  }

                  p {
                    margin-top: 10px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #666666;
                  }
                }

                .activeicon {
                  p {
                    font-weight: 400;
                    font-size: 14px;

                    line-height: 20px;
                    text-align: center;
                    color: #222222 !important;
                  }
                }

              }

              .textareabox {
                min-height: 80px;
                background: #FFFFFF;
                border-radius: 6px;
                padding: 20px;
                border: 1px solid #E2E2E2;

                p {
                  display: flex;
                  align-items: center;

                  span {
                    &:first-child {
                      display: flex;
                      width: 130px;
                      margin-right: 10px;
                      font-weight: 400;
                      font-size: 13px;
                      color: #666666;
                      line-height: 18px;
                    }

                    &:last-child {
                      font-weight: 400;
                      font-size: 13px;
                      line-height: 18px;
                      color: #222222;
                    }
                  }
                }
              }

              .noData {
                height: 80px;
                background: #FFFFFF;
                border-radius: 6px;
                border: 1px solid #E2E2E2;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          .Packinglistdetails {

            background: #F6F6F6;
            border-radius: 10px;
            margin-top: 20px;
            padding: 10px;

            .detailscontent {
              height: 70px;
              background: #FFFFFF;
              border-radius: 6px;
              overflow: hidden;
              padding: 20px;

              .headertitle {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                &:hover {
                  cursor: pointer;
                }

                .left {
                  display: flex;
                  align-items: center;

                  span {
                    height: 30px;

                    font-weight: 500;
                    font-size: 16px;
                    color: #222222;
                    line-height: 30px;
                    text-align: left;
                    display: flex;
                    align-items: center;
                  }

                  .tip {
                    height: 20px;
                    margin-left: 20px;

                    font-weight: 400;
                    font-size: 14px;
                    color: #E3372F;
                    line-height: 20px;
                    text-align: left;
                    font-style: normal;
                  }
                }
              }

              .goods_title {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                font-size: 12px;
                overflow: hidden;
                text-align: left;

              }

              .maintable {
                /deep/.el-collapse {
                  border-top: 1px solid #D8D8D8;

                  .el-collapse-item__header {
                    border-bottom: 1px solid none;
                    height: 90px;

                    .itemheader {
                      height: 90px;
                      padding-top: 20px;

                      .Cartonnumber {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        height: 20px;

                        span {

                          &:first-child {
                            font-weight: 400;
                            font-size: 14px;
                            color: #222222;
                            line-height: 20px;
                            display: flex;
                            margin-right: 10px;
                          }

                          &:last-child {
                            font-weight: 500;
                            font-size: 14px;
                            color: #00C18B;
                            line-height: 20px;
                            text-align: left;
                          }
                        }
                      }

                      .specification {

                        display: flex;
                        width: 100%;
                        align-items: center;
                        min-height: 20px;

                        .samegoods,
                        .Weight,
                        .Volume,
                        .Length,
                        .Width,
                        .Height {

                          display: flex;
                          margin-right: 40px;

                          span {
                            display: flex;
                            align-items: center;

                            &:first-child {
                              font-weight: 400;
                              font-size: 14px;
                              color: #666666;
                              line-height: 20px;
                              text-align: center;
                              margin-right: 8px;
                            }

                            &:last-child {
                              font-weight: 500;
                              font-size: 14px;
                              color: #222222;
                              line-height: 20px;
                            }
                          }
                        }


                      }
                    }

                  }

                  .el-collapse-item__arrow {
                    width: 40px;
                    height: 30px;
                    background: #FFFFFF;
                    border-radius: 6px;
                    border: 1px solid #E2E2E2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;


                    &::before {

                      transition: transform .3s;
                    }

                    &.is-active {

                      // 取消它的旋转transform
                      transform: none;

                      &::before {
                        transform: rotate(90deg);
                        font-weight: 700;
                        color: #222222;
                        font-size: 14px;
                        transition: transform .3s;
                      }
                    }
                  }

                  .el-collapse-item__wrap {
                    border-bottom: 1px solid #D8D8D8;
                  }

                  .el-collapse-item__content {
                    padding-bottom: 20px;
                  }
                }
              }
            }

            .goodimg {
              width: 80px;
              height: 80px;
              background: #ededed;
              border-radius: 6px;
            }

            .showCloseheight {
              height: auto !important;
              ;
            }
          }
        }

        /deep/.ispayaftershow {
          .el-tabs__header {
            display: none !important;
          }
        }

        // 展开更多
        .showall {
          width: 100%;
          height: 40px;
          position: absolute;
          left: 0px;
          bottom: 0px;
          z-index: 100;
          background: #ffffff;
          box-shadow: 0px 1px 8px 0px rgba(4, 0, 0, 0.1);
          padding-right: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border-radius: 0 0 6px 6px;

          &:hover {
            cursor: pointer;
          }

          span {
            font-family: Adobe Heiti Std;
            font-weight: normal;
            font-size: 14px;
            font-weight: 600;
            color: #121212;
          }

          img {
            transition: all 0.2s linear;
            width: 20px;
            height: 10px;
            margin-left: 8px;
          }

          .deg180 {
            transform: rotate(-180deg);
          }
        }
      }
    }

    //付完款后样式
    .Uponconfirmationofpayment {
      .tableheader {
        width: 1360px;
        height: 60px;
        background: #f6f6f6;
        border-radius: 6px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >div {
          display: flex;
          align-items: center;
          font-weight: normal;
          font-size: 14px;
          height: 100%;
          flex-wrap: wrap;
          text-align: center;

          color: #222222;
          justify-content: center;
        }

        .headCheckBox {
          flex: 0 0 170px;
          justify-content: flex-start;

          .CheckBox {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            margin-right: 10px;

            &:hover {
              cursor: pointer;
            }
          }

          input[type='checkbox'] {
            background-color: #fff;
            -webkit-appearance: none;
            border: 1px solid #c0c4cc;
            border-radius: 4px;
            outline: none;
          }

          input[type='checkbox']:checked {
            background: #ff730b;

            border: 1px solid #ff730b;

            &::after {
              height: 4px;
              width: 8px;
            }
          }
        }

        .goods {
          flex: 0 0 128px !important;
        }

        .detail {
          flex: 0 0 120px !important;
        }

        // 单价

        .price {
          flex: 0 0 80px !important;
        }

        // 提出数量

        .Proposedquantity {
          flex: 0 0 70px !important;
        }

        // 调查在库数量

        .Quantityinstock {
          flex: 0 0 70px !important;
        }

        // 购买数量
        .Actualpurchasedquantity {
          flex: 0 0 70px !important;
        }

        // 中国国内所有费用
        .AllexpensesinChina {
          flex: 0 0 120px !important;
        }

        // 资金变动明细
        .Detailofchangesinfunds {
          flex: 0 0 120px !important;
        }

        //  附加服务费用
        .options {
          flex: 0 0 138px !important;
        }

        // 备注
        .remarks {
          flex: 0 0 138px !important;
        }

        // 业务备注回复
        .Replytobusinessremarks {
          flex: 0 0 138px !important;
        }

        // 商品状态
        .Commoditystate {
          flex: 0 0 70px !important;
        }
      }

      .tablebody {
        .shopsitem {
          border-bottom: 1px dashed #e2e2e2;

          .shopheader {
            display: flex;
            padding: 0 20px;

            h1 {
              display: flex;
              font-weight: normal;
              font-size: 14px;
              color: #121212;
              margin-top: 10px;
              font-weight: 500;
              margin-bottom: 10px;

              .checkedBox {
                margin-right: 10px;

                .shopchecked {
                  &:hover {
                    cursor: pointer;
                  }
                }
              }

              input[type='checkbox'] {
                background-color: #fff;
                -webkit-appearance: none;
                border: 1px solid #c0c4cc;
                border-radius: 4px;
                outline: none;
              }

              input[type='checkbox']:checked {
                background: #ff730b;

                border: 1px solid #ff730b;

                &::after {
                  height: 4px;
                  width: 8px;
                }
              }

              .img1688box {
                margin-left: 12px;
              }
            }
          }

          .shopbody {
            padding-bottom: 20px;

            .goodsitem {
              padding: 10px 20px;
              min-height: 120px;
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              justify-content: space-between;

              >div {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
              }

              .goodsheadCheckBox {
                flex: 0 0 170px;
                display: flex;
                justify-content: space-between;

                .goodscheckedBox {
                  margin-right: 5px;

                  &:hover {
                    cursor: pointer;
                  }

                  input[type='checkbox'] {
                    background-color: #fff;
                    -webkit-appearance: none;
                    border: 1px solid #c0c4cc;
                    border-radius: 4px;
                    outline: none;

                    &:hover {
                      cursor: pointer;
                    }
                  }

                  input[type='checkbox']:checked {
                    background: #ff730b;

                    border: 1px solid #ff730b;

                    &::after {
                      height: 4px;
                      width: 8px;
                    }
                  }
                }

                .rowNumber {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .goodimg {
                  width: 80px;
                  height: 80px;
                  background: #ededed;
                  border-radius: 6px;
                }
              }

              .goodsproduct {
                flex: 0 0 120px !important;

                .showGoodsTitle {
                  height: 80px;
                  display: flex;

                  &:hover {
                    cursor: pointer;
                  }

                  .title {
                    height: 80px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #222222;
                    line-height: 20px;
                    // 四行文本溢出
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                    overflow: hidden;
                  }
                }
              }

              .goodsdetail {
                flex: 0 0 128px !important;

                .showDetail {
                  width: 128px !important;

                  .showDetailCon {
                    width: 128px !important;
                  }
                }
              }

              .goodsprice {
                flex: 0 0 80px !important;
                flex-direction: column;

                span {
                  font-weight: 400;
                  font-size: 14px;
                  color: #222222;
                  line-height: 20px;
                }
              }

              // 提出数量
              .goodsProposedquantity {
                flex: 0 0 70px !important;
                font-size: 14px;
                color: #222222;
                line-height: 20px;
              }

              // 调查在库数量
              .goodsQuantityinstock {
                flex: 0 0 70px !important;
                font-size: 14px;
                color: #222222;
                line-height: 20px;
              }

              // 实际采购数量
              .goodsActualpurchasedquantity {
                flex: 0 0 70px !important;
                font-size: 14px;
                color: #222222;
                line-height: 20px;
              }

              // 中国国内所有费用
              .goodsAllexpensesinChina {
                flex: 0 0 120px !important;
                flex-direction: column;

                .china_amount {
                  font-size: 14px;
                  color: #222222;
                  line-height: 20px;
                  margin-bottom: 10px;
                }

                .mingXi {
                  width: 110px;
                  height: 34px;
                  background: #e9f8fb;
                  border-radius: 4px;
                  border: 1px solid #49c9e0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 400;
                  font-size: 12px;
                  color: #222222;

                  &:hover {
                    cursor: pointer;
                  }

                  /deep/.el-icon-arrow-down {
                    margin-left: 5px;
                    font-weight: 600;
                  }
                }
              }

              // 资金变动明细
              .goodsDetailofchangesinfunds {
                flex: 0 0 120px !important;
                flex-direction: column;

                .change_price {
                  font-size: 14px;
                  color: #222222;
                  line-height: 20px;
                  margin-bottom: 10px;
                }

                .goodsDetailofchangesinfundsmingXi {
                  width: 110px;
                  height: 34px;
                  background: #e4fbf4;
                  border-radius: 4px;
                  border: 1px solid #49c9e0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 400;
                  font-size: 12px;
                  color: #222222;

                  &:hover {
                    cursor: pointer;
                  }

                  /deep/.el-icon-arrow-right {
                    margin-left: 5px;
                    font-weight: 600;
                  }
                }
              }

              // 附加服务费用
              .goodsoptions {
                flex: 0 0 138px !important;

                .service {
                  width: 128px !important;
                  height: 80px;
                  background: #f9f9f9;
                  border-radius: 4px;
                  border: 1px solid #e2e2e2;
                  padding: 6px 10px;
                  overflow-y: scroll;
                  font-weight: 400;
                  padding-right: 5px;
                  font-size: 12px;
                  color: #999999;
                  line-height: 18px;

                  // 滚动条样式
                  &::-webkit-scrollbar {
                    width: 6px;
                  }

                  .item {
                    font-weight: 400;
                    font-size: 12px;
                    color: #222222;
                    line-height: 18px;
                  }
                }

                .noserve {
                  width: 128px !important;
                  height: 80px;
                  background: #f9f9f9;
                  border-radius: 4px;
                  border: 1px solid #e2e2e2;
                  padding: 6px 10px;
                  overflow-y: scroll;
                  font-weight: 400;
                  font-size: 12px;
                  padding-right: 5px;
                  color: #999999;
                  line-height: 18px;
                }
              }

              // 备注
              .goodsremarks {
                flex: 0 0 138px !important;

                .remarksbox {
                  width: 128px !important;
                  height: 80px;
                  background: #f9f9f9;
                  border-radius: 4px;
                  border: 1px solid #e2e2e2;
                  padding: 6px 10px;
                  overflow-y: scroll;
                  font-weight: 400;
                  padding-right: 5px; // 滚动条样式

                  &::-webkit-scrollbar {
                    width: 6px;
                  }

                  font-weight: 400;
                  font-size: 12px;
                  color: #222222;
                  line-height: 18px;
                }

                .noremark {
                  width: 128px !important;
                  height: 80px;
                  background: #f9f9f9;
                  border-radius: 4px;
                  border: 1px solid #e2e2e2;
                  padding: 6px 10px;
                  overflow-y: scroll;
                  font-weight: 400;
                  font-size: 12px;
                  padding-right: 5px;
                  color: #999999;
                  line-height: 18px;
                }
              }

              // 业务备注回复
              .goodsReplytobusinessremarks {
                flex: 0 0 138px !important;

                .y_replybox {
                  width: 128px !important;
                  height: 80px;

                  border-radius: 4px;

                  padding: 6px 10px;
                  overflow-y: scroll;
                  font-weight: 400;
                  padding-right: 5px; // 滚动条样式

                  &::-webkit-scrollbar {
                    width: 6px;
                  }

                  font-weight: 400;
                  font-size: 12px;
                  color: #222222;
                  line-height: 18px;
                  background: #fdeeed;
                  border-radius: 4px;
                  border: 1px solid #f8cac5;
                }

                .noy_reply {
                  width: 128px !important;
                  height: 80px;
                  background: #f9f9f9;
                  border-radius: 4px;
                  border: 1px solid #e2e2e2;
                  padding: 6px 10px;
                  overflow-y: scroll;
                  font-weight: 400;
                  font-size: 12px;
                  padding-right: 5px;
                  color: #999999;
                  line-height: 18px;
                }
              }

              // 商品状态
              .goodsCommoditystate {
                flex: 0 0 70px;
                color: #222222;
                line-height: 20px;
                text-align: center;
              }

              .Viewbox {
                flex: 0 0 588px;
                display: flex;
                justify-content: flex-end;

                .ViewProducts {
                  min-width: 146px;
                  height: 34px;
                  background: #eff4ff;
                  padding: 0 15px;
                  border-radius: 4px;
                  border: 1px solid #608bff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 400;
                  font-size: 12px;
                  color: #608bff;
                  line-height: 18px;

                  &:hover {
                    cursor: pointer;
                  }
                }

                .Replacementlink {
                  min-width: 146px;
                  height: 34px;

                  background: #608bff;
                  border-radius: 4px;
                  display: flex;
                  margin-left: 20px;
                  align-items: center;
                  font-weight: 400;
                  font-size: 12px;
                  color: #ffffff;
                  line-height: 18px;
                  text-align: left;
                  justify-content: center;

                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }

            .bordercolorred {
              background: #fdf3f2;
              border-radius: 6px;
              border: 1px solid #e74c3c;
              position: relative;

              .unavailable {
                min-width: 124px;
                position: absolute;
                top: 0px;
                z-index: 20;
                left: 0px;
                color: #ffffff;
                height: 20px;
                background: #e74c3c;
                border-radius: 6px 0px 6px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 8px;
                font-size: 12px;
                color: #ffffff;
              }
            }

            .bordercolorblue {
              background: #eff4ff;
              border-radius: 6px;
              border: 1px solid #608bff;
              position: relative;

              .unavailable {
                position: absolute;
                min-width: 124px;
                height: 20px;
                top: 0px;
                z-index: 20;
                color: #ffffff;
                left: 0px;
                background: #608bff;
                border-radius: 6px 0px 6px 0px;
                display: flex;
                font-size: 12px;
                color: #ffffff;
                align-items: center;
                justify-content: center;
                padding: 0 8px;
              }
            }
          }
        }
      }
    }

    // 底部栏

    .operatingOptions {
      margin: 0 auto;
      width: 1400px;
      height: 100px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      align-items: center;
      display: flex;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0px 40px;
      z-index: 200;

      // 左侧
      .operatingOptionsLeft {
        min-height: 32px;
        display: flex;

        // 全选
        .choies {
          display: flex;
          align-items: center;
          margin-right: 60px;

          input[type='checkbox'] {
            background-color: #fff;
            -webkit-appearance: none;
            border: 1px solid #c0c4cc;
            border-radius: 4px;
            outline: none;
            margin-right: 10px;
          }

          input[type='checkbox']:checked {
            background: #ff730b;

            border: 1px solid #ff730b;

            &::after {
              height: 4px;
              width: 8px;
            }
          }

          .qx {
            font-size: 14px;
            color: #222222;
          }
        }

        // 操作按钮
        .caozuoBox {
          display: flex;
          align-items: center;

          .caoZuoAnNiuGroup {
            .opt {
              min-width: 100px;
              height: 40px;
              background: #608bff;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 10px;
              font-size: 16px;
              color: #ffffff;
              line-height: 22px;

              &:active {
                border: none;
              }
            }

            .addLibrary {
              height: 40px;
              background: #608bff;
              border-radius: 4px;
              padding: 0 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 500;
              font-size: 16px;
              color: #ffffff;
            }
          }
        }

        // 订单样式
        .orderDataBoxobligation {
          margin-left: 60px;
        }

        .orderDataBox {
          display: flex;
          justify-content: center;

          flex-direction: column;
          margin-left: 60px;

          span {
            display: block;
            font-size: 14px;
            font-weight: 400;
            font-size: 14px;
            color: #222222;
            line-height: 20px;
          }
        }
      }

      // 右侧

      .operatingOptionsRight {
        height: 100%;
        display: flex;
        align-items: center;

        .Amountpaid {
          display: flex;
          justify-content: flex-end;

          .left {
            height: 60px;
            display: flex;

            flex-direction: column;
            justify-content: center;

            .zongjiaBox {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-bottom: 10px;

              span {
                font-size: 14px;
                font-weight: 400;

                .priceBox {
                  font-weight: 600;
                  font-size: 14px;
                  color: #222222;
                }
              }
            }

            .zongjiaBoxMeiYuan {
              display: flex;
              justify-content: flex-end;

              .huiLv {
                font-size: 14px;
                color: #999999;
                line-height: 14px;
              }

              .meiyuanPrice {
                font-size: 16px;
                font-weight: bold;
                color: #ff730b;
                line-height: 14px;
              }
            }
          }
        }

        .youhuijuan {
          margin-right: 64px;
          padding-top: 24px;
          position: relative;

          /deep/ .el-checkbox {
            color: #ff730b;

            display: flex;
            align-items: center;
          }

          /deep/.el-checkbox__label {
            padding-left: 0px;
          }

          /deep/ .el-checkbox__inner {
            border-radius: 50px;
            height: 15px;
          }

          /deep/.el-checkbox__input {
            height: 15px;
          }

          span {
            position: absolute;
            right: 0;
            min-width: 24px;

            transform: translateX(100%);
            top: 6px;
            font-size: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            height: 18px;
            display: flex;
            padding: 0 6px;
            justify-content: center;
            align-items: center;
            background: #ff730b;
            border-radius: 4px 4px 4px 0px;
          }
        }

        .caoZuoBtnBox {
          display: flex;
          align-items: center;

          .baocun {
            width: 200px;
            height: 60px;
            border-radius: 4px;
            border: 1px solid #ff730b;
            margin-left: 20px;
            font-weight: 500;
            font-size: 16px;
            color: #ff730b;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
          }

          .tichu {
            width: 200px;
            font-weight: 500;
            font-size: 16px;
            color: #ffffff;
            line-height: 24px;
            height: 60px;
            background: #ff730b;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            color: #fff;
          }

          .payBtn {
            width: 200px;
            height: 60px;
            background: #ff730b;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            margin-left: 22px;
            align-items: center;
          }

          .cancel {
            width: 200px;
            height: 60px;
            border-radius: 4px;
            border: 1px solid #ff730b;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            font-weight: 500;
            font-size: 16px;
            color: #ff730b;
            line-height: 24px;
          }

          .chongXinTiChu {
            margin-left: 20px;
            width: 200px;
            height: 60px;
            background: #ff730b;
            font-weight: 500;
            font-size: 16px;
            color: #ffffff;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

// 商品规格 付款钱
.showDetail {
  max-height: 80px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 120px;

  display: flex;

  // 滚动条样式
  /* 整个滚动条的宽度 */
  &::-webkit-scrollbar {
    width: 6px;
    /* 滚动条的宽度 */
    /* 滚动条的高度，如果在垂直方向也需要自定义的话 */
  }

  .goodsDetailOpt {
    font-size: 14px;
  }

  .showDetailCon {
    padding: 0px 10px;
  }
}

// 付款，付款后
.obligationaffter {
  max-height: 80px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 120px;

  display: flex;

  // 滚动条样式
  /* 整个滚动条的宽度 */
  &::-webkit-scrollbar {
    width: 6px;
    /* 滚动条的宽度 */
    /* 滚动条的高度，如果在垂直方向也需要自定义的话 */
  }

  .goodsDetailOpt {
    font-size: 14px;
  }

  .showDetailCon {
    padding: 0px 5px;
  }
}

// 操作栏弹层样式
.delBtn {
  width: 157px;
  height: 30px;
  padding-left: 10px;
  line-height: 30px;
  background: #fff;
  margin-bottom: 15px;

  &:hover {
    width: 157px;
    height: 30px;
    background: #eff4ff;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 10px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    margin-bottom: 15px;
    cursor: pointer;
    color: #608bff;
  }
}

.addgoods {
  width: 157px;
  height: 30px;
  background: #fff;
  padding-left: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  border-radius: 4px;

  &:hover {
    width: 157px;
    height: 30px;
    background: #eff4ff;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 10px;
    padding-left: 10px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    line-height: 30px;
    color: #608bff;
  }
}

.addLibraryOfGoods {
  min-width: 180px;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  background: #fff;

  &:hover {
    min-width: 180px;
    height: 30px;
    background: #eff4ff;
    border-radius: 4px;
    cursor: pointer;
    padding-left: 10px;
    line-height: 30px;

    font-size: 14px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #608bff;
  }
}

.additionalServices {
  .obody {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 380px;
    overflow: auto;

    // 滚动条样式
    /* 整个滚动条的宽度 */
    &::-webkit-scrollbar {
      width: 5px;
      /* 滚动条的宽度 */

      /* 滚动条的高度，如果在垂直方向也需要自定义的话 */
    }

    .optionhead {
      width: 100%;
      display: flex;
      align-items: center;
      position: sticky;
      top: 0px;
      justify-content: space-between;
      height: 40px;
      border-radius: 4px;
      background-color: #f6f6f6;
      padding: 0 10px;
      z-index: 100;

      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #222222;
        font-weight: 600;
      }

      .optionname {
        justify-content: flex-start;
      }
    }

    .optionListitem {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      padding: 0 10px;
      min-height: 40px;

      >div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .optionname {
        justify-content: flex-start;

        input[type='checkbox'] {
          background-color: #fff;
          -webkit-appearance: none;
          border: 1px solid #c0c4cc;
          border-radius: 4px;
          outline: none;
          margin-right: 10px;

          &:hover {
            cursor: pointer;
          }
        }

        input[type='checkbox']:checked {
          background: #ff730b;

          border: 1px solid #ff730b;

          &::after {
            height: 4px;
            width: 8px;
          }
        }
      }

      .optionnum {
        /deep/ .el-input--mini {
          width: 88px;
        }

        /deep/.el-input-number--mini {
          width: 88px;
        }

        /deep/.el-input__inner {
          padding: 0px 30px;
        }

        /deep/.el-input-number__decrease {
          left: 0px;
        }

        /deep/.el-input-number__increase {
          padding-right: 2px;
          right: -10px;
        }
      }

      // .optionhead {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   margin-bottom: 10px;

      //   .left {
      //     display: flex;
      //     align-items: center;

      //     .tag {
      //       font-weight: normal;
      //       font-size: 14px;
      //       color: #121212;
      //       width: 188px;
      //       line-height: 16px;
      //     }

      //     .checkOptionBox {
      //       margin-right: 10px;

      //       &:hover {
      //         cursor: pointer;
      //       }
      //     }

      //     input[type='checkbox'] {
      //       background-color: #fff;
      //       -webkit-appearance: none;
      //       border: 1px solid #c0c4cc;
      //       border-radius: 6px;
      //       outline: none;
      //     }

      //     input[type='checkbox']:checked {
      //       background-color: #e40000;

      //       border: 1px solid #e40000;

      //       &::after {
      //         height: 3px;
      //       }
      //     }
      //   }

      //   .num {
      //     width: 100px;
      //     text-align: center;
      //     display: flex;
      //     align-items: center;
      //     color: #d12a1d;
      //     font-style: normal;
      //     justify-content: center;
      //     font-size: 14px;
      //     height: 36px;
      //     background: #fff;
      //     border-radius: 10px;
      //   }

      //   /deep/.el-input-number {
      //     overflow: hidden !important;
      //     width: 100px;
      //     height: 36px;
      //     border-radius: 10px;
      //     justify-content: space-between;
      //     display: flex;
      //     align-items: center !important;
      //   }

      //   /deep/ .el-input__inner {
      //     width: 100px;
      //     height: 36px;
      //     background: #fff;
      //     border-radius: 10px;
      //     font-weight: 400;
      //     font-size: 14px;
      //     padding: 0 30px;
      //     color: #121212;
      //     border: 1px solid #dfdfdf !important;
      //   }

      //   /deep/ .el-input-number__increase {
      //     display: flex;
      //     align-items: center;
      //     width: 16px;

      //     height: 36px;

      //     right: 10px;
      //   }

      //   /deep/ .el-icon-minus {
      //     color: #121212;
      //     font-weight: 600 !important;
      //     font-size: 18px;
      //   }

      //   /deep/ .el-icon-plus {
      //     color: #121212;
      //     font-size: 18px;
      //     font-weight: 600 !important;
      //   }

      //   /deep/ .el-input-number__decrease {
      //     width: 16px;
      //     height: 36px;

      //     display: flex;
      //     align-items: center;
      //     left: 10px;
      //   }
      // }

      // .optionbottom {
      //   display: flex;
      //   justify-content: space-between;

      //   .optionprice,
      //   .optiontotal {
      //     // flex: 1;

      //     p {
      //       &:first-child {
      //         font-weight: 400;
      //         font-size: 12px;
      //         color: #666666;
      //         line-height: 16px;
      //         margin-bottom: 5px;
      //       }

      //       &:last-child {
      //         font-weight: 550;
      //         font-size: 12px;
      //         color: #121212;
      //         line-height: 20px;
      //       }
      //     }
      //   }
      // }
    }
  }

  .nooption {
    height: 60px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .foot {
    display: flex;
    width: 100%;
    margin-top: 10px;
    padding-right: 20px;

    justify-content: flex-end;

    .hejibox {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      >p {
        display: flex;

        &:first-child {
          font-weight: 600;
          font-size: 14px;
          color: #121212;
          line-height: 16px;
          text-align: right;
          margin-right: 10px;
        }

        &:last-child {
          white-space: nowrap;

          span {
            &:first-child {
              font-weight: bold;
              font-size: 18px;
              color: #ff730b;
              line-height: 30px;
            }

            &:last-child {
              font-weight: normal;
              font-size: 14px;
              color: #ff730b;
              line-height: 30px;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .confirmbox {
      display: flex;
      align-items: center;
      margin-left: 20px;

      .react {
        font-size: 14px;

        color: #000000;
        line-height: 20px;
        background-color: transparent;
        border: solid 1px #47bd9b;
        min-height: 32px;
        padding: 5px 12px;
        border-radius: 5px;
        outline: none;
        color: #47bd9b;

        padding: 0 10px;
      }

      .save {
        font-size: 14px;

        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 32px;
        color: #fff;
        background-color: transparent;
        border: solid 1px #47bd9b;
        background-color: #47bd9b;
        padding: 5px 12px;
        border-radius: 5px;
        margin-left: 20px;
        outline: none;
      }
    }
  }
}

//   商品规格弹层
.showdetailWindowBody {
  .detailOpt {
    font-weight: 400;
    font-size: 14px;
    color: #121212;
    margin-bottom: 8px;

    h1 {
      margin-bottom: 5px !important;
    }
  }
}

.editDetail {
  display: flex;
  margin-left: 10px;
  justify-content: flex-start;
  text-align: left;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 20px;
    height: 20px;
  }
}

// 修改的商品规格
.detailWindowBody {
  max-height: 420px;
  overflow-y: auto;

  .detailOpt {
    margin-bottom: 20px;

    h1 {
      font-weight: 400;
      font-size: 14px;
      color: #121212;
      line-height: 16px;
      margin-bottom: 10px;
    }

    .optClass {
      display: flex;
      flex-wrap: wrap;

      span {
        display: flex;
        width: 140px;

        min-height: 30px;
        background: #ffffff;
        border-radius: 8px;
        margin-bottom: 10px;

        &:hover {
          cursor: pointer;
        }

        padding: 5px 10px;
        flex-wrap: wrap;
        border: 1px solid #d4d4d4;
        margin-right: 10px;

        align-items: center;
        font-weight: 500;
        font-size: 14px;
        color: #222222;

        // /* 禁止文本换行 */
        // overflow: hidden;
        // /* 隐藏溢出的内容 */
        // text-overflow: ellipsis;
        // /* 在溢出的地方显示省略号 */
        &:nth-child(2n) {
          margin-right: 0px !important;
        }
      }

      .active {
        border: 1px solid #ff730b;
        color: #ff730b;
      }
    }
  }
}

// 中国总成本弹层
.goodsChinatotalcostpopover {
  width: 100%;

  p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
    min-width: 100%;
    display: flex;
    align-items: center;

    .title {
      text-align: left;
      display: inline-block;
      width: 110px;
      margin-right: 10px;
      white-space: normal;
      word-break: keep-all;
      color: #96989a;
    }

    .content {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 700;
      color: #222222;
      min-width: 30px;

      line-height: 16px;
      margin-left: 5px;
    }

    .body1 {
      display: inline-block;
      color: #ffa724;
      font-size: 12px;
    }
  }
}
</style>
